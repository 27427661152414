
const AsideBar = ({ practiceData }) => {

    const _user = practiceData?.user ?? {};

    return (
        <div className="">
            <div className="bg-white p-4 sm:rounded-xl sm:border border-gray-200">
                <div className="w-fit mx-auto">
                    <div
                        className="w-32 h-32 rounded-full overflow-hidden mx-auto bg-slate-100 border border-slate-200">
                        {_user?.avatar &&
                            <img
                                src={_user?.avatar}
                                alt={`${_user?.first_name} ${_user?.last_name}`}
                                className='w-full h-full rounded-full'
                            />}
                    </div>
                    <div>
                        <h1 className='mx-auto mt-2 text-center font-bold text-lg'>
                            {`${_user?.first_name} ${_user?.last_name}`}
                        </h1>
                        <h3 className='mx-auto mb-2 text-center text-sm'>
                            {`@${_user?.username}`}
                        </h3>
                    </div>
                </div>

                {/* Informations */}
                <div className="mt-6 pt-6 border-t border-gray-200 max-lg:hidden">
                    <h3 className='mb-2 font-bold'>Informations</h3>
                    <table className='w-full'>
                        <tbody>
                            <tr className="[&>*]:py-1 [&>*]:text-sm [&>*]:text-gray-700 [&>*]:whitespace-nowrap cursor-context-menu">
                                <td className='font-bold'>Full Name</td>
                                <td className=''>{`${_user?.first_name} ${_user?.last_name}`}</td>
                            </tr>
                            <tr className="[&>*]:py-1 [&>*]:text-sm [&>*]:text-gray-700 [&>*]:whitespace-nowrap cursor-context-menu">
                                <td className='font-bold'>Email :</td>
                                <td className=''>{_user?.email}</td>
                            </tr>
                            <tr className="[&>*]:py-1 [&>*]:text-sm [&>*]:text-gray-700 [&>*]:whitespace-nowrap cursor-context-menu">
                                <td className='font-bold'>Phone :</td>
                                <td className=''>{`${_user?.country_code ?? ''} ${_user?.phone ?? ''}`}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default AsideBar;
import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout/index';
import { useNavigate } from 'react-router-dom';
import { useFetch } from 'hooks/useSWR';
import PageLoader from 'components/shared/Loaders/PageLoader';
import Pagination from 'components/Pagination';
import OfflinePatientsList from 'components/Pages/OfflinePatients/OfflinePatientsList';
import Search from 'components/Search/Search';
import { LuSettings2 } from 'react-icons/lu';
import FilterOfflinePatients from 'components/Pages/OfflinePatients/FilterOfflinePatients';


const OfflinePatients = () => {

    const navigate = useNavigate()

    // Get Url Parameters
    const searchParams = new URLSearchParams(document.location.search)
    const getPageNumber = searchParams.get('page') ?? 1;
    const [pageNumber, setPageNumber] = useState(getPageNumber)
    const [searchQuery, setSearchQuery] = useState('')

    const [sortBy, setSortBy] = useState('new')
    const isFiltered = sortBy != 'new'; // show filter notif..


    // Get Cares Data Using SWR package
    const fetcher = useFetch()

    const { data: offlinePatientsData, isLoading, mutate } = fetcher.getAllOfflinePatients(pageNumber, searchQuery, sortBy);
    const refresh = () => mutate()

    const [offlinePatients, setOfflinePatients] = useState([])

    useEffect(() => {
        setOfflinePatients(offlinePatientsData?.data?.data)
    }, [offlinePatientsData])

    // Change Page
    const navigateToAnotherPage = (n) => {
        setPageNumber(n)
        navigate("/offline-patients?page=" + n)
    }


    // Change Keywords And Get Data
    const handleChangeSearchQuery = (q) => {
        setSearchQuery(q)
        setPageNumber(1)
        navigate("/offline-patients?page=1")
    }

    const refreshOfflinePatientsData = (_by) => {
        setSortBy(_by)
        mutate()
    }

    return (
        <Layout>
            <div className="px-4 py-10">
                <div className='max-w-[1200px] mx-auto'>
                    {/* Header */}
                    <div className="flex items-center justify-between mb-6">
                        <div className="">
                            <h3 className='font-bold text-2xl flex items-center'>
                                Offline Patients List
                                <span className='bg-bgPrimary border px-2 py-1 ml-4 rounded-3xl text-sm font-semibold'>
                                    {offlinePatientsData?.data?.total ?? 0}
                                </span>
                            </h3>
                            <p className='mt-1'>View, Delete All Offline Patients.</p>
                        </div>
                    </div>

                    <div className="flex items-center justify-between mb-6 bg-bgSecondary p-4 rounded-md">
                        <Search
                            navigate={handleChangeSearchQuery}
                        />

                        <FilterOfflinePatients
                            refresh={refreshOfflinePatientsData}
                            data={{ orderBy: sortBy }}
                            button={
                                <button className='relative flex items-center gap-2 border border-gray-200 hover:border-gray-300 bg-white hover:bg-gray-100 text-black font-bold transition-colors duration-200 outline-none py-1.5 px-4 rounded-3xl'>
                                    Filtere
                                    <LuSettings2 size={20} className="max-lg:w-3" />
                                    {isFiltered && <span className='absolute top-0 left-0 w-2.5 h-2.5 bg-green-500 rounded-full border border-gray-200'></span>}
                                </button>
                            }
                        />
                    </div>

                    {isLoading && <PageLoader /> || (
                        <div className=''>
                            {/* Offline Patients List */}
                            <OfflinePatientsList
                                offlinePatients={offlinePatients}
                                refresh={refresh}
                            />

                            {/* Pagination */}
                            <Pagination
                                currentPageNumber={offlinePatientsData?.data?.current_page}
                                lastPageNumber={offlinePatientsData?.data?.last_page}
                                changePageNumber={navigateToAnotherPage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    )
}
export default OfflinePatients;
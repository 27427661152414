import React from 'react';
import { isEmpty } from 'helpers/utils';


const PermissionsList = ({ data, handleChange }) => {
    return (
        <section>
            <div className="overflow-hidden bg-white">
                <div className="max-w-full overflow-auto min-h-[100px]">
                    <table className='w-full border-collapse'>
                        <thead>
                            <tr className='[&>*]:py-2 [&>*]:text-black [&>*]:whitespace-nowrap'>
                                <th></th>
                                <th>BASIC</th>
                                <th>LOW</th>
                                <th>MEDIUM</th>
                                <th>HIGH</th>
                                <th>OWNER</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isEmpty(data) && (
                                data?.map((per, i) => {
                                    
                                    const slug = per.slug;
                                    const basic = per.basic;
                                    const low = per.low;
                                    const medium = per.medium;
                                    const high = per.high;
                                    const owner = per.owner;

                                    const _basic = `basic_${i}`
                                    const _low = `low_${i}`
                                    const _medium = `medium_${i}`
                                    const _high = `high_${i}`
                                    const _owner = `owner_${i}`

                                    // If the permission does not have an ID, it is new
                                    const isNew = !per.id;

                                    return (
                                        <tr key={slug} className='[&>*]:text-black [&>*]:whitespace-nowrap [&>*]:border [&>*]:border-gray-200 hover:bg-bgSecondary'>
                                            <td>
                                                <div className="px-4">
                                                    {per.title}
                                                    {isNew &&
                                                    <span className='ml-2 bg-green-100 text-green-600 border border-green-600 px-2 py-0 rounded-3xl text-sm font-semibold'>
                                                        new
                                                    </span>}
                                                </div>
                                            </td>
                                            <td>
                                                <label htmlFor={_basic} className='px-4 py-6 w-full flex items-center justify-center cursor-pointer'>
                                                    <input onChange={() => handleChange('basic', !basic, i)} type="checkbox" id={_basic} checked={basic} className='cursor-pointer' />
                                                </label>
                                            </td>
                                            <td>
                                                <label htmlFor={_low} className='px-4 py-6 w-full flex items-center justify-center cursor-pointer'>
                                                    <input onChange={() => handleChange('low', !low, i)} type="checkbox" id={_low} checked={low} className='cursor-pointer' />
                                                </label>
                                            </td>
                                            <td>
                                                <label htmlFor={_medium} className='px-4 py-6 w-full flex items-center justify-center cursor-pointer'>
                                                    <input onChange={() => handleChange('medium', !medium, i)} type="checkbox" id={_medium} checked={medium} className='cursor-pointer' />
                                                </label>
                                            </td>
                                            <td>
                                                <label htmlFor={_high} className='px-4 py-6 w-full flex items-center justify-center cursor-pointer'>
                                                    <input onChange={() => handleChange('high', !high, i)} type="checkbox" id={_high} checked={high} className='cursor-pointer' />
                                                </label>
                                            </td>
                                            <td>
                                                <label htmlFor={_owner} className='px-4 py-6 w-full flex items-center justify-center cursor-pointer'>
                                                    <input onChange={() => handleChange('owner', !owner, i)} type="checkbox" id={_owner} checked={owner} className='cursor-pointer' />
                                                </label>
                                            </td>
                                        </tr>
                                    )
                                })
                            ) || (
                                    <tr className='[&>*]:text-black [&>*]:whitespace-nowrap [&>*]:border [&>*]:border-gray-200'>
                                        <td>
                                            <div className="px-4 cursor-context-menu">
                                                Not found!
                                            </div>
                                        </td>
                                        <td>
                                            <label className='px-4 py-6 w-full flex items-center justify-center cursor-context-menu'>
                                                <input type="checkbox" className='cursor-context-menu' disabled={true} />
                                            </label>
                                        </td>
                                        <td>
                                            <label className='px-4 py-6 w-full flex items-center justify-center cursor-context-menu'>
                                                <input type="checkbox" className='cursor-context-menu' disabled={true} />
                                            </label>
                                        </td>
                                        <td>
                                            <label className='px-4 py-6 w-full flex items-center justify-center cursor-context-menu'>
                                                <input type="checkbox" className='cursor-context-menu' disabled={true} />
                                            </label>
                                        </td>
                                        <td>
                                            <label className='px-4 py-6 w-full flex items-center justify-center cursor-context-menu'>
                                                <input type="checkbox" className='cursor-context-menu' disabled={true} />
                                            </label>
                                        </td>
                                        <td>
                                            <label className='px-4 py-6 w-full flex items-center justify-center cursor-context-menu'>
                                                <input type="checkbox" className='cursor-context-menu' disabled={true} />
                                            </label>
                                        </td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}

export default PermissionsList;
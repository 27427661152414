import React, { useState } from 'react';
import { isEmpty } from 'helpers/utils';
import { Link, useNavigate } from 'react-router-dom';
import ConfirmationModal from 'components/Alerts/ConfirmationModal';
import BtnLoader from 'components/Loaders/BtnLoader';
import { CgClose } from 'react-icons/cg';
import { useAuth } from 'hooks/useAuth';
import axios from 'lib/axios';
import useAlert from 'components/Alerts/useAlert';


const OfflinePatientsList = ({ offlinePatients, refresh }) => {
    const { renderAlertMessages, showMessage } = useAlert();

    const { csrf, user: admin, userIsLoading: adminIsLoading } = useAuth()
    const { ViewOfflinePatientsPage, DeleteOfflinePatient, ViewProfessionalPage } = !adminIsLoading && admin && admin.roles;

    const navigate = useNavigate()

    const showPatientPage = (id) => {
        if (ViewOfflinePatientsPage) {
            navigate('/offline_patient/'+id);
        }
    }

    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false)
    const [deletePatientInProgress, setDeletePatientInProgress] = useState(false)
    const [patientIdToDelete, setPatientIdToDelete] = useState('')
    const deletePatient = async () => {
        try {
            setDeletePatientInProgress(true)

            await csrf()

            const { data } = await axios.delete(`/api/v1/forAdmin/deleteOfflinePatient/${patientIdToDelete}`)

            if (!data.error) {
                await refresh()
                showMessage(data.message, 'success');
                setPatientIdToDelete('')
            }


            setDeletePatientInProgress(false)
        } catch (error) {
            setDeletePatientInProgress(false)
            showMessage('something was wrong', 'error');
        }
    }

    return (
        <div className="overflow-hidden bg-white">
            {renderAlertMessages}
            <div
                className="max-w-full overflow-auto min-h-[100px]">
                <table
                    className='w-full'>
                    <thead>
                        <tr className='[&>*]:px-4 [&>*]:py-2 [&>*]:text-black [&>*]:whitespace-nowrap [&>*]:text-start'>
                            <th className="">ID</th>
                            <th className="">Fullname</th>
                            <th className="">Email</th>
                            <th className="">Phone</th>
                            <th className="">Appointments</th>
                            <th className="">Sales</th>
                            <th className="">Wallet</th>
                            <th className="">Created By</th>
                            <th className=''></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isEmpty(offlinePatients) && (
                            offlinePatients.map((patient) => {
                                return (
                                    <tr key={patient.id} onClick={() => showPatientPage(patient.id)} className='hover:bg-bgPrimary border-t border-gray-200 [&>*]:py-6 [&>*]:px-4 [&>*]:text-gray-700 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-pointer transition-colors duration-200'>
                                        <td className="font-bold">{patient.id}</td>
                                        <td className="">{`${patient.first_name} ${patient.last_name}`}</td>
                                        <td className="">{patient.email}</td>
                                        <td className="">{`${patient.country_code ?? ""} ${patient.phone ?? ""}`}</td>
                                        <td className="">{patient.total_appointments}</td>
                                        <td className="">{patient.total_sales}</td>
                                        <td className="">
                                            <strong className="text-green-500">{`MAD ${patient.wallet ?? '00.00'}`}</strong>
                                        </td>
                                        <td className="">
                                            <div className="w-fit">
                                                {!isEmpty(patient.created_by) && (
                                                    <Link to={ViewProfessionalPage ? `/${patient.created_by.role}/${patient.created_by.username}` : '#'}>
                                                        <div className='w-10 h-10 rounded-full bg-slate-200 overflow-hidden hover:opacity-90'>
                                                            <img
                                                                className='w-full h-full'
                                                                src={patient.created_by.avatar}
                                                                alt={`${patient.created_by.first_name} ${patient.created_by.last_name}`}
                                                            />
                                                        </div>
                                                    </Link>
                                                ) || (
                                                        <div className='w-10 h-10 rounded-full bg-slate-200 overflow-hidden hover:opacity-90'></div>
                                                    )}
                                            </div>
                                        </td>
                                        <td className='text-end'>
                                            {DeleteOfflinePatient &&
                                            <button
                                                disabled={deletePatientInProgress}
                                                onClick={() => {
                                                    setPatientIdToDelete(patient.id)
                                                    setConfirmationModalIsOpen(true)
                                                }}
                                                className="text-red-500 hover:text-white transition-colors duration-200 bg-transparent hover:bg-red-500 focus:outline-none p-2 rounded-md">
                                                {deletePatientInProgress && patientIdToDelete == patient.id
                                                    ? <BtnLoader withText={false} />
                                                    : <CgClose className="w-5 h-5" />}
                                            </button>}
                                        </td>
                                    </tr>
                                )
                            })
                        )}
                    </tbody>
                </table>
            </div>

            {/* Delete Patient */}
            <ConfirmationModal
                isOpen={confirmationModalIsOpen}
                title="Delete Patient"
                message="Are you sure you want to delete patient?"
                buttonName="Supprimer"
                onConfirm={() => {
                    setConfirmationModalIsOpen(false);
                    deletePatient()
                }}
                onCancel={() => {
                    setConfirmationModalIsOpen(false);
                }}
            />
        </div>
    )
}
export default OfflinePatientsList;
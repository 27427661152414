import React from 'react';


export const TicketsAsideBar = () => {
    return {
        title: "Tickets",
        links: [
            {
                icon: "",
                name: "Unread",
                path: "/tickets?type=unread&page=1",
                desc: "Get all unreaded tickets",
            },{
                icon: "",
                name: "Solved",
                path: "/tickets?type=solved&page=1",
                desc: "Get all Solved tickets",
            },{
                icon: "",
                name: "Archived",
                path: "/tickets?type=archived&page=1",
                desc: "Get all Archived tickets",
            },
        ],
    };
};
import { useState } from "react";
import { GoScreenFull } from "react-icons/go";

const Overview = ({ userData, GoTo }) => {

    return (
        <div className=''>
            <div className={`overflow-y-auto`}>
                <div className="flex items-center border-b border-gray-100 my-6">
                    <button
                        className={`font-bold text-lg flex items-center outline-none px-2 py-2 transition-colors duration-200 border-b-2 rounded-t-lg border-black cursor-context-menu`}>
                        Overview
                    </button>
                </div>
                <div className="max-w-full overflow-x-auto">
                    <div className="">
                        {/* Overview */}
                        <div className="rounded-xl">
                            <div className="grid grid-cols-3 gap-4 max-lg:grid-cols-2 max-sm:grid-cols-1 max-sm:gap-2 [&>*]:flex [&>*]:flex-col [&>*]:gap-2 [&>*]:p-4 [&>*]:rounded-lg [&>*]:cursor-context-menu [&>*]:bg-gradient-to-t [&>*]:from-slate-50 [&>*]:to-slate-100">
                                <div className="">
                                    <div className="group flex justify-between items-start">
                                        <div className="flex flex-col">
                                            <span>Wallet</span>
                                            <strong className="text-green-500 font-bold text-xl mt-2">{`${userData?.wallet ?? '0.00'} MAD`}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="group flex justify-between items-start">
                                        <div className="flex flex-col">
                                            <span>All Appointments</span>
                                            <strong className="text-black font-bold text-xl mt-2">{userData?.total_appointments ?? '0'}</strong>
                                        </div>
                                        <button onClick={() => GoTo('Appointments')} className="outline-none text-slate-300 hover:text-slate-400 opacity-0 group-hover:opacity-100 max-lg:opacity-100">
                                            <GoScreenFull className="size-5" />
                                        </button>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="group flex justify-between items-start">
                                        <div className="flex flex-col">
                                            <span>Upcomming Appointments</span>
                                            <strong className="text-black font-bold text-xl mt-2">{userData?.upcomming_appointments ?? '0'}</strong>
                                        </div>
                                        <button onClick={() => GoTo('Appointments')} className="outline-none text-slate-300 hover:text-slate-400 opacity-0 group-hover:opacity-100 max-lg:opacity-100">
                                            <GoScreenFull className="size-5" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Overview;
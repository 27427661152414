import React from 'react';
import Layout from 'components/Layout/index';
import UsersAnalytics from 'components/Home/UsersAnalytics';
import AppointmentsAnalytics from 'components/Home/AppointmentsAnalytics';
import StatsByNumbers from 'components/Home/StatsByNumbers';
import LastReviews from 'components/Home/LastReviews';
import LastUsers from 'components/Home/LastUsers';
import { useFetch } from 'hooks/useSWR';
import { isEmpty } from 'helpers/utils';
import PageLoader from 'components/shared/Loaders/PageLoader';


const Dashboard = () => {

    const fetcher = useFetch()

    const { data, isLoading } = fetcher.getDashboardInformations();

    return (
        <Layout>
            <div className="px-4 py-10">
                <div className="max-w-[1400px] mx-auto">
                    <div className="flex items-center justify-between mb-6">
                        <div className="">
                            <h3 className='font-bold text-2xl flex items-center'>
                                Dashboard
                            </h3>
                            <p className='mt-1'>View All Wenaya Statistics.</p>
                        </div>
                    </div>

                    {isLoading && <PageLoader /> ||
                        !isEmpty(data) && (
                            <div className="grid-cols-2 max-sm:grid-cols-1 max-md:grid-cols-1 max-md:gap-8 grid gap-4">
                                <UsersAnalytics />

                                <AppointmentsAnalytics />

                                <StatsByNumbers
                                    data={data?.by_numbers}
                                />

                                <LastReviews
                                    reviews={data?.last_reviews}
                                />

                                <LastUsers
                                    users={data?.last_users}
                                />
                            </div>
                        )}
                </div>
            </div>
        </Layout>
    )
}
export default Dashboard;

/* eslint-disable react-hooks/rules-of-hooks */
import axios from "lib/axios";


const csrf = () => axios.get("/sanctum/csrf-cookie");


export const getAllPractices = async (page, searchQuery, sortBy) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/practices?page=${page}&query=${searchQuery}&sortby=${sortBy}`);
        return response.data;
    } catch (error) {
        console.error("get server side practices error", error);
    }
};

export const getAllAppointments = async (page, sortBy, appointmentStatus) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAllAppointments?page=${page}&sortby=${sortBy}&status=${appointmentStatus}`);
        return response.data;
    } catch (error) {
        console.error("getAllAppointments error", error);
    }
};

export const getPracticeById = async (id) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getPracticeById/${id}`);
        return response.data;
    } catch (error) {
        console.error("get server side user error", error);
    }
};

export const getAppointmentsByPracticeId = async (id, pageNumber, orderBy) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAppointmentsByPracticeId/${id}?page=${pageNumber}&sortby=${orderBy}`);
        return response.data;
    } catch (error) {
        console.error("getAppointmentsByPracticeId error", error);
    }
};

export const getServicesByPracticeId = async (id) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getServicesByPracticeId/${id}`);
        return response.data;
    } catch (error) {
        console.error("getServicesByPracticeId error", error);
    }
};

export const getTeamMembersByPracticeId = async (id, pageNumber) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getTeamMembersByPracticeId/${id}?page=${pageNumber}`);
        return response.data;
    } catch (error) {
        console.error("getTeamMembersByPracticeId error", error);
    }
};

export const getReviewsByPracticeId = async (id, pageNumber) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getReviewsByPracticeId/${id}?page=${pageNumber}`);
        return response.data;
    } catch (error) {
        console.error("getReviewsByPracticeId error", error);
    }
};

export const getAppointmentsByProfessionalId = async (id, pageNumber, orderBy) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAppointmentsByProfessionalId/${id}?page=${pageNumber}&sortby=${orderBy}`);
        return response.data;
    } catch (error) {
        console.error("getAppointmentsByProfessionalId error", error);
    }
};

export const getReviewsByProfessionalId = async (id, pageNumber) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getReviewsByProfessionalId/${id}?page=${pageNumber}`);
        return response.data;
    } catch (error) {
        console.error("getReviewsByProfessionalId error", error);
    }
};

export const getServicesByProfessionalId = async (id) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getServicesByProfessionalId/${id}`);
        return response.data;
    } catch (error) {
        console.error("getServicesByProfessionalId error", error);
    }
};

export const getAllPatients = async (page, searchQuery, sortBy) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAllPatients?page=${page}&query=${searchQuery}&sortby=${sortBy}`);
        return response.data;
    } catch (error) {
        console.error("getAllPatients error", error);
    }
};

export const getAllAdmins = async (page, searchQuery, sortBy) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAllAdmins?page=${page}&query=${searchQuery}&sortby=${sortBy}`);
        return response.data;
    } catch (error) {
        console.error("getAllAdmins error", error);
    }
};

export const getAllSpecialties = async (page, searchQuery, sortBy, status) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAllSpecialties?page=${page}&query=${searchQuery}&sortby=${sortBy}&status=${status}`);
        return response.data;
    } catch (error) {
        console.error("getAllSpecialties error", error);
    }
};

export const getAllPages = async (page, searchQuery, sortBy) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAllPages?page=${page}&query=${searchQuery}&sortby=${sortBy}`);
        return response.data;
    } catch (error) {
        console.error("getAllPages error", error);
    }
};

export const getAllLanguages = async (page, searchQuery, sortBy) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAllLanguages?page=${page}&query=${searchQuery}&sortby=${sortBy}`);
        return response.data;
    } catch (error) {
        console.error("getAllLanguages error", error);
    }
};

export const getAllCountries = async (page, searchQuery, sortBy) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAllCountries?page=${page}&query=${searchQuery}&sortby=${sortBy}`);
        return response.data;
    } catch (error) {
        console.error("getAllCountries error", error);
    }
};

export const getAllCities = async (page, searchQuery, sortBy) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAllCities?page=${page}&query=${searchQuery}&sortby=${sortBy}`);
        return response.data;
    } catch (error) {
        console.error("getAllCities error", error);
    }
};

export const getAllRegions = async (page, searchQuery, sortBy) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAllRegions?page=${page}&query=${searchQuery}&sortby=${sortBy}`);
        return response.data;
    } catch (error) {
        console.error("getAllRegions error", error);
    }
};

export const getAllTroubles = async (page, searchQuery, sortBy) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAllTroubles?page=${page}&query=${searchQuery}&sortby=${sortBy}`);
        return response.data;
    } catch (error) {
        console.error("getAllTroubles error", error);
    }
};

export const getDashboardInformations = async () => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getDashboardInformations`);
        console.log("response", response);
        return response.data;
    } catch (error) {
        console.error("get server side user error", error);
    }
};

export const getAppointmentsStats = async (days) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAppointmentsStats/${days}`);
        console.log("response", response);
        return response.data;
    } catch (error) {
        console.error("getAppointmentsStats error", error);
    }
};

export const getUsersStats = async (days) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getUsersStats/${days}`);
        console.log("response", response);
        return response.data;
    } catch (error) {
        console.error("getUsersStats error", error);
    }
};

export const getAllProfessionals = async (page, searchQuery, sortBy) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAllProfessionals?page=${page}&query=${searchQuery}&sortby=${sortBy}`);
        return response.data;
    } catch (error) {
        console.error("getAllProfessionals error", error);
    }
};

export const getAllCares = async (page, searchQuery, orderBy, careType, careStatus) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAllCares?page=${page}&query=${searchQuery}&sortby=${orderBy}&type=${careType}&status=${careStatus}`);
        return response.data;
    } catch (error) {
        console.error("getAllCares error", error);
    }
};

export const getSpecialitiesToCreateCare = async () => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getSpecialitiesToCreateCare`);
        return response.data;
    } catch (error) {
        console.error("getSpecialitiesToCreateCare error", error);
    }
};

export const getAllReviews = async (page, starsNumber) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAllReviews?page=${page}&stars=${starsNumber}`);
        return response.data;
    } catch (error) {
        console.error("getAllReviews error", error);
    }
};

export const getAllOfflinePatients = async (page, searchQuery, orderBy) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAllOfflinePatients?page=${page}&query=${searchQuery}&sortby=${orderBy}`);
        return response.data;
    } catch (error) {
        console.error("getAllOfflinePatients error", error);
    }
};

export const getUserInformations = async () => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/getUserInformations`);
        return response.data;
    } catch (error) {
        console.error("getUserInformations error", error);
    }
};

export const getProfessionalById = async (id) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getProfessionalById/${id}`);
        return response.data;
    } catch (error) {
        console.error("getProfessionalById error", error);
    }
};

export const getPatientById = async (id) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getPatientById/${id}`);
        return response.data;
    } catch (error) {
        console.error("getPatientById error", error);
    }
};

export const getOfflinePatientById = async (id) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getOfflinePatientById/${id}`);
        return response.data;
    } catch (error) {
        console.error("getOfflinePatientById error", error);
    }
};

export const getAppointmentsByOfflinePatientId = async (id, pageNumber, orderBy) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAppointmentsByOfflinePatientId/${id}?page=${pageNumber}&sortby=${orderBy}`);
        return response.data;
    } catch (error) {
        console.error("getAppointmentsByOfflinePatientId error", error);
    }
};

export const getAppointmentsByPatientId = async (id, pageNumber, orderBy) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAppointmentsByPatientId/${id}?page=${pageNumber}&sortby=${orderBy}`);
        return response.data;
    } catch (error) {
        console.error("getAppointmentsByPatientId error", error);
    }
};

export const getReviewsByPatientId = async (id, pageNumber) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getReviewsByPatientId/${id}?page=${pageNumber}`);
        return response.data;
    } catch (error) {
        console.error("getReviewsByPatientId error", error);
    }
};

export const getAllTranslatorLanguages = async () => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/translator/get`);
        return response.data;
    } catch (error) {
        console.error("getAllTranslatorLanguages error", error);
    }
};

export const getAllAdminPermissions = async () => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/adminPermissions/getAll`);
        return response.data;
    } catch (error) {
        console.error("getAllAdminPermissions error", error);
    }
};

export const getTickets = async (page, type) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getTickets?type=${type}&page=${page}`);
        return response.data;
    } catch (error) {
        console.error("getTickets error", error);
    }
};

export const deleteTicket = async (id) => {
    try {
        await csrf();
        const response = await axios.delete(`api/v1/forAdmin/deleteTicket/${id}`);
        return response.data;
    } catch (error) {
        console.error("deleteTicket error", error);
    }
};

export const changeTicketType = async (id,t) => {
    try {
        await csrf();
        const response = await axios.put(`api/v1/forAdmin/changeTicketType/${id}/${t}`);
        return response.data;
    } catch (error) {
        console.error("changeTicketType error", error);
    }
};

export const getAllContactRequests = async (page, searchQuery, sortBy, status) => {
    try {
        await csrf();
        const response = await axios.get(`api/v1/forAdmin/getAllContactRequests?page=${page}&query=${searchQuery}&sortby=${sortBy}&status=${status}`);
        return response.data;
    } catch (error) {
        console.error("getAllContactRequests error", error);
    }
};
import { isEmpty } from "helpers/utils";
import { GetRelativeTime } from "lib/GetRelativeTime";

export const TicketsList = ({ tickets, selectedTicketId, setSelectedTicketId }) => {

    return (
        <>
            {
                !isEmpty(tickets) && (
                    tickets.map((ticket, index) => (
                        <div
                            key={ticket.id}
                            onClick={() => setSelectedTicketId(ticket.id)}
                            className={`flex flex-col gap-1 px-2 py-3 cursor-pointer text-sm w-full ${Number(selectedTicketId) == ticket.id ? 'bg-gray-100 border-l-2 border-gray-400' : 'hover:bg-gray-100 border-l hover:border-gray-300'}`}>
                            <div className="flex items-start justify-between">
                                <span className='font-semibold'>{`#${ticket.id}`}</span>
                                <span className='text-xs text-gray-500'>{GetRelativeTime(ticket.created_at)}</span>
                            </div>
                            <span>{ticket.email}</span>
                            <p className='line-clamp-1'>{ticket.message}</p>
                        </div>
                    ))
                ) || (
                    <div className="px-2 py-3 text-sm">
                        <p className="text-gray-500">No results found!</p>
                    </div>
                )
            }
        </>
    )
}
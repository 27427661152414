import useSWR from "swr";
import { useState } from "react";
import { useCookies } from "react-cookie";
import axios from "lib/axios";


export const useAuth = () => {
    const [cookies, setCookies, removeCookies] = useCookies();

    const [loading, setLoading] = useState(false);


    const goToLoginPage = () => {
        const currentPage = window.location.pathname;
        if (currentPage !== '/auth/login') {
            window.location.href = '/auth/login';
        }
    }

    const { data: user, error, isLoading: userIsLoading, mutate } = useSWR(cookies.isAuth ? "/api/v1/forAdmin/admin" : null, () =>
        axios.get(cookies.isAuth ? "/api/v1/forAdmin/admin" : null)
            .then(async res => {
                const userData = res.data.data;

                // save isAuth cookie
                setCookies("isAuth", true, {
                    expires: new Date(2147483647 * 1000),
                    secure: process.env.NODE_ENV == "production",
                    sameSite: 'strict',
                    path: '/'
                });

                return userData;
            })
            .catch(async error => {
                if (error.response.data.message == 'Fresh Access Token Required') {
                    removeCookies("isAuth");
                    goToLoginPage()
                }

                if (error.response.status !== 409) {
                    throw error;
                }

                window.location.href = '/auth/login';
            })
    );


    const csrf = () => axios.get("/sanctum/csrf-cookie").catch(() => setLoading(false));


    const login = async ({ setErrors, ...props }) => {
        setLoading(true);
        await csrf();

        setErrors([]);

        await
            axios
                .post("/login", props)
                .then(({ data }) => {
                    setLoading(false);

                    if (data.error) {
                        setErrors({ email: data.message })
                        return
                    }

                    // save isAuth cookie
                    setCookies("isAuth", true, {
                        expires: new Date(2147483647 * 1000),
                        secure: process.env.NODE_ENV == "production",
                        sameSite: 'strict',
                        path: '/'
                    });

                    mutate();

                    window.location.href = '/';
                })
                .catch(error => {
                    console.log("error", error);
                    removeCookies("isAuth");
                    setLoading(false);
                });
    };


    const logout = async () => {
        await axios.post("/logout");
        removeCookies("isAuth");
        window.location.pathname = "/auth/login";
    };


    return {
        csrf,
        user,
        userIsLoading,
        login,
        logout,
        loading,
    };
};

import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout/index';
import adminPermissions from "lib/adminPermissions"
import axios from 'lib/axios';
import { useAuth } from 'hooks/useAuth';
import useAlert from 'components/Alerts/useAlert';
import BtnLoader from 'components/Loaders/BtnLoader';
import { useFetch } from 'hooks/useSWR';
import PageLoader from 'components/shared/Loaders/PageLoader';
import PermissionsList from 'components/Pages/Permissions/PermissionsList';


const Permissions = () => {
    const { renderAlertMessages, showMessage } = useAlert();

    const { csrf } = useAuth()

    // Get all permissions that are already saved in database.
    const fetcher = useFetch()
    const { data: permissionsData, isLoading, mutate } = fetcher.getAllAdminPermissions();
    const refresh = () => mutate()


    const [permissions, setPermissions] = useState([]);
    const [staticPermissions, setStaticPermissions] = useState(adminPermissions);


    // Merge And Set Static Permissions that are not in existing permissions
    useEffect(() => {
        const _per = permissionsData?.data ?? [];

        const newPermissions = staticPermissions.filter(
            (staticPermission) =>
                !_per.some((permission) => permission.slug === staticPermission.slug)
        );

        setPermissions([..._per, ...newPermissions]);

    }, [permissionsData])


    // Change Values
    const handleChange = (name, value, index) => {
        const updatedPermissions = permissions.map((permission, i) => {
            if (i === index) {
                // Update the value by key name
                return { ...permission, [name]: value };
            }
            return permission;
        });

        setPermissions(updatedPermissions)
    }


    // Save Changes
    const [isProgress, setInProgress] = useState(false)
    const saveChanges = async () => {

        const formData = {
            permissions
        }

        try {
            setInProgress(true)

            await csrf()

            const { data } = await axios.put(`/api/v1/forAdmin/adminPermissions/saveChanges`, formData)

            if (!data.error) {
                await refresh();
                showMessage(data.message, 'success');
            }

            setInProgress(false)
        } catch (error) {
            showMessage('something was wrong', 'error');
            setInProgress(false)
        }
    }

    return (
        <Layout>
            {renderAlertMessages}
            <div className="px-4 py-10 max-sm:px-0">
                <div className='max-w-[1200px] mx-auto'>
                    {/* Header */}
                    <div className="flex items-center justify-between mb-6">
                        <div className="max-sm:px-4">
                            <h3 className='font-bold text-2xl flex items-center'>
                                Permissions List
                                <span className='bg-bgPrimary border px-2 py-1 ml-4 rounded-3xl text-sm font-semibold'>
                                    {permissions?.length ?? '0'}
                                </span>
                            </h3>
                            <p className='mt-1'>View, Edit, Delete All Permissions.</p>
                        </div>
                        <button
                            disabled={isProgress || isLoading}
                            onClick={() => saveChanges()}
                            className='px-4 py-2 bg-black hover:opacity-90 text-white text-lg font-bold rounded-md whitespace-nowrap flex items-center gap-2
                                max-sm:fixed max-sm:bottom-4 max-sm:right-4'>
                            <span className='hidden sm:block'>{!isProgress ? `Save Changes` : <BtnLoader />}</span>
                            <span className='block sm:hidden'>{!isProgress ? `Save` : <BtnLoader withText={false} />}</span>
                        </button>
                    </div>


                    {isLoading && <PageLoader /> || (
                        <PermissionsList
                            data={permissions}
                            handleChange={handleChange}
                        />
                    )}
                </div>
            </div>
        </Layout>
    )
}
export default Permissions;
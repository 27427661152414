import React, { useState } from "react";
import SelectCard from "./SelectCard";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from "chart.js";
import { Bar } from "react-chartjs-2"
import { useFetch } from "hooks/useSWR";
import PageLoader from "components/shared/Loaders/PageLoader";


function AppointmentsAnalytics() {

    const [periodByDays, setPeriodByDays] = useState(7) // period by days
    const [period, setPeriod] = useState(7) // period by days
    const changePeriod = e => setPeriod(e.target.value);

    const fetcher = useFetch()
    const { data: res, isLoading, mutate } = fetcher.getAppointmentsStats(periodByDays);

    const research = () => {
        setPeriodByDays(period)
        mutate()
    };

    const cancel = () => {
        setPeriodByDays(periodByDays)
    };

    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

    const data = {
        labels: res?.days ?? [],
        datasets: [
            {
                label: "Appointments",
                data: res?.data ?? [],
                backgroundColor: "#9696ff",
            }
        ],
    };

    const options = {
        indexAxis: "x",
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div className=" bg-white rounded-lg p-4 border border-gray-300 h-full w-full">
            <div className="flex justify-between pb-4 border-b border-gray-100">
                <div>
                    <h2 className="text-xl mb-2 font-semibold ">Appointments Analytics</h2>
                    <p className="text-gray-500 font-sans">{`Last ${periodByDays} days`}</p>
                </div>
                <div className="relative">
                    <SelectCard
                        period={period}
                        changePeriod={changePeriod}
                        research={research}
                        cancel={cancel}
                    />
                </div>
            </div>

            {isLoading && <PageLoader/> || (
                <div className="mt-4">
                    <Bar data={data} options={options} />
                </div>
            )}
        </div>
    );
}

export default AppointmentsAnalytics;

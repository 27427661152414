import React from "react";


function StatsByNumbers({ data }) {

  const visits = data.visits;
  const users = data.users;
  const practices = data.practices;
  const professionals = data.professionals;
  const patients = data.patients;
  const appointments = data.appointments;

  return (
    <div className="grid-cols-2 max-sm:grid-cols-1 max-md:grid-cols-1 grid gap-4">
      <div className="bg-white border border-gray-300 rounded-lg p-4 flex flex-col gap-4">
        <h3>Visits</h3>
        <span className="font-extrabold text-3xl text-[#333]">{visits}</span>
      </div>
      <div className="bg-white border border-gray-300 rounded-lg p-4 flex flex-col gap-4">
        <h3>Users</h3>
        <span className="font-extrabold text-3xl text-[#333]">{users}</span>
      </div>
      <div className="bg-white border border-gray-300 rounded-lg p-4 flex flex-col gap-4">
        <h3>Practices</h3>
        <span className="font-extrabold text-3xl text-[#333]">{practices}</span>
      </div>
      <div className="bg-white border border-gray-300 rounded-lg p-4 flex flex-col gap-4">
        <h3>Professionals</h3>
        <span className="font-extrabold text-3xl text-[#333]">{professionals}</span>
      </div>
      <div className="bg-white border border-gray-300 rounded-lg p-4 flex flex-col gap-4">
        <h3>Patients</h3>
        <span className="font-extrabold text-3xl text-[#333]">{patients}</span>
      </div>
      <div className="bg-white border border-gray-300 rounded-lg p-4 flex flex-col gap-4">
        <h3>Appointment</h3>
        <span className="font-extrabold text-3xl text-[#333]">{appointments}</span>
      </div>
    </div>
  );
}

export default StatsByNumbers;

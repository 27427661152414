import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { IoMdClose } from "react-icons/io";

export default function ConfirmationModal({ isOpen, title, message, buttonName, onConfirm, onCancel }) {
    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={onCancel}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center text-center lg:min-h-full lg:p-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95">
                                <Dialog.Panel
                                    className="w-screen h-screen p-4 overflow-hidden text-left align-middle transition-all transform bg-white lg:h-max lg:w-full lg:max-w-lg lg:rounded-lg lg:shadow-xl">
                                    <div className="flex items-center justify-between">
                                        <h2 className="text-lg font-extrabold text-gray-900">{title}</h2>
                                        <button
                                            type="button"
                                            className="justify-center outline-none p-1 bg-gray-50 hover:bg-gray-100 rounded-md"
                                            onClick={onCancel}>
                                            <IoMdClose className="w-6 h-6" />
                                        </button>
                                    </div>

                                    <div className="py-6 my-4 border-gray-200 border-y">
                                        {message}
                                    </div>

                                    <div className="hidden lg:flex lg:justify-end lg:gap-3">
                                        <button onClick={onCancel} type="button" className="p-2 px-5 font-semibold text-black bg-white border border-gray-300 rounded-md text-md hover:bg-gray-100">
                                            Annuler
                                        </button>
                                        <button onClick={() => onConfirm()} type="button" className="p-3 px-5 font-semibold text-white bg-red-500 rounded-md text-md hover:bg-red-600">
                                            {buttonName ?? 'OK'}
                                        </button>
                                    </div>
                                    <div className="fixed bottom-0 z-40 flex justify-between w-screen gap-2 py-4 pr-12 bg-white border-t border-gray-300 lg:hidden">
                                        <button onClick={onCancel} type="button" className="w-1/2 p-3 font-semibold text-black bg-white border border-gray-300 rounded-md text-md hover:bg-gray-100">
                                            Annuler
                                        </button>
                                        <button onClick={() => onConfirm()} type="button" className="w-1/2 p-3 font-semibold text-white bg-red-500 rounded-md text-md hover:bg-red-600">
                                            {buttonName ?? 'OK'}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
import React from 'react';
import DashboardPage from 'pages/Dashboard';
import Login from 'pages/Auth/Login';
import Settings from 'pages/Settings';
import Patients from 'pages/Patient/Patients';
import Patient from 'pages/Patient/Patient';
import UpdatePatient from 'pages/Patient/Update';
import CreatePatient from 'pages/Patient/Create';
import Professionals from 'pages/Professional/Professionals';
import Professional from 'pages/Professional/Professional';
import UpdateProfessional from 'pages/Professional/Update';
import CreateProfessional from 'pages/Professional/Create';
import Practices from 'pages/Practice/Practices';
import CreatePractice from 'pages/Practice/Create';
import Practice from 'pages/Practice/Practice';
import OfflinePatients from 'pages/OfflinePatient/OfflinePatients';
import OfflinePatient from 'pages/OfflinePatient/OfflinePatient';
import UpdateOfflinePatient from 'pages/OfflinePatient/Update';
import Languages from 'pages/Languages';
import Countries from 'pages/Countries';
import Cities from 'pages/Cities';
import Categories from 'pages/Categories';
import Regions from 'pages/Regions';
import Specializations from 'pages/Specializations';
import Appointments from 'pages/Appointments';
import Reviews from 'pages/Reviews';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Pages from 'pages/Pages';
import Troubles from 'pages/Troubles';
import Cares from 'pages/Cares';
import Account from 'pages/Account';
import Translator from 'pages/Translator';
import Admins from 'pages/Admins';
import Permissions from 'pages/Permissions';
import UpdatePractice from 'pages/Practice/Update';
import "App.css"
import NotFound from 'pages/404';
import { useAuth } from 'hooks/useAuth';
import PageLoader from 'components/shared/Loaders/PageLoader';
import Tickets from 'pages/Tickets';
import ContactRequests from 'pages/ContactRequests';


const App = () => {


    const { user: admin, userIsLoading: adminIsLoading } = useAuth()
    const {
        ViewOverviewPage,
        ViewPracticesList,
        ViewPracticePage,
        UpdatePracticeInformations,
        ViewProfessionalsList,
        ViewProfessionalPage,
        UpdateProfessionalInformations,
        ViewAppointmentsList,
        ViewCaresList,
        ViewReviewsList,
        ViewPatientsList,
        ViewPatientPage,
        UpdatePatientInformations,
        ViewAdminsList,
        ViewAccountPage,
        ViewSpecialitiesList,
        ViewLanguagesList,
        ViewCountriesList,
        ViewCitiesList,
        ViewRegionsList,
        ViewOfflinePatientsList,
        ViewOfflinePatientsPage,
        UpdateOfflinePatientInformations,
        ViewPagesList,
        ViewCategoriesList,
        ViewTroublesList,
        ViewTranslatorList,
        ViewPermissionsList,
        ViewTicketsList,
        ViewContactRequestsList,
    } = !adminIsLoading && admin && admin.roles || {};


    const notFound = () => <NotFound />

    const returnElement = (pageComponent, authorize) => {
        return authorize ? pageComponent : notFound();
    }


    return adminIsLoading ? <PageLoader /> : (
        <BrowserRouter>
            <Routes>
                <Route exact path='/' element={returnElement(<DashboardPage />, ViewOverviewPage)}></Route>
                <Route exact path='/auth/login' element={returnElement(<Login />, true)}></Route>
                <Route exact path='/settings' element={returnElement(<Settings />, true)}></Route>
                <Route exact path='/patients' element={returnElement(<Patients />, ViewPatientsList)}></Route>
                <Route exact path='/patient/:id' element={returnElement(<Patient />, ViewPatientPage)}></Route>
                <Route exact path='/patient/:id/update' element={returnElement(<UpdatePatient />, UpdatePatientInformations)}></Route>
                <Route exact path='/patient/create' element={returnElement(<CreatePatient />, ViewPatientsList)}></Route>
                <Route exact path='/professionals' element={returnElement(<Professionals />, ViewProfessionalsList)}></Route>
                <Route exact path='/professional/:id' element={returnElement(<Professional />, ViewProfessionalPage)}></Route>
                <Route exact path='/professional/:id/update' element={returnElement(<UpdateProfessional />, UpdateProfessionalInformations)}></Route>
                <Route exact path='/professional/create' element={returnElement(<CreateProfessional />, ViewProfessionalPage)}></Route>
                <Route exact path='/practices' element={returnElement(<Practices />, ViewPracticesList)}></Route>
                <Route exact path='/practices/create' element={returnElement(<CreatePractice />, true)}></Route>
                <Route exact path='/practice/:id' element={returnElement(<Practice />, ViewPracticePage)}></Route>
                <Route exact path='/practice/:id/update' element={returnElement(<UpdatePractice />, UpdatePracticeInformations)}></Route>
                <Route exact path='/offline-patients' element={returnElement(<OfflinePatients />, ViewOfflinePatientsList)}></Route>
                <Route exact path='/offline_patient/:id' element={returnElement(<OfflinePatient />, ViewOfflinePatientsPage)}></Route>
                <Route exact path='/offline_patient/:id/update' element={returnElement(<UpdateOfflinePatient />, UpdateOfflinePatientInformations)}></Route>
                <Route exact path='/languages' element={returnElement(<Languages />, ViewLanguagesList)}></Route>
                <Route exact path='/countries' element={returnElement(<Countries />, ViewCountriesList)}></Route>
                <Route exact path='/cities' element={returnElement(<Cities />, ViewCitiesList)}></Route>
                <Route exact path='/regions' element={returnElement(<Regions />, ViewRegionsList)}></Route>
                <Route exact path='/categories' element={returnElement(<Categories />, ViewCategoriesList)}></Route>
                <Route exact path='/specializations' element={returnElement(<Specializations />, ViewSpecialitiesList)}></Route>
                <Route exact path='/reviews' element={returnElement(<Reviews />, ViewReviewsList)}></Route>
                <Route exact path='/appointments' element={returnElement(<Appointments />, ViewAppointmentsList)}></Route>
                <Route exact path='/pages' element={returnElement(<Pages />, ViewPagesList)}></Route>
                <Route exact path='/troubles' element={returnElement(<Troubles />, ViewTroublesList)}></Route>
                <Route exact path='/cares' element={returnElement(<Cares />, ViewCaresList)}></Route>
                <Route exact path='/account' element={returnElement(<Account />, ViewAccountPage)}></Route>
                <Route exact path='/translator' element={returnElement(<Translator />, ViewTranslatorList)}></Route>
                <Route exact path='/admins' element={returnElement(<Admins />, ViewAdminsList)}></Route>
                <Route exact path='/permissions' element={returnElement(<Permissions />, ViewPermissionsList)}></Route>
                <Route exact path='/tickets' element={returnElement(<Tickets />, ViewTicketsList)}></Route>
                <Route exact path='/contact-requests' element={returnElement(<ContactRequests />, ViewContactRequestsList)}></Route>
                <Route exact path='*' element={returnElement(<NotFound />, true)}></Route>
            </Routes>
        </BrowserRouter>
    )
}
export default App;

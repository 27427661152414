import dayjs from "../../../lib/dayjs";


const Details = ({ professionalData }) => {

    const _professional = professionalData;
    const _user = _professional?.user ?? {};

    return (
        <div className="bg-white sm:rounded-xl sm:border max-sm:border-t border-gray-200 mt-4 overflow-hidden">
            <div className="flex items-center justify-between p-4">
                <h3 className='font-bold text-xl flex items-center'>
                    Details
                </h3>
            </div>
            <div className="max-w-full overflow-auto">
                <table className='w-full'>
                    <tbody className="[&>*]:border-t [&>*]:border-gray-200">
                        <tr className='[&>*]:py-4 [&>*]:px-4 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-context-menu'>
                            <td className="text-black font-bold">Full-Name</td>
                            <td className="text-gray-700">{`${_user.first_name} ${_user.last_name} (@${_user.username})`}</td>
                        </tr>
                        <tr className='[&>*]:py-4 [&>*]:px-4 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-context-menu'>
                            <td className="text-black font-bold">Email</td>
                            <td className="text-gray-700">{_user.email}</td>
                        </tr>
                        <tr className='[&>*]:py-4 [&>*]:px-4 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-context-menu'>
                            <td className="text-black font-bold">Phone</td>
                            <td className="text-gray-700">{`${_user.country_code} ${_user.phone}`}</td>
                        </tr>
                        <tr className='[&>*]:py-4 [&>*]:px-4 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-context-menu'>
                            <td className="text-black font-bold">Business-Name</td>
                            <td className="text-gray-700">{_professional.business}</td>
                        </tr>
                        <tr className='[&>*]:py-4 [&>*]:px-4 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-context-menu'>
                            <td className="text-black font-bold">Website</td>
                            <td className="text-gray-700">{_professional.siteweb}</td>
                        </tr>
                        <tr className='[&>*]:py-4 [&>*]:px-4 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-context-menu'>
                            <td className="text-black font-bold">Facebook</td>
                            <td className="text-gray-700">{_professional.facebook}</td>
                        </tr>
                        <tr className='[&>*]:py-4 [&>*]:px-4 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-context-menu'>
                            <td className="text-black font-bold">Instagram</td>
                            <td className="text-gray-700">{_professional.instagram}</td>
                        </tr>
                        <tr className='[&>*]:py-4 [&>*]:px-4 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-context-menu'>
                            <td className="text-black font-bold">Twitter</td>
                            <td className="text-gray-700">{_professional.twitter}</td>
                        </tr>
                        <tr className='[&>*]:py-4 [&>*]:px-4 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-context-menu'>
                            <td className="text-black font-bold">Slug</td>
                            <td className="text-gray-700">{_professional.slug}</td>
                        </tr>
                        <tr className='[&>*]:py-4 [&>*]:px-4 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-context-menu'>
                            <td className="text-black font-bold">Registered Date</td>
                            <td className="text-gray-700">{dayjs(_professional.created_at).format("D MMMM, YYYY")}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default Details;
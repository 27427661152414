import BoxModal from "components/Layout/BoxModal";
import Editor from "components/shared/Editor";
import { useAuth } from "hooks/useAuth";
import axios from "lib/axios";
import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import BtnLoader from "../../Loaders/BtnLoader";
import useAlert from "components/Alerts/useAlert";
import { useFetch } from "hooks/useSWR";
import ImagePreview from "components/Preview/ImagePreview";
import Select from 'react-select'
import { isEmpty } from "helpers/utils";


export default function UpdateTrouble({ selectedTrouble, setSelectedTrouble, isOpen, refresh }) {
    const { renderAlertMessages, showMessage } = useAlert();

    const closeModal = () => setSelectedTrouble({})

    const { csrf } = useAuth()


    // Get Specialities
    const fetcher = useFetch()
    const { data: specialitiesData, isLoading: specialitiesIsLoading } = fetcher.getSpecialitiesToCreateCare();


    const [troubleId, setTroubleId] = useState('')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [details, setDetails] = useState('')
    const [webImage, setWebImage] = useState('')
    const [mobileImage, setMobileImage] = useState('')
    const [specialities, setSpecialities] = useState([])

    const [isLoading, setIsLoading] = useState(false)


    const updateTrouble = async () => {

        const selectedSpec = !isEmpty(specialities) ? specialities.map(item => item.id).join(',') : '';

        const formData = {
            trouble_id: troubleId,
            name,
            description,
            details,
            image_web: webImage,
            image_mobile: mobileImage,
            specialties: selectedSpec,
        };

        try {

            setIsLoading(true)

            await csrf()

            const res = await axios.put(`/api/v1/forAdmin/updateTrouble`, formData)
            const data = await res.data;

            if (!data.error) {
                await refresh();

                showMessage(data.message, 'success');

                closeModal()
            }

            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            showMessage('something was wrong', 'error');
        }
    }


    useEffect(() => {
        const t = selectedTrouble;
        setTroubleId(t.id)
        setName(t.name)
        setDescription(t.description)
        setDetails(t.details)
        setWebImage('')
        setMobileImage('')


        // Modify each item in the array
        const updatedValues = t?.specialties?.map(item => {
            return {
                ...item,
                label: item.fr_name,
                value: item.id
            };
        });
        // Set the updated items to state
        setSpecialities(updatedValues);

    },[selectedTrouble])


    // State to hold the modified specialities
    const [specOptions, setSpecOptions] = useState([]);

    useEffect(() => {
        // Modify each item in the array
        const updatedItems = specialitiesData?.data?.map(item => {
            return {
                ...item,
                label: item.fr_name,
                value: item.id
            };
        });

        // Set the updated items to state
        setSpecOptions(updatedItems);
    }, [specialitiesData]);

    return (
        <>
            {renderAlertMessages}
            <BoxModal
                isOpen={isOpen}
                onClose={closeModal}
                content={
                    <>
                        <div className="flex items-center justify-between p-4 border-b border-gray-300 bg-white">
                            <h2 className="text-lg font-extrabold text-black">Update Trouble</h2>
                            <button
                                type="button"
                                className="justify-center outline-none p-1 bg-gray-50 hover:bg-gray-100 rounded-md"
                                onClick={closeModal}>
                                <IoMdClose className="w-6 h-6" />
                            </button>
                        </div>

                        <div className="px-4 py-6 bg-white max-h-[60vh] max-lg:max-h-[80vh] overflow-y-auto">
                            <label className='flex flex-col mb-4'>
                                <span className='mb-1 text-base font-semibold text-black'>Name</span>
                                <input
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    className='outline-none border border-gray-200 px-3 py-2 rounded-lg text-sm font-medium hover:border-gray-300 focus:border-gray-400'
                                    type="text"
                                />
                            </label>

                            <label className='flex flex-col mb-4'>
                                <span className='mb-1 text-base font-semibold text-black'>Description</span>
                                <Editor
                                    name="content"
                                    value={description}
                                    onChange={(e, { value }) => setDescription(value)}
                                />
                            </label>

                            <label className='flex flex-col mb-4'>
                                <span className='mb-1 text-base font-semibold text-black'>Details</span>
                                <Editor
                                    name="content"
                                    value={details}
                                    onChange={(e, { value }) => setDetails(value)}
                                />
                            </label>

                            <div className='grid gap-4 grid-cols-2 max-sm:grid-cols-1 max-sm:gap-0'>
                                <label className='flex flex-col mb-4'>
                                    <span className='mb-1 text-base font-semibold text-black'>Web Image</span>
                                    <ImagePreview
                                        changeImage={setWebImage}
                                        button={
                                            <div className='outline-none border px-3 py-2 rounded-lg text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400 bg-gray-100 cursor-pointer'>
                                                Choose Web Image
                                            </div>
                                        }
                                    />
                                </label>
                                <label className='flex flex-col mb-4'>
                                    <span className='mb-1 text-base font-semibold text-black'>Mobile Image</span>
                                    <ImagePreview
                                        changeImage={setMobileImage}
                                        button={
                                            <div className='outline-none border px-3 py-2 rounded-lg text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400 bg-gray-100 cursor-pointer'>
                                                Choose Mobile Image
                                            </div>
                                        }
                                    />
                                </label>
                            </div>
                            
                            <div className='flex flex-col mb-4'>
                                <label className='flex flex-col mb-4'>
                                    <span className='mb-1 text-base font-semibold text-black'>Specialities</span>
                                    <Select
                                        closeMenuOnSelect={false}
                                        isMulti
                                        onChange={(newValue, actionMeta) => {
                                            setSpecialities(newValue)
                                        }}
                                        value={specialities}
                                        options={specOptions}
                                    />
                                </label>
                            </div>
                        </div>

                        <div
                            className={`
                                bg-white border-t border-gray-300 p-4
                                max-lg:absolute max-lg:w-screen max-lg:grid max-lg:grid-cols-2 max-lg:gap-4 max-lg:right-0 max-lg:bottom-0
                                lg:flex lg:items-center lg:flex-row-reverse
                            `}>
                            <button onClick={updateTrouble} disabled={isLoading} type="button" className="py-2 px-6 font-semibold text-white bg-buttonBg-1 rounded-md text-md hover:bg-buttonBg-2 border border-buttonBg-1 lg:ml-4">
                                {!isLoading ? 'Save' : <BtnLoader />}
                            </button>
                            <button onClick={closeModal} disabled={isLoading} type="button" className="py-2 px-6 font-semibold text-black bg-white border border-gray-300 rounded-md text-md hover:bg-gray-100">
                                Annuler
                            </button>
                        </div>
                    </>
                }
            />
        </>
    )
}
import { IoMdClose } from "react-icons/io";
import BoxModal from "components/Layout/BoxModal";
import { useEffect, useState } from "react";
import { isEmpty } from "helpers/utils";


export default function FilterOfflinePatients({refresh, data, button }) {
    const [isOpen, setIsOpen] = useState(false);
    const closeModal = () => setIsOpen(false);
    const openModal = () => setIsOpen(true);


    const [orderBy, setOrderBy] = useState('');


    const sortBy = [
        {value:'new', name:'Newest first'},
        {value:'old', name:'Oldest first'},
    ];


    const reSetData = () => {
        setOrderBy(data?.orderBy ?? '');
    }


    const GoSearch = () => {
        refresh(orderBy)
    }


    useEffect(() => {
        reSetData()
    },[data])

    return (
        <>
            <div onClick={openModal} className="w-fit">
                {button}
            </div>
            <BoxModal
                isOpen={isOpen}
                onClose={closeModal}
                content={
                    <>
                        <div className="flex items-center justify-between p-4 border-b border-gray-300 bg-white">
                            <h2 className="text-lg font-extrabold text-black">Filter OfflinePatients</h2>
                            <button
                                type="button"
                                className="justify-center outline-none p-1 bg-gray-50 hover:bg-gray-100 rounded-md"
                                onClick={closeModal}>
                                <IoMdClose className="w-6 h-6" />
                            </button>
                        </div>

                        <div className="px-4 py-6 bg-white max-h-[60vh] max-lg:max-h-[80vh] overflow-y-auto text-sm">
                            
                                <div className="flex flex-col gap-2 mb-4">
                                    <label className="text-md font-bold text-black">Sort by</label>
                                    <select
                                        onChange={(e) => setOrderBy(e.target.value)}
                                        value={orderBy}
                                        className="border border-gray-200 hover:border-gray-300 bg-white text-md rounded-md px-3 py-3 text-gray-700 outline-none cursor-pointer transition-all duration-300">
                                        {!isEmpty(sortBy) && (
                                            sortBy.map((item, i) => (
                                                <option key={i} value={item.value}>{item.name}</option>
                                            ))
                                        )}
                                    </select>
                                </div>

                        </div>

                        <div
                            className={`
                                bg-white border-t border-gray-300 p-4
                                max-lg:absolute max-lg:w-screen max-lg:grid max-lg:grid-cols-2 max-lg:gap-4 max-lg:right-0 max-lg:bottom-0
                                lg:flex lg:items-center lg:flex-row-reverse
                            `}>
                            <button onClick={() => { setIsOpen(false); GoSearch(); }} type="button" className="py-2 px-6 font-semibold text-white bg-buttonBg-1 rounded-md text-md hover:bg-buttonBg-2 border border-buttonBg-1 lg:ml-4">
                                Save
                            </button>
                            <button onClick={() => { reSetData(); setIsOpen(false); }}
                                type="button" className="py-2 px-6 font-semibold text-black bg-white border border-gray-300 rounded-md text-md hover:bg-gray-100">
                                Annuler
                            </button>
                        </div>
                    </>
                }
            />
        </>
    );
}

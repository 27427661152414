import { useAuth } from "hooks/useAuth";
import axios from "lib/axios";
import { useState } from "react";
import ConfirmationModal from "components/Alerts/ConfirmationModal";
import BtnLoader from "components/Loaders/BtnLoader";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import { isEmpty } from "helpers/utils";
import UpdateCare from "./UpdateCare";
import useAlert from "components/Alerts/useAlert";
import { CgClose } from "react-icons/cg";

export default function CaresList({ cares, refresh }) {
    const { renderAlertMessages, showMessage } = useAlert();

    const { csrf } = useAuth()

    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false)
    const [careIdToDelete, setCareIdToDelete] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const deleteCare = async () => {
        try {

            const id = careIdToDelete;

            setIsLoading(true)

            await csrf()

            const { data } = await axios.delete(`/api/v1/forAdmin/deleteCare/${id}`)

            if (!data.error) {
                await refresh();
                showMessage(data.message, 'success');
                setCareIdToDelete('')
            }

            setIsLoading(false)
        } catch (error) {
            showMessage('something was wrong', 'error');
            setIsLoading(false)
        }
    }

    // set care data to update it
    const [selectedCare, setSelectedCare] = useState({})

    return (
        <>
            {renderAlertMessages}
            <section>
                <div className="overflow-hidden bg-white">
                    <div className="max-w-full overflow-auto min-h-[100px]">
                        <table className='w-full'>
                            <thead>
                                <tr className='[&>*]:px-4 [&>*]:py-2 [&>*]:text-black [&>*]:whitespace-nowrap [&>*]:text-start'>
                                    <th className=''>Id</th>
                                    <th className=''>Name</th>
                                    <th className=''>Type</th>
                                    <th className=''>isVisible</th>
                                    <th className=''>Web img</th>
                                    <th className=''>Mobile img</th>
                                    <th className=''></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isEmpty(cares) && (
                                    cares.map((care) => {

                                        const inProgressToDelete = isLoading && careIdToDelete == care.id;

                                        return (
                                            <tr key={care.id} className='hover:bg-bgPrimary border-t border-gray-200 [&>*]:py-6 [&>*]:px-4 [&>*]:text-gray-700 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-pointer transition-colors duration-200'>
                                                <td className='font-bold'>{care.id}</td>
                                                <td className=''>{care.name}</td>
                                                <td className=''>{care.type}</td>
                                                <td className=''>{care.is_visible ? 'Yes' : 'No'}</td>
                                                <td className=''>
                                                    <div className='w-12 h-12 rounded-xl overflow-hidden bg-slate-100 border'>
                                                        {care.image_web &&
                                                            <img src={care.image_web} alt={care.name} className='w-full h-full rounded-xl' />}
                                                    </div>
                                                </td>
                                                <td className=''>
                                                    <div className='w-12 h-12 rounded-xl overflow-hidden bg-slate-100 border'>
                                                        {care.image_mobile &&
                                                            <img src={care.image_mobile} alt={care.name} className='w-full h-full rounded-xl' />}
                                                    </div>
                                                </td>
                                                <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                    <div className="flex items-center justify-center w-fit float-right">
                                                        <button
                                                            disabled={inProgressToDelete}
                                                            onClick={() => setSelectedCare(care)}
                                                            className="text-green-500 hover:text-white transition-colors duration-200 bg-transparent hover:bg-green-500 focus:outline-none p-2 rounded-md">
                                                            <TbEdit className="w-5 h-5" />
                                                        </button>
                                                        <button
                                                            disabled={inProgressToDelete}
                                                            onClick={() => {
                                                                setCareIdToDelete(care.id)
                                                                setConfirmationModalIsOpen(true)
                                                            }}
                                                            className="text-red-500 hover:text-white transition-colors duration-200 bg-transparent hover:bg-red-500 focus:outline-none p-2 rounded-md">
                                                            {!inProgressToDelete
                                                                ? <CgClose className="w-5 h-5" />
                                                                : <BtnLoader withText={false} />}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>


            {/* Update */}
            <UpdateCare
                selectedCare={selectedCare}
                setSelectedCare={setSelectedCare}
                isOpen={!isEmpty(selectedCare) ? true : false}
                refresh={refresh}
            />

            {/* Delete Care */}
            <ConfirmationModal
                isOpen={confirmationModalIsOpen}
                title="Delete Care"
                message="Are you sure you want to delete care?"
                buttonName="Supprimer"
                onConfirm={() => {
                    setConfirmationModalIsOpen(false);
                    deleteCare()
                }}
                onCancel={() => {
                    setConfirmationModalIsOpen(false);
                }}
            />
        </>
    )
}
import React from 'react';
import dayjs from "../../../lib/dayjs";


const AsideBar = ({ user }) => {

    return (
        <aside>
            <div className="bg-white p-4 sm:rounded-xl sm:border border-gray-200">
                <div className="w-fit mx-auto">
                    <div
                        className="w-32 h-32 rounded-full overflow-hidden mx-auto bg-slate-100 border border-slate-200">
                        {user?.avatar &&
                            <img
                                src={user?.avatar}
                                alt={`${user?.first_name} ${user?.last_name}`}
                                className='w-full h-full rounded-full'
                            />}
                    </div>
                    <div>
                        <h1 className='mx-auto mt-2 text-center font-bold text-lg'>
                            {`${user?.first_name} ${user?.last_name}`}
                        </h1>
                        <h3 className='mx-auto mb-2 text-center text-sm'>
                            {`@${user?.username}`}
                        </h3>
                    </div>
                </div>
                <div className='mt-4 pt-4 border-t border-gray-200 overflow-hidden max-lg:hidden'>
                    <h3 className='mb-2 font-bold'>Informations</h3>
                    <table className='w-full'>
                        <tbody>
                            <tr className="[&>*]:py-1 [&>*]:text-sm [&>*]:text-gray-700 [&>*]:whitespace-nowrap cursor-context-menu">
                                <td className='font-bold'>Wallet :</td>
                                <td className=''>{user.wallet}</td>
                            </tr>
                            <tr className="[&>*]:py-1 [&>*]:text-sm [&>*]:text-gray-700 [&>*]:whitespace-nowrap cursor-context-menu">
                                <td className='font-bold'>Full Name</td>
                                <td className=''>{`${user?.first_name} ${user?.last_name}`}</td>
                            </tr>
                            <tr className="[&>*]:py-1 [&>*]:text-sm [&>*]:text-gray-700 [&>*]:whitespace-nowrap cursor-context-menu">
                                <td className='font-bold'>Email :</td>
                                <td className=''>{user?.email}</td>
                            </tr>
                            <tr className="[&>*]:py-1 [&>*]:text-sm [&>*]:text-gray-700 [&>*]:whitespace-nowrap cursor-context-menu">
                                <td className='font-bold'>Phone :</td>
                                <td className=''>{`${user?.country_code ?? ''} ${user?.phone ?? ''}`}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </aside>
    )
}

export default AsideBar;
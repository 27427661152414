import React, { useState } from 'react';
import Layout from 'components/Layout/index';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from 'hooks/useSWR';
import PageLoader from 'components/shared/Loaders/PageLoader';
import AsideBar from 'components/Pages/Practice/AsideBar';
import Content from 'components/Pages/Practice/Content';
import { isEmpty } from 'helpers/utils';

const Practice = () => {

    const fetcher = useFetch()

    const { id } = useParams()

    const navigate = useNavigate()

    const { data:practiceData, isLoading } = fetcher.getPracticeById(id);

    return (
        <Layout>
            {isLoading ? <PageLoader /> : (
                !isEmpty(practiceData) && (
                    <div className="sm:p-4">
                        <div className='max-w-[1400px] mx-auto'>
                            <div className="grid grid-cols-[300px_1fr] gap-4 mb-10 max-lg:grid-cols-1 max-sm:gap-0">
                                {/* Aside */}
                                <AsideBar
                                    practiceData={practiceData?.data}
                                />

                                <Content
                                    practiceData={practiceData?.data}
                                />
                            </div>
                        </div>
                    </div>
                ) || (
                    <div className="p-4 w-full h-52 flex flex-col items-center justify-center">
                        <p><strong>Error:</strong> Practice Not Found!</p>
                        <button
                            onClick={() => navigate(-1)}
                            className='mt-8 underline hover:no-underline'>
                            Return Back
                        </button>
                    </div>
                )
            )}
        </Layout>
    )
}
export default Practice;
import { configureStore } from '@reduxjs/toolkit'
import iconReducer from './Reducers/icon-slice'
import UserReducer from './Reducers/UserReducer'
import LayoutReducer from './Reducers/LayoutReducer'

const store = configureStore({
    reducer: {
        iconReducer: iconReducer.reducer,
        UserReducer: UserReducer.reducer,
        LayoutReducer: LayoutReducer.reducer
    },
    devTools: process.env.NODE_ENV !== "production",
})

export default store
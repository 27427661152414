import { useAuth } from "hooks/useAuth";
import axios from "lib/axios";
import { useState } from "react";
import ConfirmationModal from "components/Alerts/ConfirmationModal";
import BtnLoader from "components/Loaders/BtnLoader";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import { isEmpty } from "helpers/utils";
import useAlert from "components/Alerts/useAlert";
import { CgClose } from "react-icons/cg";

export default function ContactRequestsList({ data, refresh }) {
    const { renderAlertMessages, showMessage } = useAlert();

    const { csrf } = useAuth()

    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false)
    const [messageIdToDelete, setMessageIdToDelete] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const deleteMessage = async () => {
        alert('Next...');
        return;
        try {

            const id = messageIdToDelete;

            setIsLoading(true)

            await csrf()

            const { data } = await axios.delete(`/api/v1/forAdmin/deleteContactRequestById/${id}`)

            if (!data.error) {
                await refresh();
                showMessage(data.message, 'success');
                setMessageIdToDelete('')
            }

            setIsLoading(false)
        } catch (error) {
            showMessage('something was wrong', 'error');
            setIsLoading(false)
        }
    }

    return (
        <>
            {renderAlertMessages}
            <section>
                <div className="overflow-hidden bg-white">
                    <div className="max-w-full overflow-auto min-h-[100px]">
                        <table className='w-full'>
                            <thead>
                                <tr className='[&>*]:px-4 [&>*]:py-2 [&>*]:text-black [&>*]:whitespace-nowrap [&>*]:text-start'>
                                    <th className=''>Id</th>
                                    <th className=''>Full name</th>
                                    <th className=''>Email Address</th>
                                    <th className=''>Message</th>
                                    <th className=''></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isEmpty(data) && (
                                    data.map((c) => {

                                        const inProgressToDelete = isLoading && messageIdToDelete == c.id;

                                        return (
                                            <tr key={c.id} className='hover:bg-bgPrimary border-t border-gray-200 [&>*]:py-6 [&>*]:px-4 [&>*]:text-gray-700 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-pointer transition-colors duration-200'>
                                                <td className='font-bold'>{c.id}</td>
                                                <td className=''>{c.fullname}</td>
                                                <td className=''>{c.email}</td>
                                                <td className=''>{c.message}</td>
                                                <td className="">
                                                    <div className="flex items-center justify-center w-fit float-right">
                                                        {/* <button
                                                            className="text-green-500 hover:text-white transition-colors duration-200 bg-transparent hover:bg-green-500 focus:outline-none p-2 rounded-md">
                                                            <TbEdit className="w-5 h-5" />
                                                        </button> */}
                                                        <button
                                                            disabled={inProgressToDelete}
                                                            onClick={() => {
                                                                setMessageIdToDelete(c.id)
                                                                setConfirmationModalIsOpen(true)
                                                            }}
                                                            className="text-red-500 hover:text-white transition-colors duration-200 bg-transparent hover:bg-red-500 focus:outline-none p-2 rounded-md">
                                                            {!inProgressToDelete
                                                                ? <CgClose className="w-5 h-5" />
                                                                : <BtnLoader withText={false} />}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>

            {/* Delete Care */}
            <ConfirmationModal
                isOpen={confirmationModalIsOpen}
                title="Delete Speciality"
                message="Are you sure you want to delete spiciality?"
                buttonName="Supprimer"
                onConfirm={() => {
                    setConfirmationModalIsOpen(false);
                    deleteMessage()
                }}
                onCancel={() => {
                    setConfirmationModalIsOpen(false);
                }}
            />
        </>
    )
}
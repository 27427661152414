import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout/index';
import PageLoader from 'components/shared/Loaders/PageLoader';
import ReviewsList from 'components/Pages/Reviews/ReviewsList';
import { useNavigate } from 'react-router-dom';
import { useFetch } from 'hooks/useSWR';
import Pagination from 'components/Pagination';
import FilterReviews from 'components/Pages/Reviews/FilterReviews';
import { LuSettings2 } from 'react-icons/lu';


const Reviews = () => {

    const navigate = useNavigate()

    // Get Url Parameters
    const searchParams = new URLSearchParams(document.location.search)
    const getPageNumber = searchParams.get('page') ?? 1;
    const [pageNumber, setPageNumber] = useState(getPageNumber)
    const [starsNumber, setStarsNumber] = useState('0')
    const [filterByStars, setFilterByStars] = useState('0') // [0,1,2,3,4,5], 0 equal all stars


    // Get Cares Data Using SWR package
    const fetcher = useFetch()

    const { data: reviewsData, isLoading, mutate } = fetcher.getAllReviews(pageNumber, starsNumber);
    const refresh = () => mutate()

    const [reviews, setReviews] = useState([])

    useEffect(() => {
        setReviews(reviewsData?.data?.data)
    }, [reviewsData])

    // Change Page
    const navigateToAnotherPage = (n) => {
        setPageNumber(n)
        navigate("/reviews?page=" + n)
    }

    const getReviewsFilteredByStars = () => {
        setStarsNumber(filterByStars)
        mutate()
    }

    return (
        <Layout>
            <div className="px-4 py-10">
                <div className='max-w-[1200px] mx-auto'>
                    {/* Header */}
                    <div className="flex items-center justify-between mb-6">
                        <div className="">
                            <h3 className='font-bold text-2xl flex items-center'>
                                Reviews List
                                <span className='bg-bgPrimary border px-2 py-1 ml-4 rounded-3xl text-sm font-semibold'>
                                    {reviewsData?.data?.total ?? 0}
                                </span>
                            </h3>
                            <p className='mt-1'>View, Delete All Reviews.</p>
                        </div>
                    </div>

                    <div className="flex items-center justify-between mb-6 bg-bgSecondary p-4 rounded-md flex-row-reverse">
                        <FilterReviews
                            filterByStars={filterByStars}
                            setFilterByStars={setFilterByStars}
                            starsNumber={starsNumber}
                            refresh={getReviewsFilteredByStars}
                            button={
                                <button className='relative flex items-center gap-2 border border-gray-200 hover:border-gray-300 bg-white hover:bg-gray-100 text-black font-bold transition-colors duration-200 outline-none py-1.5 px-4 rounded-3xl'>
                                    Filters
                                    <LuSettings2 size={20} className="max-lg:w-3" />
                                    {starsNumber != '0' && <span className='absolute top-0 left-0 w-2.5 h-2.5 bg-green-500 rounded-full border border-gray-200'></span>}
                                </button>
                            }
                        />
                    </div>
                    
                    {isLoading ? <PageLoader /> : (
                        <div className=''>
                            <ReviewsList
                                reviewsData={reviews}
                                refresh={refresh}
                            />

                            {/* Pagination */}
                            <Pagination
                                currentPageNumber={reviewsData?.data?.current_page}
                                lastPageNumber={reviewsData?.data?.last_page}
                                changePageNumber={navigateToAnotherPage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    )
}
export default Reviews;
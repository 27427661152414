
const Loader = () => {

    const bgStyle = {
        position: "fixed",
        left: "50%",
        bottom: "1rem",
        transform: "translateX(-50%)",
        background: "rgb(41 41 41)",
        padding: "10px 1rem",
        borderRadius: "5px",
        boxShadow: "0px 5px 5px 0px rgb(0 0 0 / 16%)",
        cursor: "context-menu",
        display: "flex",
        alignItems: "center",
        zIndex: "100",
    };

    const loaderStyle = {
        border: '3px solid #fff',
        borderRadius: '50%',
        borderTop: '3px solid rgba(37, 99, 235, 0.8)',
        width: '20px',
        height: '20px',
        animation: 'loaderSpin 0.6s linear infinite',
        WebkitAnimation: 'loaderSpin 0.4s linear infinite', // For Safari
    };

    const textStyle = {
        color: "#fff",
        marginLeft: ".5rem"
    }
    
    return (
        <>
            <div style={bgStyle} className="swipeUpAnimation">
                <div  style={loaderStyle}></div>
                <span style={textStyle}>Loading...</span>
            </div>
        </>
    )
}

export default Loader
import { IoMdClose } from "react-icons/io";
import { HiCheckCircle } from "react-icons/hi2";
import BoxModal from "components/Layout/BoxModal";
import { useState } from "react";

export default function FilterReviews({ button, filterByStars, setFilterByStars, starsNumber, refresh }) {
    const [isOpen, setIsOpen] = useState(false);
    const closeModal = () => setIsOpen(false);
    const openModal = () => setIsOpen(true);

    const options = [
        { number: 0, name: "All" },
        { number: 1, name: "1 Stars" },
        { number: 2, name: "2 Stars" },
        { number: 3, name: "3 Stars" },
        { number: 4, name: "4 Stars" },
        { number: 5, name: "5 Stars" },
    ];

    return (
        <>
            <div onClick={openModal} className="w-fit">
                {button}
            </div>
            <BoxModal
                isOpen={isOpen}
                onClose={closeModal}
                content={
                    <>
                        <div className="flex items-center justify-between p-4 border-b border-gray-300 bg-white">
                            <h2 className="text-lg font-extrabold text-black">Filter Reviews</h2>
                            <button
                                type="button"
                                className="justify-center outline-none p-1 bg-gray-50 hover:bg-gray-100 rounded-md"
                                onClick={closeModal}>
                                <IoMdClose className="w-6 h-6" />
                            </button>
                        </div>

                        <div className="px-4 py-6 bg-white max-h-[60vh] max-lg:max-h-[80vh] overflow-y-auto">
                            <div>
                                <label className=" text-md font-bold max-lg:text-sm">Note</label>
                                <div className="mt-2 grid grid-cols-3 gap-4 lg:gap-x-5">
                                    {options?.map(option => {
                                        const number = option.number;
                                        const name = option.name;
                                        const isSelected = filterByStars == number;

                                        return (
                                            <button
                                                key={number}
                                                type="button"
                                                className={`border-2 border-gray-100 bg-white max-lg:text-sm ${isSelected
                                                        ? "border-secondary bg-secondary bg-opacity-10"
                                                        : ""
                                                    }
                                                                    text-md relative h-full w-full rounded-md px-6 py-4 text-center font-bold text-black hover:bg-gray-200 hover:bg-opacity-30`}
                                                onClick={() => setFilterByStars(number)}
                                            >
                                                {name}
                                                {isSelected && (
                                                    <div className="absolute -right-4 -top-4 lg:-right-3 lg:-top-3">
                                                        <HiCheckCircle color="#159AA9" size={25} />
                                                    </div>
                                                )}
                                            </button>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        <div
                            className={`
                                bg-white border-t border-gray-300 p-4
                                max-lg:absolute max-lg:w-screen max-lg:grid max-lg:grid-cols-2 max-lg:gap-4 max-lg:right-0 max-lg:bottom-0
                                lg:flex lg:items-center lg:flex-row-reverse
                            `}>
                            <button onClick={() => { setIsOpen(false); refresh(); }} type="button" className="py-2 px-6 font-semibold text-white bg-buttonBg-1 rounded-md text-md hover:bg-buttonBg-2 border border-buttonBg-1 lg:ml-4">
                                Save
                            </button>
                            <button onClick={() => { setFilterByStars(starsNumber); setIsOpen(false); }}
                                type="button" className="py-2 px-6 font-semibold text-black bg-white border border-gray-300 rounded-md text-md hover:bg-gray-100">
                                Annuler
                            </button>
                        </div>
                    </>
                }
            />
        </>
    );
}

import dayjs from "../../../lib/dayjs";


const Details = ({ userData }) => {

    return (
        <div className='bg-white sm:rounded-xl sm:border max-sm:border-t border-gray-200 mt-4 overflow-hidden'>
            <div className="flex items-center justify-between p-4">
                <h3 className='font-bold text-xl flex items-center'>
                    Details
                </h3>
            </div>
            <div className="max-w-full overflow-auto">
                <table className='w-full'>
                    <tbody className="[&>*]:border-t [&>*]:border-gray-200">
                        <tr className='[&>*]:py-4 [&>*]:px-4 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-context-menu'>
                            <td className="text-black font-bold">Wallet</td>
                            <td className="text-gray-700">{userData.wallet}</td>
                        </tr>
                        <tr className='[&>*]:py-4 [&>*]:px-4 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-context-menu'>
                            <td className="text-black font-bold">Full-Name</td>
                            <td className="text-gray-700">{`${userData.first_name} ${userData.last_name} (@${userData.username})`}</td>
                        </tr>
                        <tr className='[&>*]:py-4 [&>*]:px-4 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-context-menu'>
                            <td className="text-black font-bold">Email</td>
                            <td className="text-gray-700">{userData.email}</td>
                        </tr>
                        <tr className='[&>*]:py-4 [&>*]:px-4 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-context-menu'>
                            <td className="text-black font-bold">Phone</td>
                            <td className="text-gray-700">{`${userData.country_code} ${userData.phone}`}</td>
                        </tr>
                        <tr className="[&>*]:py-4 [&>*]:px-4 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-context-menu">
                            <td className='text-black font-bold'>BirthDate</td>
                            <td className='text-gray-700'>{userData.birth_date}</td>
                        </tr>
                        <tr className="[&>*]:py-4 [&>*]:px-4 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-context-menu">
                            <td className='text-black font-bold'>Gender</td>
                            <td className='text-gray-700'>{userData.gender}</td>
                        </tr>
                        <tr className='[&>*]:py-4 [&>*]:px-4 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-context-menu'>
                            <td className="text-black font-bold">Registered Date</td>
                            <td className="text-gray-700">{dayjs(userData.created_at).format("D MMMM, YYYY")}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default Details;
import { createSlice } from '@reduxjs/toolkit'

const UserReducer = createSlice({
    name: "UserReducer",
    initialState: {
        isLoggedIn: false,
    },
    reducers: {
        setLoggedIn: (state, { payload }) => {
            console.log("payload", payload);
            state.isLoggedIn = payload
        },

    }
})

export default UserReducer

export const UserReducerAction = UserReducer.actions

import React from 'react';
import { RiHomeLine } from 'react-icons/ri';
import { TbUsersGroup } from 'react-icons/tb';
import { MdOutlineMedicalServices, MdOutlineReviews } from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { MdOutlineSettings } from 'react-icons/md';
import { IconContext } from 'react-icons';
import classNames from 'helpers/classNames';
import { Link } from 'react-router-dom';
import { PiStorefrontDuotone } from 'react-icons/pi';
import { FaRegRectangleList, FaUsersViewfinder } from "react-icons/fa6";


const SideBar = ({ openSidebar, roles }) => {

    const {
        ViewOverviewPage,
        ViewAppointmentsList,
        ViewCaresList,
        ViewPracticesList,
        ViewProfessionalsList,
        ViewReviewsList,
        ViewPatientsList,
        ViewAdminsList,
        ViewAccountPage,
    } = roles;

    const activeLink = (p) => {
        const path = window.location.pathname;

        if (p == path) return 'bg-[#ffffff26]';

        return 'hover:bg-[#ffffff26]';
    }

    return (
        <aside
            id="sidebar"
            className={classNames(openSidebar ? "-translate-x-full" : "translate-x-0 border-gray-200 border-r ", "fixed flex flex-col top-0 left-0 z-40 w-fit h-full overflow-y-auto duration-75 pt-[70px] transition-transform bg-primary sm:translate-x-0")}
            aria-label="Sidebar">
            <div className="w-[70px] h-full">
                <div className="relative flex flex-col flex-1 min-h-0 pt-0 bg-primary h-full">
                    <div className="flex flex-col flex-1 overflow-y-auto h-full scrollbar-primary">
                        <div className="flex flex-col justify-between bg-primary h-full">
                            <ul>
                                {ViewOverviewPage &&
                                <li title='Reports'>
                                    <Link to="/">
                                        <div className={`flex items-center justify-center cursor-pointer text-white h-[70px] ${activeLink('/')}`}>
                                            <IconContext.Provider value={{ className: 'w-8 h-8' }}>
                                                <RiHomeLine />
                                            </IconContext.Provider>
                                        </div>
                                    </Link>
                                </li>}

                                {ViewAppointmentsList &&
                                <li title='Appointments'>
                                    <Link to="/appointments">
                                        <div className={`flex items-center justify-center cursor-pointer text-white h-[70px] ${activeLink('/appointments')}`}>
                                            <IconContext.Provider value={{ className: 'w-8 h-8 ' }}>
                                                <FaRegRectangleList />
                                            </IconContext.Provider>
                                        </div>
                                    </Link>
                                </li>}

                                {ViewCaresList &&
                                <li title='Cares'>
                                    <Link to="/cares">
                                        <div className={`flex items-center justify-center cursor-pointer text-white h-[70px] ${activeLink('/cares')}`}>
                                            <IconContext.Provider value={{ className: 'w-8 h-8' }}>
                                                <MdOutlineMedicalServices />
                                            </IconContext.Provider>
                                        </div>
                                    </Link>
                                </li>}

                                {ViewPracticesList &&
                                <li title='Practices'>
                                    <Link to="/practices">
                                        <div className={`flex items-center justify-center cursor-pointer text-white h-[70px] ${activeLink('/practices')}`}>
                                            <IconContext.Provider value={{ className: 'w-8 h-8' }}>
                                                <PiStorefrontDuotone />
                                            </IconContext.Provider>
                                        </div>
                                    </Link>
                                </li>}

                                {ViewProfessionalsList &&
                                <li title='Professionals'>
                                    <Link to="/professionals">
                                        <div className={`flex items-center justify-center cursor-pointer text-white h-[70px] ${activeLink('/professionals')}`}>
                                            <IconContext.Provider value={{ className: 'w-8 h-8' }}>
                                                <FaUsersViewfinder />
                                            </IconContext.Provider>
                                        </div>
                                    </Link>
                                </li>}

                                {ViewReviewsList &&
                                <li title='Reviews'>
                                    <Link to="/reviews">
                                        <div className={`flex items-center justify-center cursor-pointer text-white h-[70px] ${activeLink('/reviews')}`}>
                                            <IconContext.Provider value={{ className: 'w-8 h-8' }}>
                                                <MdOutlineReviews />
                                            </IconContext.Provider>
                                        </div>
                                    </Link>
                                </li>}

                                {ViewPatientsList &&
                                <li title='Patients'>
                                    <Link to="/patients">
                                        <div className={`flex items-center justify-center cursor-pointer text-white h-[70px] ${activeLink('/patients')}`}>
                                            <IconContext.Provider value={{ className: 'w-8 h-8' }}>
                                                <TbUsersGroup />
                                            </IconContext.Provider>
                                        </div>
                                    </Link>
                                </li>}

                                {ViewAdminsList &&
                                <li title='Admins'>
                                    <Link to="/admins">
                                        <div className={`flex items-center justify-center cursor-pointer text-white h-[70px] ${activeLink('/admins')}`}>
                                            <IconContext.Provider value={{ className: 'w-8 h-8' }}>
                                                <MdOutlineAdminPanelSettings />
                                            </IconContext.Provider>
                                        </div>
                                    </Link>
                                </li>}

                                <li title='Settings'>
                                    <Link to="/settings">
                                        <div className={`flex items-center justify-center cursor-pointer text-white h-[70px] ${activeLink('/settings')}`}>
                                            <IconContext.Provider value={{ className: 'w-8 h-8 ' }}>
                                                <MdOutlineSettings />
                                            </IconContext.Provider>
                                        </div>
                                    </Link>
                                </li>
                            </ul>

                            <ul>
                                {ViewAccountPage &&
                                <li title='Account'>
                                    <Link to="/account">
                                        <div className={`flex items-center justify-center cursor-pointer text-white h-[70px] ${activeLink('/account')}`}>
                                            <IconContext.Provider value={{ className: 'w-8 h-8' }}>
                                                <CgProfile />
                                            </IconContext.Provider>
                                        </div>
                                    </Link>
                                </li>}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </aside>

    );
};



export default SideBar;

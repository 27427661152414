import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout/index';
import { GrClose } from 'react-icons/gr';
import { IconContext } from 'react-icons';
import CategoriesRequests from "lib/Pages/Requests/Categories"
import Loader from 'components/shared/Loaders/index';

const Categories = () => {

  const { Get, Delete, Update, Create, isError, isLoading } = CategoriesRequests()

  const [showForm, setShowForm] = useState(false)
  const [categories, setCategories] = useState([])
  
  const [newCategoryArName, setNewCategoryArName] = useState('')
  const [newCategoryEnName, setNewCategoryEnName] = useState('')
  const [newCategoryFrName, setNewCategoryFrName] = useState('')
  const [newCategoryDetails, setNewCategoryDetails] = useState('')
  const [newCategoryDescription, setNewCategoryDescription] = useState('')
  const [newCategoryImgWeb, setNewCategoryImgWeb] = useState('')
  const [newCategoryImgMobile, setNewCategoryImgMobile] = useState('')

  const [editThisCategory, setEditThisCategory] = useState()
  const [editCategoryArName, setEditCategoryArName] = useState('')
  const [editCategoryEnName, setEditCategoryEnName] = useState('')
  const [editCategoryFrName, setEditCategoryFrName] = useState('')
  const [editCategoryDetails, setEditCategoryDetails] = useState('')
  const [editCategoryDescription, setEditCategoryDescription] = useState('')
  const [editCategoryImgWeb, setEditCategoryImgWeb] = useState('')
  const [editCategoryImgMobile, setEditCategoryImgMobile] = useState('')

  useEffect(() => {
    Get({ setCategories })
  },[])


  const DeleteCategory = async (id) => {
    await Delete({ id })

    // check errors
    if (isError) {
      alert('Error!')
    }

    // remove from array
    const updateCategories = categories.filter(category => category.id !== id);
    setCategories(updateCategories);
  }


  const SaveNewCategory = async () => {
    const Data = {
      ar_name: newCategoryArName,
      en_name: newCategoryEnName,
      fr_name: newCategoryFrName,
      details: newCategoryDetails,
      description: newCategoryDescription,
      image_web: newCategoryImgWeb,
      image_mobile: newCategoryImgMobile,
    };
    
    await Create({ Data, categories, setCategories })

    // check errors
    if (isError) {
      alert('Error!')
    }

    setShowForm(false)
    setNewCategoryArName('')
    setNewCategoryEnName('')
    setNewCategoryFrName('')
    setNewCategoryDetails('')
    setNewCategoryDescription('')
    setNewCategoryImgWeb('')
    setNewCategoryImgMobile('')
  }


  const EditCategory = async () => {
    const Data = {
      id: editThisCategory.id,
      ar_name: editCategoryArName,
      en_name: editCategoryEnName,
      fr_name: editCategoryFrName,
      details: editCategoryDetails,
      description: editCategoryDescription,
      image_web: editCategoryImgWeb,
      image_mobile: editCategoryImgMobile,
    }

    await Update({Data})
    

    // check errors
    if (isError) {
      alert('Error!')
    }


    setEditThisCategory()


    // get and update values
    const updatedCategories = categories.map(category => {
      if (category.id === Data.id) {
        const updatedCategory = {
          ...category,
          ar_name: Data.ar_name,
          en_name: Data.en_name,
          fr_name: Data.fr_name,
          details: Data.details,
          description: Data.description
        };

        if (Data.image_web) {
          updatedCategory.image_web = Data.image_web;
        }

        if (Data.image_mobile) {
          updatedCategory.image_mobile = Data.image_mobile;
        }

        return updatedCategory;
      }
      return category;
    });


    setCategories(updatedCategories);
  }

  return (
    <Layout>
      <div className="p-4">
        <div className='max-w-[1100px] mx-auto'>

          {isLoading && <Loader />}

          {/* Page Header */}
          <div className='mt-4 mb-2 flex items-center justify-between'>
            <h2 className='text-xl font-extrabold'>Categories</h2>

            <button onClick={() => setShowForm(true)} className='px-4 py-3 bg-blue-600 hover:bg-blue-700 text-blue-50 rounded text-sm font-medium'>
              Add New
            </button>
          </div>

          {/* Add New */}
          {showForm &&
            <div className='fixed w-full h-full z-[90] left-0 top-0' style={{ 'background': 'rgb(0 0 0 / 48%)' }}>
              <div className='px-4 w-full max-w-[700px] min-h-[300px] max-h-[80vh] overflow-y-auto absolute -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4'>
                <div className='zoominAnimation bg-[white] rounded-[5px]'>
                  {/* Header */}
                  <div className='flex items-center justify-between p-4 border-b'>
                    <h4>Add New Category</h4>
                    <button
                      onClick={() => setShowForm(false)}
                      className='hover:bg-gray-100 p-1 rounded text-sm font-medium text-gray-700'>
                      <IconContext.Provider value={{ className: 'w-5 h-5' }}>
                        <GrClose />
                      </IconContext.Provider>
                    </button>
                  </div>
                  {/* Body */}
                  <div className='p-4'>

                    <div className='grid grid-cols-3 gap-4'>
                      <label className='flex flex-col mb-4'>
                        <span className='mb-1 text-sm font-medium text-gray-700'>Arabic name</span>
                        <input
                          onChange={(e) => setNewCategoryArName(e.target.value)}
                          value={newCategoryArName}
                          className='outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                          type="text"
                        />
                      </label>
                      <label className='flex flex-col mb-4'>
                        <span className='mb-1 text-sm font-medium text-gray-700'>English name</span>
                        <input
                          onChange={(e) => setNewCategoryEnName(e.target.value)}
                          value={newCategoryEnName}
                          className='outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                          type="text"
                        />
                      </label>
                      <label className='flex flex-col mb-4'>
                        <span className='mb-1 text-sm font-medium text-gray-700'>French name</span>
                        <input
                          onChange={(e) => setNewCategoryFrName(e.target.value)}
                          value={newCategoryFrName}
                          className='outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                          type="text"
                        />
                      </label>
                    </div>

                    <label className='flex flex-col mb-4'>
                      <span className='mb-1 text-sm font-medium text-gray-700'>Details</span>
                      <input
                        onChange={(e) => setNewCategoryDetails(e.target.value)}
                        value={newCategoryDetails}
                        className='outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                        type="text"
                      />
                    </label>

                    <label className='flex flex-col mb-4'>
                      <span className='mb-1 text-sm font-medium text-gray-700'>Short Description</span>
                      <textarea
                        onChange={(e) => setNewCategoryDescription(e.target.value)}
                        value={newCategoryDescription}
                        className='outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                        type="text" ></textarea>
                    </label>

                    <div className='grid grid-cols-2 gap-4'>
                      <label className='flex flex-col mb-4'>
                        <span className='mb-1 text-sm font-medium text-gray-700'>Image Web</span>
                        <input
                          onChange={(e) => {
                            const data = new FileReader();
                            data.addEventListener('load', () => {
                              setNewCategoryImgWeb(data.result)
                            })
                            data.readAsDataURL(e.target.files[0])
                          }}
                          className='cursor-pointer outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                          type="file"
                          accept="image/png, image/jpg, image/jpeg"
                        />
                      </label>
                      <label className='flex flex-col mb-4'>
                        <span className='mb-1 text-sm font-medium text-gray-700'>Image Mobile</span>
                        <input
                          onChange={(e) => {
                            const data = new FileReader();
                            data.addEventListener('load', () => {
                              setNewCategoryImgMobile(data.result)
                            })
                            data.readAsDataURL(e.target.files[0])
                          }}
                          className='cursor-pointer outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                          type="file"
                          accept="image/png, image/jpg, image/jpeg"
                        />
                      </label>
                    </div>

                    <button
                      onClick={() => SaveNewCategory()}
                      disabled={isLoading}
                      className='mt-5 px-6 py-3 bg-blue-600 hover:bg-blue-700 text-blue-50 rounded text-sm font-medium'>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>}

          {/* Add New */}
          {editThisCategory &&
            <div className='fixed w-full h-full z-[90] left-0 top-0' style={{ 'background': 'rgb(0 0 0 / 48%)' }}>
              <div className='px-4 w-full max-w-[700px] min-h-[300px] max-h-[80vh] overflow-y-auto absolute -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4'>
                <div className='zoominAnimation bg-[white] rounded-[5px]'>
                  {/* Header */}
                  <div className='flex items-center justify-between p-4 border-b'>
                    <h4>Add New Category</h4>
                    <button
                      onClick={() => setEditThisCategory(false)}
                      className='hover:bg-gray-100 p-1 rounded text-sm font-medium text-gray-700'>
                      <IconContext.Provider value={{ className: 'w-5 h-5' }}>
                        <GrClose />
                      </IconContext.Provider>
                    </button>
                  </div>
                  {/* Body */}
                  <div className='p-4'>

                    <div className='grid grid-cols-3 gap-4'>
                      <label className='flex flex-col mb-4'>
                        <span className='mb-1 text-sm font-medium text-gray-700'>Arabic name</span>
                        <input
                          onChange={(e) => setEditCategoryArName(e.target.value)}
                          value={editCategoryArName}
                          className='outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                          type="text"
                        />
                      </label>
                      <label className='flex flex-col mb-4'>
                        <span className='mb-1 text-sm font-medium text-gray-700'>English name</span>
                        <input
                          onChange={(e) => setEditCategoryEnName(e.target.value)}
                          value={editCategoryEnName}
                          className='outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                          type="text"
                        />
                      </label>
                      <label className='flex flex-col mb-4'>
                        <span className='mb-1 text-sm font-medium text-gray-700'>French name</span>
                        <input
                          onChange={(e) => setEditCategoryFrName(e.target.value)}
                          value={editCategoryFrName}
                          className='outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                          type="text"
                        />
                      </label>
                    </div>

                    <label className='flex flex-col mb-4'>
                      <span className='mb-1 text-sm font-medium text-gray-700'>Details</span>
                      <input
                        onChange={(e) => setEditCategoryDetails(e.target.value)}
                        value={editCategoryDetails}
                        className='outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                        type="text"
                      />
                    </label>

                    <label className='flex flex-col mb-4'>
                      <span className='mb-1 text-sm font-medium text-gray-700'>Short Description</span>
                      <textarea
                        onChange={(e) => setEditCategoryDescription(e.target.value)}
                        value={editCategoryDescription}
                        className='outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                        type="text" ></textarea>
                    </label>

                    <div className='grid grid-cols-2 gap-4'>
                      <label className='flex flex-col mb-4'>
                        <span className='mb-1 text-sm font-medium text-gray-700'>Image Web</span>
                        <input
                          onChange={(e) => {
                            const data = new FileReader();
                            data.addEventListener('load', () => {
                              setEditCategoryImgWeb(data.result)
                            })
                            data.readAsDataURL(e.target.files[0])
                          }}
                          className='cursor-pointer outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                          type="file"
                          accept="image/png, image/jpg, image/jpeg"
                        />
                      </label>
                      <label className='flex flex-col mb-4'>
                        <span className='mb-1 text-sm font-medium text-gray-700'>Image Mobile</span>
                        <input
                          onChange={(e) => {
                            const data = new FileReader();
                            data.addEventListener('load', () => {
                              setEditCategoryImgMobile(data.result)
                            })
                            data.readAsDataURL(e.target.files[0])
                          }}
                          className='cursor-pointer outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                          type="file"
                          accept="image/png, image/jpg, image/jpeg"
                        />
                      </label>
                    </div>

                    <button
                      onClick={() => EditCategory()}
                      disabled={isLoading}
                      className='mt-5 px-6 py-3 bg-blue-600 hover:bg-blue-700 text-blue-50 rounded text-sm font-medium'>
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </div>}

          {/* Content */}
          <section className="flex flex-col mt-6 mb-10">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden border border-gray-100 rounded">
                  <table className="min-w-full">
                    <thead>
                      <tr className="bg-gray-50 border-b border-gray-100">
                        <th scope="col" className="px-4 py-4 text-left">
                          Arabic name
                        </th>
                        <th scope="col" className="px-4 py-4 text-left">
                          English name
                        </th>
                        <th scope="col" className="px-4 py-4 text-left">
                          Frensh name
                        </th>
                        <th scope="col" className="px-4 py-4 text-left">
                          Details
                        </th>
                        <th scope="col" className="px-4 py-4 text-left">
                          Description
                        </th>
                        <th scope="col" className="px-4 py-4 text-left">
                          Image Web
                        </th>
                        <th scope="col" className="px-4 py-4 text-left">
                          Image Mobile
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {categories.length > 0 && (
                        categories.map((category) => (
                          <tr key={category.id} className="hover:bg-gray-50 border-b border-gray-100">
                            <td className="px-4 py-2 text-sm whitespace-nowrap">
                              {category.ar_name}
                            </td>
                            <td className="px-4 py-2 text-sm whitespace-nowrap">
                              {category.en_name}
                            </td>
                            <td className="px-4 py-2 text-sm whitespace-nowrap">
                              {category.fr_name}
                            </td>
                            <td className="px-4 py-2 text-sm whitespace-nowrap">
                              {category.details}
                            </td>
                            <td className="px-4 py-2 text-sm whitespace-nowrap">
                              {category.description}
                            </td>
                            <td className="px-4 py-2 text-sm whitespace-nowrap">
                              {category.image_web &&
                              <img
                                width={50}
                                height={50}
                                src={
                                  category.image_web.startsWith("data:image")
                                  && `${category.image_web}`
                                  || `http://localhost:8000${category.image_web}`
                                }
                              />}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap">
                              {category.image_mobile &&
                              <img
                                width={50}
                                height={50}
                                src={
                                  category.image_mobile.startsWith("data:image")
                                  && `${category.image_mobile}`
                                  || `http://localhost:8000${category.image_mobile}`
                                }
                              />}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap float-right">
                              <div className="flex items-center gap-x-6">
                                <button
                                  onClick={() => {
                                      if (window.confirm('Are you sure you wish to delete this category?')) {
                                        DeleteCategory(category.id)
                                      }
                                  }}
                                  className="text-gray-500 transition-colors duration-200 dark:hover:text-red-500 dark:text-gray-300 hover:text-red-500 focus:outline-none">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                  </svg>
                                </button>
                                <button
                                  onClick={() => {
                                    setEditThisCategory(category)
                                    
                                    setEditCategoryArName(category.ar_name)
                                    setEditCategoryEnName(category.en_name)
                                    setEditCategoryFrName(category.fr_name)
                                    setEditCategoryDetails(category.details)
                                    setEditCategoryDescription(category.description)
                                  }}
                                  className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                  </svg>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div>
    </Layout>
  )
}
export default Categories;
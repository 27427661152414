import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout/index';
import axios from 'lib/axios';
import { useAuth } from 'hooks/useAuth';
import useAlert from 'components/Alerts/useAlert';
import { useFetch } from 'hooks/useSWR';
import PageLoader from 'components/shared/Loaders/PageLoader';
import BtnLoader from 'components/Loaders/BtnLoader';

const Account = () => {
    const { renderAlertMessages, showMessage } = useAlert();

    const { csrf } = useAuth();

    // Get Cares Data Using SWR package
    const fetcher = useFetch()
    const { data: accountInfo, isLoading, mutate } = fetcher.getUserInformations();
    const refresh = () => mutate()


    const [inProgress, setInProgress] = useState(false)
    const [updateAvatarInProgress, setUpdateAvatarInProgress] = useState(false)
    const [errors, setErrors] = useState([])

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [gender, setGender] = useState('')
    const [avatar, setAvatar] = useState('')
    const [birthdateD, setBirthdateD] = useState('')
    const [birthdateM, setBirthdateM] = useState('')
    const [birthdateY, setBirthdateY] = useState('')




    useEffect(() => {
        const i = accountInfo ?? {};
        setFirstname(i?.first_name)
        setLastname(i?.last_name)
        setEmail(i?.email)
        setGender(i?.gender ?? 'male')
        setAvatar(i?.avatar)
        setBirthdateD(i?.birth_date?.split('-')[2])
        setBirthdateM(i?.birth_date?.split('-')[1])
        setBirthdateY(i?.birth_date?.split('-')[0])
    }, [accountInfo]);


    const handleChangeBirthdateY = async (e) => {
        const nu = e.target.value;

        const re = /^[0-9\b]+$/;

        if (nu === '' || re.test(nu)) {
            setBirthdateY(nu)
        }
    }


    const handleChangeBirthdateM = async (e) => {
        setBirthdateM(e.target.value)
    }


    const handleChangeBirthdateD = async (e) => {
        const nu = e.target.value;

        const re = /^[0-9\b]+$/;

        if (nu === '' || re.test(nu)) {
            setBirthdateD(nu)
        }
    }


    const UpdateUserAvatar = async (str) => {
        try {
            setUpdateAvatarInProgress(true)

            await csrf()

            const { data: res } = await axios.put(`/api/v1/updateUserAvatar`, { image: str });

            if (!res.error) {
                setAvatar(`http://localhost:8000${res.data.new_avatar}`)
                showMessage('Updated Successfully.', 'success');
            }

            setUpdateAvatarInProgress(false)
        } catch (err) {
            setUpdateAvatarInProgress(false)
            showMessage('something was wrong', 'error');
        }
    }


    const handleChangeAvatar = (event) => {
        const newIMG = event.target.files[0];
        if (newIMG) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const base64 = event.target.result;
                UpdateUserAvatar(base64);
            };

            reader.readAsDataURL(newIMG);
        }
    }


    const handleUpdateClick = async () => {
        const FormData = {
            first_name: firstname,
            last_name: lastname,
            email: email,
            birthdate: `${birthdateY}-${birthdateM}-${birthdateD}`,
            gender,
        };

        try {
            setInProgress(true)

            setErrors([])

            await csrf()

            const { data } = await axios.put(`api/v1/updateUserInfo`, FormData);

            if (!data.error) {
                await refresh()
                showMessage(data.message, 'success');
            }

            setInProgress(false)
        } catch (error) {
            setInProgress(false)

            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
                return
            }

            showMessage('something was wrong', 'error');
        }
    }

    return (
        <Layout>
            {renderAlertMessages}
            <div className="px-4 py-10 max-sm:px-0">
                {isLoading && <PageLoader /> || (
                    <div className='max-w-[1200px] mx-auto'>
                        {/* Page Header */}
                        <div className="flex items-center justify-between mb-6">
                            <div className="max-sm:px-4">
                                <h3 className='font-bold text-2xl flex items-center'>
                                    Account
                                </h3>
                                <p className='mt-1'>Edit Your Account Informations.</p>
                            </div>
                        </div>

                        <div className="bg-white border border-gray-300 p-4 max-w-[700px] rounded-xl max-sm:border-none">
                            <div className="mb-3 flex flex-col">
                                <label htmlFor="firstname" className='font-bold text-lg text-black'>Firstname</label>
                                <input
                                    value={firstname}
                                    onChange={(e) => setFirstname(e.target.value)}
                                    type="text"
                                    id="firstname"
                                    className='sm:px-4 px-2 py-3 border border-gray-300 rounded-md max-lg:text-sm outline-none hover:border-gray-400 focus:border-primary transition-colors duration-300 mb-1'
                                />
                                {errors.first_name &&
                                    <p className="mt-2 text-sm text-red-400">
                                        {errors.first_name}</p>}
                            </div>
                            <div className="mb-3 flex flex-col">
                                <label htmlFor="lastname" className='font-bold text-lg text-black'>Lastname</label>
                                <input
                                    value={lastname}
                                    onChange={(e) => setLastname(e.target.value)}
                                    type="text"
                                    id="lastname"
                                    className='sm:px-4 px-2 py-3 border border-gray-300 rounded-md max-lg:text-sm outline-none hover:border-gray-400 focus:border-primary transition-colors duration-300 mb-1'
                                />
                                {errors.last_name &&
                                    <p className="mt-2 text-sm text-red-400">
                                        {errors.last_name}</p>}
                            </div>
                            <div className="mb-3 flex flex-col">
                                <label htmlFor="email" className='font-bold text-lg text-black'>Email</label>
                                <input
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="text"
                                    id="email"
                                    className='sm:px-4 px-2 py-3 border border-gray-300 rounded-md max-lg:text-sm outline-none hover:border-gray-400 focus:border-primary transition-colors duration-300 mb-1'
                                />
                                {errors.email &&
                                    <p className="mt-2 text-sm text-red-400">
                                        {errors.email}</p>}
                            </div>
                            <div className="mb-3 flex flex-col">
                                <label htmlFor="gender" className='font-bold text-lg text-black'>Gender</label>
                                <select
                                    value={gender}
                                    onChange={(e) => setGender(e.target.value)}
                                    type="text"
                                    id="gender"
                                    className='sm:px-4 px-2 py-3 border border-gray-300 rounded-md max-lg:text-sm outline-none hover:border-gray-400 focus:border-primary transition-colors duration-300 mb-1'>
                                    {//!inProgress &&
                                        ['male', 'female'].map((g, i) => (
                                            <option key={i} value={g}>{g}</option>
                                        ))}
                                </select>
                                {errors.gender &&
                                    <p className="mt-2 text-sm text-red-400">
                                        {errors.gender}</p>}
                            </div>
                            <div className="mb-3 flex flex-col">
                                <label htmlFor="gender" className='font-bold text-lg text-black'>BirthDate</label>
                                <div className="grid grid-cols-3 gap-4">
                                    <input
                                        value={birthdateY}
                                        onChange={handleChangeBirthdateY}
                                        type="text"
                                        className='sm:px-4 px-2 py-3 border border-gray-300 rounded-md max-lg:text-sm outline-none hover:border-gray-400 focus:border-primary transition-colors duration-300 mb-1'
                                    />
                                    <select
                                        value={birthdateM}
                                        onChange={handleChangeBirthdateM}
                                        type="text"
                                        className='sm:px-4 px-2 py-3 border border-gray-300 rounded-md max-lg:text-sm outline-none hover:border-gray-400 focus:border-primary transition-colors duration-300 mb-1'>
                                        {['Janvier', 'Février', 'Mars',
                                            'Avril', 'Mai', 'Juin',
                                            'Juillet', 'Août', 'Septembre',
                                            'Octobre', 'Novembre', 'Décembre']
                                            .slice(0, 12).map((month, i) => {
                                                const num = i + 1;
                                                const monthNum = num < 10 ? `0${num}` : num;
                                                return (
                                                    <option
                                                        key={monthNum}
                                                        value={monthNum}>
                                                        {month}
                                                    </option>)
                                            })
                                        }
                                    </select>
                                    <input
                                        value={birthdateD}
                                        onChange={handleChangeBirthdateD}
                                        type="text"
                                        className='sm:px-4 px-2 py-3 border border-gray-300 rounded-md max-lg:text-sm outline-none hover:border-gray-400 focus:border-primary transition-colors duration-300 mb-1'
                                    />
                                </div>
                                {errors.birthdate &&
                                    <p className="mt-2 text-sm text-red-400">
                                        {errors.birthdate}</p>}
                            </div>
                            <div className="mb-3 flex flex-col">
                                <label htmlFor="gender" className='font-bold text-lg text-black'>Avatar</label>
                                <input type="file" id='fileInput' onChange={handleChangeAvatar} hidden />
                                <div className="relative flex items-center justify-center w-[124px] h-[124px] rounded-xl bg-[#e1f0ff] group mt-1">
                                    {avatar && (
                                        updateAvatarInProgress
                                            ? <div className="animate-pulse">Wait...</div>
                                            : <img
                                                src={avatar}
                                                alt="Profile Picture"
                                                width={124}
                                                height={124}
                                                className="rounded-xl"
                                            />)
                                    }
                                    <label htmlFor="fileInput" className="absolute right-0 bottom-0 h-full w-full rounded-xl cursor-pointer hidden group-hover:block animate-fade-in" style={{ "background": "rgb(0 0 0 / 40%)" }}>
                                        <div className='flex h-full w-full items-center justify-center'>
                                            <svg viewBox="0 0 24 24" width='24px' height='24px' fill="none">
                                                <g strokeWidth="0"></g>
                                                <g strokeLinecap="round" strokeLinejoin="round"></g>
                                                <g>
                                                    <path d="M11.293 3.293a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1-1.414 1.414L13 6.414V16a1 1 0 1 1-2 0V6.414L8.707 8.707a1 1 0 0 1-1.414-1.414l4-4Z" fill="#ffffff"></path>
                                                    <path d="M6 17a1 1 0 1 0-2 0v.6C4 19.482 5.518 21 7.4 21h9.2c1.882 0 3.4-1.518 3.4-3.4V17a1 1 0 1 0-2 0v.6c0 .778-.622 1.4-1.4 1.4H7.4c-.778 0-1.4-.622-1.4-1.4V17Z" fill="#ffffff"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="mt-3">
                                <button
                                    onClick={handleUpdateClick}
                                    disabled={inProgress}
                                    className='py-3 px-12 font-bold text-white rounded-md bg-primary border border-primary mt-2'>
                                    {!inProgress ? 'Update' : <BtnLoader/>}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    )
}
export default Account;

export default function _permissions() {
    return [
        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewOverviewPage', title: 'View Overview Page'},

        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewPracticesList', title: 'View Practices List'},
        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewPracticePage', title: 'View Practice Page'},
        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'UpdatePracticeInformations', title: 'Update Practice Informations'},
        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'DeletePracticeAccount', title: 'Delete Practice Account'},

        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewPatientsList', title: 'View Patients List'},
        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewPatientPage', title: 'View Patient Page'},
        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'UpdatePatientInformations', title: 'Update Patient Informations'},
        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'DeletePatientAccount', title: 'Delete Patien Account'},

        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewProfessionalsList', title: 'View Professionals List'},
        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewProfessionalPage', title: 'View Professional Page'},
        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'UpdateProfessionalInformations', title: 'Update Professional Informations'},
        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'DeleteProfessionalAccount', title: 'Delete Professional Account'},
        
        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewAppointmentsList', title: 'View Appointments List'},

        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewCaresList', title: 'View Cares List'},
        
        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewReviewsList', title: 'View Reviews List'},
        
        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewAdminsList', title: 'View Admins List'},
        
        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewAccountPage', title: 'View Account Page'},
        

        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewSpecialitiesList', title: 'View Specialities List'},

        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewLanguagesList', title: 'View Languages List'},

        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewCountriesList', title: 'View Countries List'},

        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewCitiesList', title: 'View Cities List'},

        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewRegionsList', title: 'View Regions List'},

        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewOfflinePatientsList', title: 'View OfflinePatients List'},
        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewOfflinePatientsPage', title: 'View OfflinePatients Page'},
        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'DeleteOfflinePatient', title: 'Delete OfflinePatient'},
        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'UpdateOfflinePatientInformations', title: 'Update OfflinePatient Informations'},

        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewPagesList', title: 'View Pages List'},

        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewCategoriesList', title: 'View Categories List'},

        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewTroublesList', title: 'View Troubles List'},

        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewTranslatorList', title: 'View Translator List'},
        
        {basic: false, low: true, medium: true, high: true, owner: true, slug: 'ViewPermissionsList', title: 'View Permissions List'},
        
        {basic: false, low: false, medium: false, high: false, owner: true, slug: 'ViewTicketsList', title: 'View Tickets List'},
        {basic: false, low: false, medium: false, high: false, owner: true, slug: 'ViewContactRequestsList', title: 'View ContactRequests List'},
    ]
}
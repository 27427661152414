import { isEmpty } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { BsSearch } from "react-icons/bs";
import { CgClose } from "react-icons/cg";



const Search = ({ navigate }) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [timer, setTimer] = useState(null);

    
    const handleSearch = async (term) => {
        navigate(term)
    };


    const debounce = (func, delay) => {
        let timeoutId;

        return function (...args) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }

            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };


    const handleInputChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);

        if (timer) {
            clearTimeout(timer);
        }

        setTimer(setTimeout(() => {
            debouncedSearch(term);
        }, 2000));
    };


    const debouncedSearch = debounce(handleSearch, 200);


    // Clear the timer when the component unmounts
    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timer]);



    // reset
    const reset = () => {
        setSearchTerm('')
        navigate('')
    }

    return (
        <div className='group flex border border-gray-200 hover:border-gray-300 transition-colors duration-200 outline-none rounded-3xl w-1/2 overflow-hidden bg-white'>
            <div className="pl-4 pr-3 mr-3 border-r border-gray-200 flex items-center text-gray-400 group-hover:border-gray-300 max-sm:hidden">
                <BsSearch />
            </div>
            <input
                type="text"
                className='w-full py-2 outline-none max-sm:px-3'
                placeholder='search...'
                value={searchTerm}
                onChange={handleInputChange}
            />
            {!isEmpty(searchTerm) && <div onClick={reset} className="px-3 flex items-center text-gray-400 hover:text-gray-500 cursor-pointer"><CgClose /></div>}
        </div>
    )
}
export default Search;
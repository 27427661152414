import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout/index';
import useAlert from 'components/Alerts/useAlert';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from 'hooks/useSWR';
import { useAuth } from 'hooks/useAuth';
import BtnLoader from 'components/Loaders/BtnLoader';
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css";
import PageLoader from 'components/shared/Loaders/PageLoader';
import { isEmpty } from 'helpers/utils';
import axios from 'lib/axios';


const Update = () => {
    const { renderAlertMessages, showMessage } = useAlert();

    const { id } = useParams();

    const navigate = useNavigate()

    const fetcher = useFetch()

    const { data: offlinePatientData, isLoading: offlinePatientDataInProgress } = fetcher.getOfflinePatientById(id);


    const { csrf } = useAuth()

    const [patientId, setPatientId] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [gender, setGender] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [countryPhoneCode, setCountryPhoneCode] = useState('')
    const [countryName, setCountryName] = useState('')
    const [countryCode, setCountryCode] = useState('')
    const [other, setOther] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({})


    useEffect(() => {
        const u = offlinePatientData?.data ?? {};
        setPatientId(u?.id)
        setFirstName(u?.first_name)
        setLastName(u?.last_name)
        setEmail(u?.email)
        setGender(u?.gender)
        setPhoneNumber(`${u?.country_code ?? ''} ${u?.phone ?? ''}`)
        setCountryPhoneCode(u?.country_code)
        setCountryName('')
        setCountryCode('')
        setOther(u?.other)
    }, [offlinePatientData])


    const ChangePhoneNumber = (phone, country) => {
        setPhoneNumber(phone);
        setCountryPhoneCode(country.dialCode)
        setCountryName(country.name)
        setCountryCode(country.countryCode)
    }


    const updateOfflinePatient = async () => {
        const formData = {
            patient_id: patientId,
            first_name: firstName,
            last_name: lastName,
            email,
            gender,
            phone: phoneNumber,
            country_code: countryPhoneCode,
            other
        };

        try {
            setIsLoading(true)

            setErrors([])

            await csrf()

            const { data } = await axios.put(`/api/v1/forAdmin/updateOfflinePatient`, formData);

            if (!data.error) {
                showMessage(data.message, 'success');
            }


            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)

            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
                return
            }

            if (error.response && error.response.data.error === true) {
                setErrors(error.response.data.message);
                return
            }

            showMessage('something was wrong', 'error');
        }
    }

    return (
        <Layout>
            {renderAlertMessages}
            {offlinePatientDataInProgress && <PageLoader /> || (
                !isEmpty(offlinePatientData) && (
                    <div className="sm:p-4">
                        <div className='max-w-[1200px] mx-auto'>
                            <div className="min-h-screen">
                                {/* Page Header */}
                                <div className='mt-5 mb-4 flex items-center justify-between max-sm:px-4'>
                                    <h2 className='text-xl font-extrabold'>Update Patient</h2>
                                    <div className="flex items-center gap-2 max-sm:hidden">
                                        <button
                                            onClick={() => navigate(-1)}
                                            disabled={isLoading}
                                            className="py-3 px-12 font-bold text-primary rounded-md bg-white border border-primary">
                                            Cancel
                                        </button>
                                        <button
                                            onClick={updateOfflinePatient}
                                            disabled={isLoading}
                                            className="py-3 px-12 font-bold text-white rounded-md bg-primary border border-primary">
                                            {!isLoading ? 'Update' : <BtnLoader />}
                                        </button>
                                    </div>
                                </div>

                                {/* Form */}
                                <div className="grid grid-cols-[1fr_350px] gap-4 max-lg:grid-cols-1">
                                    <div className="">
                                        {/* Account Informations */}
                                        <div className="bg-white border-gray-300 sm:rounded-xl sm:border">
                                            <div className="border-b border-gray-200 p-4">
                                                <span className='font-bold text-lg text-black'>Account Informations</span>
                                            </div>
                                            <div className="p-4">
                                                <div className="grid grid-cols-2 gap-4 max-sm:grid-cols-1 mb-4">
                                                    <div className="flex flex-col">
                                                        <label htmlFor="firstname" className="font-bold max-lg:text-sm mb-1">
                                                            First name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="firstname"
                                                            value={firstName}
                                                            onChange={(e) => setFirstName(e.target.value)}
                                                            placeholder="Enter first name"
                                                            className="sm:px-4 px-2 py-3 border border-gray-300 rounded-md max-lg:text-sm outline-none hover:border-gray-400 focus:border-primary transition-colors duration-300"
                                                        />
                                                        {errors.first_name && (
                                                            <p className="mt-1 text-sm text-red-500">
                                                                {errors.first_name}
                                                            </p>)}
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <label htmlFor="lastName" className="font-bold max-lg:text-sm mb-1">
                                                            Last name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="lastName"
                                                            value={lastName}
                                                            onChange={(e) => setLastName(e.target.value)}
                                                            placeholder="Enter last name"
                                                            className="sm:px-4 px-2 py-3 border border-gray-300 rounded-md max-lg:text-sm outline-none hover:border-gray-400 focus:border-primary transition-colors duration-300"
                                                        />
                                                        {errors.last_name && (
                                                            <p className="mt-1 text-sm text-red-500">
                                                                {errors.last_name}
                                                            </p>)}
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-2 gap-4 max-sm:grid-cols-1 mb-4">
                                                    <div className="flex flex-col">
                                                        <label htmlFor="email" className="font-bold max-lg:text-sm mb-1">
                                                            Email address
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="email"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            placeholder="Enter email address"
                                                            className="sm:px-4 px-2 py-3 border border-gray-300 rounded-md max-lg:text-sm outline-none hover:border-gray-400 focus:border-primary transition-colors duration-300"
                                                        />
                                                        {errors.email && (
                                                            <p className="mt-1 text-sm text-red-500">
                                                                {errors.email}
                                                            </p>)}
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <label htmlFor="phoneNumber" className="font-bold max-lg:text-sm mb-1">
                                                            Mobile number
                                                        </label>
                                                        <PhoneInput
                                                            name="phoneNumber"
                                                            id="phoneNumber"
                                                            inputStyle={{
                                                                border: "1px solid rgb(204, 204, 204)",
                                                                width: "100%",
                                                                height: "100%",
                                                                color: "rgb(85, 85, 85)",
                                                                paddingTop: "1rem",
                                                                paddingBottom: "1rem",
                                                            }}
                                                            country={"ma"}
                                                            value={phoneNumber}
                                                            onChange={ChangePhoneNumber}
                                                        />
                                                        {errors.phone && errors.country_code && (
                                                            <p className="mt-1 text-sm text-red-500">
                                                                {errors.phone ?? null}
                                                                {errors.country_code ?? null}
                                                            </p>)}
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-2 gap-4 max-sm:grid-cols-1 mb-4">
                                                    <div className="flex flex-col">
                                                        <label htmlFor="gender" className="font-bold max-lg:text-sm mb-1">
                                                            Gender
                                                        </label>
                                                        <select
                                                            id="gender"
                                                            value={gender}
                                                            onChange={(e) => setGender(e.target.value)}
                                                            className="sm:px-4 px-2 py-3 border border-gray-300 rounded-md max-lg:text-sm outline-none hover:border-gray-400 focus:border-primary transition-colors duration-300">
                                                            <option value="">Select Gender</option>
                                                            <option value="male">Male</option>
                                                            <option value="female">Female</option>
                                                        </select>
                                                        {errors.gender && (
                                                            <p className="mt-1 text-sm text-red-500">
                                                                {errors.gender}
                                                            </p>)}
                                                    </div>
                                                    {/* ## */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="bg-white border-gray-300 sm:rounded-xl sm:border">
                                            <div className="border-b border-gray-200 p-4">
                                                <span className='font-bold text-lg text-black'>Other Informations</span>
                                            </div>
                                            <div className="p-4 flex flex-col gap-4">
                                                <label className="flex flex-col" htmlFor="other">
                                                    <textarea
                                                        id="other"
                                                        value={other}
                                                        onChange={(e) => setOther(e.target.value)}
                                                        type="text"
                                                        placeholder='Enter Other Informations'
                                                        className="sm:px-4 px-2 py-3 border border-gray-300 rounded-md max-lg:text-sm outline-none hover:border-gray-400 focus:border-primary transition-colors duration-300 min-h-28"
                                                    ></textarea>
                                                    {errors.other && (
                                                        <p className="mt-1 text-sm text-red-500">
                                                            {errors.other}
                                                        </p>)}
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* Buttons */}
                            <div className="sticky bottom-0 flex items-center justify-center gap-4 p-4 bg-white -mb-4 border-t border-gray-300 sm:hidden">
                                <button
                                    onClick={() => navigate(-1)}
                                    disabled={isLoading}
                                    className="w-1/2 py-3 px-4 font-bold text-primary rounded-md max-lg:text-sm bg-white border border-primary">
                                    Cancel
                                </button>
                                <button
                                    onClick={updateOfflinePatient}
                                    disabled={isLoading}
                                    className="w-1/2 py-3 px-4 font-bold text-white rounded-md max-lg:text-sm bg-primary border border-primary">
                                    {!isLoading ? 'Update' : <BtnLoader />}
                                </button>
                            </div>
                        </div>
                    </div>
                ) || (
                    <div className="p-4 w-full h-52 flex flex-col items-center justify-center">
                        <p><strong>Error:</strong> Practice Not Found!</p>
                        <button
                            onClick={() => navigate(-1)}
                            className='mt-8 underline hover:no-underline'>
                            Return Back
                        </button>
                    </div>
                )
            )}
        </Layout>
    )
}
export default Update;
import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout/index';
import PageLoader from 'components/shared/Loaders/PageLoader';
import CreateCare from 'components/Pages/Cares/CreateCare';
import CaresList from 'components/Pages/Cares/CaresList';
import { useFetch } from 'hooks/useSWR';
import Pagination from 'components/Pagination';
import { useNavigate } from 'react-router-dom';
import Search from 'components/Search/Search';
import { LuSettings2 } from 'react-icons/lu';
import FilterCares from 'components/Pages/Cares/FilterCares';
import { FaPlus } from 'react-icons/fa6';


const Cares = () => {

    const navigate = useNavigate()

    // Get Url Parameters
    const searchParams = new URLSearchParams(document.location.search)
    const getPageNumber = searchParams.get('page') ?? 1;
    const [pageNumber, setPageNumber] = useState(getPageNumber)
    const [searchQuery, setSearchQuery] = useState('')


    const [orderBy, setOrderBy] = useState('new')
    const [careType, setCareType] = useState('all')
    const [careStatus, setCareStatus] = useState('all')
    const isFiltered = orderBy != 'new' || careType != 'all' || careStatus != 'all'; // show filter notif..


    // Get Cares Data Using SWR package
    const fetcher = useFetch()

    const { data: caresData, isLoading, mutate } = fetcher.getAllCares(pageNumber, searchQuery, orderBy, careType, careStatus);
    const refresh = () => mutate()

    const [cares, setCares] = useState([])


    useEffect(() => {
        setCares(caresData?.data?.data)
    }, [caresData])



    // Change Page
    const navigateToAnotherPage = (n) => {
        setPageNumber(n)
        navigate("/cares?page=" + n)
    }


    // Change Keywords And Get Data
    const handleChangeSearchQuery = (q) => {
        setSearchQuery(q)
        setPageNumber(1)
        navigate("/cares?page=1")
    }

    const refreshCaresData = (_by, _type, _status) => {
        setOrderBy(_by)
        setCareType(_type)
        setCareStatus(_status)
        mutate()
    }

    return (
        <Layout>
            <div className="px-4 py-10 max-sm:px-0">
                <div className='max-w-[1200px] mx-auto'>
                    {/* Header */}
                    <div className="flex items-center justify-between mb-6">
                        <div className="max-sm:px-4">
                            <h3 className='font-bold text-2xl flex items-center'>
                                Cares List
                                <span className='bg-bgPrimary border px-2 py-1 ml-4 rounded-3xl text-sm font-semibold'>
                                    {caresData?.data?.total ?? 0}
                                </span>
                            </h3>
                            <p className='mt-1'>View, Edit, Delete All Cares.</p>
                        </div>
                        <CreateCare
                            button={
                                <button
                                    className='px-4 py-2 bg-black hover:opacity-90 text-white text-lg font-bold rounded-md whitespace-nowrap flex items-center gap-2
                                    max-sm:fixed max-sm:bottom-4 max-sm:right-4'>
                                    <span className='hidden sm:block'>Add care</span>
                                    <span className='block sm:hidden'>Add</span>
                                    <FaPlus className='block sm:hidden' size={20} />
                                </button>
                            }
                            refresh={refresh}
                        />
                    </div>

                    <div className="flex items-center justify-between mb-6 bg-bgSecondary p-4 rounded-md">
                        <Search
                            navigate={handleChangeSearchQuery}
                        />
                        <FilterCares
                            refresh={refreshCaresData}
                            data={{ orderBy: orderBy, type: careType, status: careStatus }}
                            button={
                                <button className='relative flex items-center gap-2 border border-gray-200 hover:border-gray-300 bg-white hover:bg-gray-100 text-black font-bold transition-colors duration-200 outline-none py-1.5 px-4 rounded-3xl'>
                                    Filters
                                    <LuSettings2 size={20} className="max-lg:w-3" />
                                    {isFiltered && <span className='absolute top-0 left-0 w-2.5 h-2.5 bg-green-500 rounded-full border border-gray-200'></span>}
                                </button>
                            }
                        />
                    </div>

                    {isLoading && <PageLoader /> || (
                        <div className="">
                            {/* Content */}
                            <CaresList
                                cares={cares}
                                refresh={refresh}
                            />

                            {/* Pagination */}
                            <Pagination
                                currentPageNumber={caresData?.data?.current_page}
                                lastPageNumber={caresData?.data?.last_page}
                                changePageNumber={navigateToAnotherPage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    )
}
export default Cares;
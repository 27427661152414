import { useAuth } from "hooks/useAuth";
import axios from "lib/axios";
import { useState } from "react";
import ConfirmationModal from "components/Alerts/ConfirmationModal";
import BtnLoader from "components/Loaders/BtnLoader";
import { RiDeleteBin6Line } from "react-icons/ri";
import { isEmpty } from "helpers/utils";
import useAlert from "components/Alerts/useAlert";
import { Link } from "react-router-dom";
import { CgClose } from "react-icons/cg";

export default function AppointmentsList({ appointments, refresh }) {
    const { renderAlertMessages, showMessage } = useAlert();

    const { csrf } = useAuth()

    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false)
    const [appointmentIdToDelete, setAppointmentIdToDelete] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const deleteAppointment = async () => {
        try {

            const id = appointmentIdToDelete;

            setIsLoading(true)

            await csrf()

            const { data } = await axios.delete(`/api/v1/forAdmin/deleteAppointment/${id}`)

            if (!data.error) {
                await refresh();
                showMessage(data.message, 'success');
                setAppointmentIdToDelete('')
            }

            setIsLoading(false)
        } catch (error) {
            showMessage('something was wrong', 'error');
            setIsLoading(false)
        }
    }

    return (
        <>
            {renderAlertMessages}
            <section>
                <div className="overflow-hidden bg-white">
                    <div className="max-w-full overflow-auto min-h-[100px]">
                        <table className='w-full'>
                            <thead>
                                <tr className='[&>*]:px-4 [&>*]:py-2 [&>*]:text-black [&>*]:whitespace-nowrap [&>*]:text-start'>
                                    <th className=''>Id</th>
                                    <th className=''>Practice</th>
                                    <th className=''>Professional</th>
                                    <th className=''>Patient</th>
                                    <th className=''>Price</th>
                                    <th className=''>Date&time</th>
                                    <th className=''>At</th>
                                    <th className=''>Status</th>
                                    <th className=''></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isEmpty(appointments) && (
                                    appointments.map((appointment) => {

                                        const inProgressToDelete = isLoading && appointmentIdToDelete == appointment.id;

                                        // Get Practice Info
                                        const practiceId = appointment?.practice_data?.user ?? "";
                                        const practiceSlug = appointment?.practice_data?.slug ?? "";
                                        const practiceFullName = appointment?.practice_data?.business ?? 'Unknow';
                                        const practiceLogo = appointment?.practice_data?.logo ?? "";

                                        // Get Professional Info
                                        const professionalId = appointment?.professional?.id ?? "";
                                        const professionalSlug = appointment?.professional?.username ?? "";
                                        const professionalFullName = `${appointment?.professional?.first_name ?? 'Unknow'} ${appointment?.professional?.last_name ?? 'Unknow'}`;
                                        const professionalLogo = appointment?.professional?.avatar;

                                        // Get Patient Info
                                        const bookingType = appointment.booking_type == 'online';
                                        const patientId = bookingType ? appointment?.patient?.id : appointment?.offline_patient?.id;
                                        const patientSlug = bookingType ? appointment?.patient?.username : '#';
                                        const patientLink = bookingType ? `/patient/${patientId}` : `/offline_patient/${patientId}`;
                                        const patientFullName = bookingType ? `${appointment?.patient?.first_name ?? 'Unknow'} ${appointment?.patient?.last_name ?? 'Unknow'}` : `${appointment?.offline_patient?.first_name ?? 'Unknow'} ${appointment?.offline_patient?.last_name ?? 'Unknow'}`;
                                        const patientLogo = bookingType ? appointment?.patient?.avatar : appointment?.offline_patient?.avatar;

                                        return (
                                            <tr key={appointment.id} className='hover:bg-bgPrimary border-t border-gray-200 [&>*]:py-6 [&>*]:px-4 [&>*]:text-gray-700 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-pointer transition-colors duration-200'>
                                                <td className='font-bold'>{appointment.id}</td>
                                                <td className=''>
                                                    <div className="w-fit">
                                                        <Link to={`/practice/${practiceId}`}>
                                                            <div className="group flex items-center gap-2">
                                                                <div className='w-10 h-10 rounded-md bg-slate-200 overflow-hidden'>
                                                                    <img
                                                                        className='w-full h-full'
                                                                        src={practiceLogo}
                                                                        alt={practiceFullName}
                                                                    />
                                                                </div>
                                                                <div className="flex flex-col">
                                                                    <span className='font-bold group-hover:underline'>{practiceFullName}</span>
                                                                    <span className=''>{practiceSlug}</span>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td className=''>
                                                    <div className="w-fit">
                                                        <Link to={`/professional/${professionalId}`}>
                                                            <div className="group flex items-center gap-2">
                                                                <div className='w-8 h-8 rounded-full bg-slate-200 overflow-hidden'>
                                                                    <img
                                                                        className='w-full h-full'
                                                                        src={professionalLogo}
                                                                        alt={professionalFullName}
                                                                    />
                                                                </div>
                                                                <div className="flex flex-col">
                                                                    <span className='font-bold group-hover:underline'>{professionalFullName}</span>
                                                                    <span className=''>{professionalSlug}</span>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td className=''>
                                                    <div className="w-fit">
                                                        <Link to={patientLink}>
                                                            <div className="group flex items-center gap-2">
                                                                <div className='w-8 h-8 rounded-full bg-slate-200 overflow-hidden'>
                                                                    <img
                                                                        className='w-full h-full'
                                                                        src={patientLogo}
                                                                        alt={patientFullName}
                                                                    />
                                                                </div>
                                                                <div className="flex flex-col">
                                                                    <span className='font-bold group-hover:underline'>{patientFullName}</span>
                                                                    <span className=''>{patientSlug}</span>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td className=''>
                                                    <strong className="text-green-500">{`${appointment.currency} ${appointment.price}`}</strong>
                                                </td>
                                                <td className=''>{`${appointment.booking_date} ${appointment.booking_time}`}</td>
                                                <td className=''>{appointment.at}</td>
                                                <td className=''>{appointment.booking_status}</td>
                                                <td className=''>
                                                    <div className="flex items-center justify-center w-fit float-right">
                                                        <button
                                                            disabled={inProgressToDelete}
                                                            onClick={() => {
                                                                setAppointmentIdToDelete(appointment.id)
                                                                setConfirmationModalIsOpen(true)
                                                            }}
                                                            className="text-red-500 hover:text-white transition-colors duration-200 bg-transparent hover:bg-red-500 focus:outline-none p-2 rounded-md ml-2">
                                                            {!inProgressToDelete
                                                                ? <CgClose className="w-5 h-5" />
                                                                : <BtnLoader withText={false} />}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) || (
                                        <tr className='[&>*]:p-4 [&>*]:text-gray-700 [&>*]:whitespace-nowrap'>
                                            <td colSpan={9}>No Results Found!</td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>


            {/* Delete Appointment */}
            <ConfirmationModal
                isOpen={confirmationModalIsOpen}
                title="Delete Appointment"
                message="Are you sure you want to delete appointment?"
                buttonName="Supprimer"
                onConfirm={() => {
                    setConfirmationModalIsOpen(false);
                    deleteAppointment()
                }}
                onCancel={() => {
                    setConfirmationModalIsOpen(false);
                }}
            />
        </>
    )
}
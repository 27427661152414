import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from "../../../lib/dayjs";
import { isEmpty } from 'helpers/utils';
import { useAuth } from 'hooks/useAuth';


const PatientsList = ({ patientsData, refresh }) => {

    const { user: admin, userIsLoading: adminIsLoading } = useAuth()
    const { ViewPatientPage } = !adminIsLoading && admin && admin.roles;

    return (
        <>
            <div
                className="overflow-hidden bg-white">
                <div
                    className="max-w-full overflow-auto min-h-[100px]">
                    <table
                        className='w-full'>
                        <thead>
                            <tr className='[&>*]:px-4 [&>*]:py-2 [&>*]:text-black [&>*]:whitespace-nowrap [&>*]:text-start'>
                                <th className='text-start'>ID</th>
                                <th className='text-start'>FullName</th>
                                <th className='text-start'>Email</th>
                                <th className='text-start'>Phone</th>
                                <th className='text-start'>Appointments</th>
                                <th className='text-start'>Sales</th>
                                <th className='text-start'>Reviews</th>
                                <th className='text-start'>Wallet</th>
                                <th className='text-right'>Created_at</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isEmpty(patientsData) && (
                                patientsData.map((user) => {

                                    return (
                                        <tr key={user.id} className='hover:bg-bgPrimary border-t border-gray-200 [&>*]:py-6 [&>*]:px-4 [&>*]:text-gray-700 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-pointer transition-colors duration-200'>
                                            <td className='font-bold'>{user.id}</td>
                                            <td className=''>
                                                <div className="w-fit">
                                                    <Link to={ViewPatientPage ? `/patient/${user.id}` : '#'}>
                                                        <div className="group flex items-center gap-2">
                                                            <div className='w-10 h-10 rounded-full bg-gray-100 overflow-hidden'>
                                                                <img
                                                                    className='w-full h-full rounded-full'
                                                                    src={user.avatar}
                                                                    alt={`${user.first_name}  ${user.last_name}`}
                                                                />
                                                            </div>
                                                            <div className="flex flex-col">
                                                                <span className={`font-bold ${ViewPatientPage ? 'group-hover:underline' : ''}`}>{`${user.first_name}  ${user.last_name}`}</span>
                                                                <span className=''>{user.username}</span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </td>
                                            <td className=''>{user.email}</td>
                                            <td className=''>{`${user.country_code ?? ''} ${user.phone ?? ''}`}</td>
                                            <td className=''>{user.total_appointments}</td>
                                            <td className=''>{user.total_sales}</td>
                                            <td className=''>{user.total_reviews}</td>
                                            <td className=''>
                                                <strong className="text-green-500">{`MAD ${user.wallet}`}</strong>
                                            </td>
                                            <td className='text-right'>{dayjs(user.created_at).format("D MMMM, YYYY")}</td>
                                        </tr>
                                    )
                                })
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
export default PatientsList;
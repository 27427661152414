import { createSlice } from '@reduxjs/toolkit'

export const iconTypes = {
    "change_toSun": "change_toSun",
    "change_toMoon": "change_toMoon"
};

const iconslice = createSlice({
    name: "iconReducer",
    initialState: {
        icon: 'moon',
        isLoggedIn: false,


    },
    reducers: {
        iconMoon: (state, action) => {
            console.log("payload", action.payload);
            state.icon = 'moon'
        },
        iconSun: (state, action) => {
            console.log("payload", action.payload);

            state.icon = 'sun'
        },
        change: (state, { type, payload }) => {
            console.log("change reducer", type, payload);
            switch (type) {
                case iconTypes.change_toSun:
                    return state.icon = payload
                case iconTypes.change_toMoon:
                    return state.icon = payload
                default:
                    return state.icon = "moon"
            }
        }
    }
})

export default iconslice

export const iconAction = iconslice.actions

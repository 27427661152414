export const MobileDetect = {
  Android: function (userAgent) {
    if (typeof window !== "undefined") {
      return navigator.userAgent.match(/Android/i);
    } else {
      var ua = userAgent;
      return /mobile|iphone|ipod|android|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile|ipad|android|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i.test(
        ua
      );
    }
  },
  BlackBerry: function (userAgent) {
    if (typeof window !== "undefined") {
      return navigator.userAgent.match(/BlackBerry/i);
    } else {
      var ua = userAgent;
      return /mobile|iphone|ipod|android|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile|ipad|android|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i.test(
        ua
      );
    }
  },
  iOS: function (userAgent) {
    if (typeof window !== "undefined") {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    } else {
      var ua = userAgent;
      return /mobile|iphone|ipod|android|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile|ipad|android|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i.test(
        ua
      );
    }
  },
  Opera: function (userAgent) {
    if (typeof window !== "undefined") {
      return navigator.userAgent.match(/Opera Mini/i);
    } else {
      var ua = userAgent;
      return /mobile|iphone|ipod|android|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile|ipad|android|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i.test(
        ua
      );
    }
  },
  Windows: function (userAgent) {
    if (typeof window !== "undefined") {
      return navigator.userAgent.match(/IEMobile/i);
    } else {
      var ua = userAgent;
      return /mobile|iphone|ipod|android|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile|ipad|android|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i.test(
        ua
      );
    }
  },
  any: function (userAgent) {
    let ua = "";
    if (typeof window !== "undefined") {
      ua = navigator.userAgent;
    } else {
      ua = userAgent;
    }
    return /mobile|iphone|ipod|android|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile|ipad|android|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i.test(ua);
    // return (
    //   isMobile.Android(userAgent) ||
    //   isMobile.BlackBerry(userAgent) ||
    //   isMobile.iOS(userAgent) ||
    //   isMobile.Opera(userAgent) ||
    //   isMobile.Windows(userAgent)
    // );
  }
};

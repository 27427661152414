import BtnLoader from "components/Loaders/BtnLoader";
import { useAuth } from "hooks/useAuth";
import React, { useEffect, useState } from "react";

const Login = () => {

    const { login, loading } = useAuth()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState([])


    const SubmitForm = async (e) => {
        e.preventDefault()

        if (!email) return setErrors({ email: "email is required" })
        if (!password) return setErrors({ password: "password is required" })

        // login
        await login({
            setErrors,
            email,
            password
        })
    }

    return (
        <div className='max-w-[600px] my-16 mx-auto px-4'>
            <div className="m-4 text-start">
                <p className="text-2xl font-bold">Login</p>
            </div>

            <form onSubmit={SubmitForm} className="bg-gray-50 py-6 px-4 rounded-xl">
                <div className="flex flex-col mb-4">
                    <label className="font-semibold mb-3">Email</label>
                    <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={`w-full rounded-lg border border-gray-300 bg-transparent px-5 py-3 leading-tight text-gray-700 transition-all hover:border-gray-400 ${errors.email ? 'border border-red-400' : ''}`} />
                    {errors.email && <p className="mt-2 text-sm text-red-400">{errors.email}</p>}
                </div>

                <div className="flex flex-col mb-4">
                    <label className="font-semibold mb-3">Password</label>
                    <input
                        name="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={`w-full rounded-lg border border-gray-300 bg-transparent px-5 py-3 leading-tight text-gray-700 transition-all hover:border-gray-400 ${errors.password ? 'border border-red-400' : ''}`} />
                    {errors.password && <p className="mt-2 text-sm text-red-400">{errors.password}</p>}
                </div>

                <div className="mt-2 flex flex-col">
                    <button
                        className="focus:shadow-outline mt-2 rounded-lg bg-primary px-6  py-3 font-bold text-white transition-all hover:bg-[#404753]"
                        type="submit"
                        disabled={loading}>
                        {!loading ? 'Login' : <BtnLoader />}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default Login;
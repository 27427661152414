import BoxModal from "components/Layout/BoxModal";
import { useAuth } from "hooks/useAuth";
import axios from "lib/axios";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import useAlert from "components/Alerts/useAlert";
import { isEmpty } from "helpers/utils";
import { useFetch } from "hooks/useSWR";
import BtnLoader from "components/Loaders/BtnLoader";


export default function CreateRegion({ button, refresh }) {
    const { renderAlertMessages, showMessage } = useAlert();

    const [isOpen, setIsOpen] = useState(false)
    const closeModal = () => setIsOpen(false)
    const openModal = () => setIsOpen(true)


    const { csrf } = useAuth()


    // Get Countries
    const fetcher = useFetch()
    const { data: citiesData, isLoading: citiesIsLoading } = fetcher.getAllCities(1, '');


    const [en_name, setEn_name] = useState('')
    const [fr_name, setFr_name] = useState('')
    const [ar_name, setAr_name] = useState('')
    const [city, setCity] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({})


    const saveNewRegion = async () => {
        try {
            const formData = {
                en_name,
                fr_name,
                ar_name,
                city
            };

            setIsLoading(true)
            setErrors({})

            await csrf()

            const res = await axios.post('/api/v1/forAdmin/createRegion', formData)
            const data = await res.data;

            if (!data.error) {
                await refresh();

                showMessage(data.message, 'success');

                setEn_name('')
                setFr_name('')
                setAr_name('')
                setCity('')

                closeModal()
            }

            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)

            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
                return
            }

            if (error.response && error.response.data.error === true) {
                setErrors(error.response.data.message);
                return
            }

            showMessage('something was wrong', 'error');
        }
    }


    return (
        <>
            {renderAlertMessages}
            <div onClick={openModal} className="w-fit">
                {button}
            </div>
            <BoxModal
                isOpen={isOpen}
                onClose={closeModal}
                content={
                    <>
                        <div className="flex items-center justify-between p-4 border-b border-gray-300 bg-white">
                            <h2 className="text-lg font-extrabold text-black">Create Region</h2>
                            <button
                                type="button"
                                className="justify-center outline-none p-1 bg-gray-50 hover:bg-gray-100 rounded-md"
                                onClick={closeModal}>
                                <IoMdClose className="w-6 h-6" />
                            </button>
                        </div>

                        <div className="px-4 py-6 bg-white max-h-[60vh] max-lg:max-h-[80vh] overflow-y-auto">
                            <label className='flex flex-col mb-4'>
                                <span className='mb-1 text-base font-semibold text-black'>English Name</span>
                                <input
                                    onChange={(e) => setEn_name(e.target.value)}
                                    value={en_name}
                                    className='outline-none border border-gray-200 px-3 py-2 rounded-lg text-sm font-medium hover:border-gray-300 focus:border-gray-400'
                                    type="text"
                                />
                                {errors.en_name && (
                                    <p className="mt-1 text-sm text-red-500">
                                        {errors.en_name}
                                    </p>)}
                            </label>

                            <label className='flex flex-col mb-4'>
                                <span className='mb-1 text-base font-semibold text-black'>French Name</span>
                                <input
                                    onChange={(e) => setFr_name(e.target.value)}
                                    value={fr_name}
                                    className='outline-none border border-gray-200 px-3 py-2 rounded-lg text-sm font-medium hover:border-gray-300 focus:border-gray-400'
                                    type="text"
                                />
                                {errors.fr_name && (
                                    <p className="mt-1 text-sm text-red-500">
                                        {errors.fr_name}
                                    </p>)}
                            </label>

                            <label className='flex flex-col mb-4'>
                                <span className='mb-1 text-base font-semibold text-black'>Arabic Name</span>
                                <input
                                    onChange={(e) => setAr_name(e.target.value)}
                                    value={ar_name}
                                    className='outline-none border border-gray-200 px-3 py-2 rounded-lg text-sm font-medium hover:border-gray-300 focus:border-gray-400'
                                    type="text"
                                />
                                {errors.ar_name && (
                                    <p className="mt-1 text-sm text-red-500">
                                        {errors.ar_name}
                                    </p>)}
                            </label>

                            <label className='flex flex-col mb-4'>
                                <span className='mb-1 text-base font-semibold text-black'>City</span>
                                <select
                                    onChange={(e) => setCity(e.target.value)}
                                    value={city}
                                    className='outline-none border border-gray-200 px-3 py-2 rounded-lg text-sm font-medium hover:border-gray-300 focus:border-gray-400'>
                                    <option value=""></option>
                                    {citiesIsLoading && (
                                        <option value="">Loading...</option>
                                    ) || (
                                        !isEmpty(citiesData?.data?.data) && (
                                            citiesData?.data?.data?.map((c) => (
                                                <option key={c.id} value={c.id}>{c.en_name}</option>
                                            ))
                                        )
                                    )}
                                </select>
                                {errors.city && (
                                    <p className="mt-1 text-sm text-red-500">
                                        {errors.city}
                                    </p>)}
                            </label>

                        </div>

                        <div
                            className={`
                                bg-white border-t border-gray-300 p-4
                                max-lg:absolute max-lg:w-screen max-lg:grid max-lg:grid-cols-2 max-lg:gap-4 max-lg:right-0 max-lg:bottom-0
                                lg:flex lg:items-center lg:flex-row-reverse
                            `}>
                            <button onClick={saveNewRegion} disabled={isLoading} type="button" className="py-2 px-6 font-semibold text-white bg-buttonBg-1 rounded-md text-md hover:bg-buttonBg-2 border border-buttonBg-1 lg:ml-4">
                                {!isLoading ? 'Save' : <BtnLoader />}
                            </button>
                            <button onClick={closeModal} disabled={isLoading} type="button" className="py-2 px-6 font-semibold text-black bg-white border border-gray-300 rounded-md text-md hover:bg-gray-100">
                                Annuler
                            </button>
                        </div>
                    </>
                }
            />
        </>
    )
}
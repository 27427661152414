import { useState, useEffect } from 'react';
import Alerts from './AlertMessage';

const useAlert = () => {
    const [alert, setAlert] = useState(null);

    const showMessage = (message, type) => {
        setAlert({ message, type });
    };

    const closeAlert = () => {
        setAlert(null);
    };

    useEffect(() => {
        if (alert) {
            const timer = setTimeout(() => {
                closeAlert();
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [alert]);

    const renderAlertMessages = alert && (
        <Alerts message={alert.message} type={alert.type} onClose={closeAlert} />
    );

    return { renderAlertMessages, showMessage };
};

export default useAlert;
import { useState } from "react";
import Appointments from "./Appointments";
import Reviews from "./Reviews";
import Services from "./Services";
import Details from "./Details";
import DangerZone from "./DangerZone";
import Overview from "./Overview";

const Content = ({ professionalData }) => {

    const navBarLinks = ['Overview', 'Appointments', 'Reviews', 'Services', 'Details', 'Settings'];

    const [childName, setChildName] = useState(navBarLinks[0])

    return (
        <div className="max-w-full overflow-auto">
            <div className="bg-white sm:rounded-xl sm:border border-gray-200">
                {/* Professional Name & Total Price */}
                <div className="flex items-center justify-between p-4 border-b border-gray-200">
                    <h1 className='font-bold text-2xl'>
                        {professionalData.business ?? 'Unknow'}
                    </h1>
                    <b className='text-green-500'>{`${professionalData?.total_price ?? '00.00'} MAD`}</b>
                </div>
                <div className="overflow-x-auto p-4">
                    <div className='flex gap-2'>
                        {navBarLinks?.map((link, i) => {
                            const isActive = link == childName;
                            return (
                                <button
                                    key={i}
                                    disabled={isActive}
                                    onClick={() => setChildName(link)}
                                    className={`border  font-bold transition-colors duration-200 outline-none py-[6px] px-4 rounded-3xl
                                        ${isActive
                                            ? 'text-white bg-black border-black'
                                            : 'text-black bg-white border-gray-200 hover:border-gray-300 hover:bg-gray-100'}
                                        `}>
                                    {link}
                                </button>
                            )
                        })}
                    </div>
                </div>
            </div>
            
            {
                childName == 'Overview' && <Overview professionalData={professionalData} GoTo={setChildName} /> ||
                childName == 'Appointments' && <Appointments /> ||
                childName == 'Reviews' && <Reviews /> ||
                childName == 'Services' && <Services /> ||
                childName == 'Details' && <Details professionalData={professionalData} /> ||
                childName == 'Settings' && <DangerZone professionalData={professionalData} /> ||
                null
            }
        </div>
    )
}
export default Content;
import BoxModal from "components/Layout/BoxModal";
import Editor from "components/shared/Editor";
import { useAuth } from "hooks/useAuth";
import axios from "lib/axios";
import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import BtnLoader from "../../Loaders/BtnLoader";
import useAlert from "components/Alerts/useAlert";
import ImagePreview from "components/Preview/ImagePreview";

export default function UpdateSpeciality({ selectedSpeciality, setSelectedSpeciality, isOpen, refresh }) {
    const { renderAlertMessages, showMessage } = useAlert();

    const closeModal = () => setSelectedSpeciality({})

    const { csrf } = useAuth()


    const [specialityId, setSpecialityId] = useState('')
    const [enName, setEnName] = useState('')
    const [frName, setFrName] = useState('')
    const [arName, setArName] = useState('')
    const [description, setDescription] = useState('')
    const [details, setDetails] = useState('')
    const [webImage, setWebImage] = useState('')
    const [mobileImage, setMobileImage] = useState('')
    const [isVisible, setIsVisible] = useState("true")

    const [isLoading, setIsLoading] = useState(false)


    const updateSpeciality = async () => {
        try {
            const formData = {
                speciality_id: specialityId,
                en_name: enName,
                fr_name: frName,
                ar_name: arName,
                description,
                details,
                web_img: webImage,
                mobile_img: mobileImage,
                is_visible: isVisible,
            };
            
            setIsLoading(true)

            await csrf()

            const res = await axios.put('/api/v1/forAdmin/updateSpecialty', formData)
            const data = await res.data;

            if (!data.error) {
                await refresh();

                showMessage(data.message, 'success');

                closeModal()
            }

            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            showMessage('something was wrong', 'error');
        }
    }

    useEffect(() => {
        const s = selectedSpeciality;
        setSpecialityId(s.id)
        setEnName(s.en_name)
        setFrName(s.fr_name)
        setArName(s.ar_name)
        setDescription(s.description)
        setDetails(s.details)
        setWebImage('')
        setMobileImage('')
        setIsVisible(s?.is_visible?.toString())
    },[selectedSpeciality])

    return (
        <>
            {renderAlertMessages}
            <BoxModal
                isOpen={isOpen}
                onClose={closeModal}
                content={
                    <>
                        <div
                            className="flex items-center justify-between p-4 border-b border-gray-300 bg-white">
                            <h2 className="text-lg font-extrabold text-gray-900">Update Speciality</h2>
                            <button
                                type="button"
                                className="justify-center outline-none p-1 bg-gray-50 hover:bg-gray-100 rounded-md"
                                onClick={closeModal}>
                                <IoMdClose className="w-6 h-6" />
                            </button>
                        </div>

                        <div className="px-4 py-6 bg-white max-h-[60vh] max-lg:max-h-[80vh] overflow-y-auto">
                            <div className="grid gap-4 grid-cols-3 max-sm:grid-cols-1 max-sm:gap-0">
                            <label className='flex flex-col mb-4'>
                                    <span className='mb-1 text-base font-semibold text-black'>English name</span>
                                <input
                                    onChange={(e) => setEnName(e.target.value)}
                                    value={enName}
                                    className='outline-none border border-gray-200 px-3 py-2 rounded-lg text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                                    type="text"
                                />
                            </label>

                            <label className='flex flex-col mb-4'>
                                <span className='mb-1 text-base font-semibold text-black'>French name</span>
                                <input
                                    onChange={(e) => setFrName(e.target.value)}
                                    value={frName}
                                    className='outline-none border border-gray-200 px-3 py-2 rounded-lg text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                                    type="text"
                                />
                            </label>

                            <label className='flex flex-col mb-4'>
                                <span className='mb-1 text-base font-semibold text-black'>Arabic name</span>
                                <input
                                    onChange={(e) => setArName(e.target.value)}
                                    value={arName}
                                    className='outline-none border border-gray-200 px-3 py-2 rounded-lg text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                                    type="text"
                                />
                            </label>
                        </div>

                            <label className='flex flex-col mb-4'>
                                <span className='mb-1 text-base font-semibold text-black'>Description</span>
                                <Editor
                                    name="content"
                                    value={description}
                                    onChange={(e, { value }) => setDescription(value)}
                                />
                            </label>

                            <label className='flex flex-col mb-4'>
                                <span className='mb-1 text-base font-semibold text-black'>Details</span>
                                <Editor
                                    name="content"
                                    value={details}
                                    onChange={(e, { value }) => setDetails(value)}
                                />
                            </label>

                            <div className='grid gap-4 grid-cols-3 max-sm:grid-cols-1 max-sm:gap-0'>
                                <label className='flex flex-col mb-4'>
                                    <span className='mb-1 text-base font-semibold text-black'>Web Image</span>
                                    <ImagePreview
                                        changeImage={setWebImage}
                                        button={
                                            <div className='outline-none border px-3 py-2 rounded-lg text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400 bg-gray-100 cursor-pointer'>
                                                Choose Web Image
                                            </div>
                                        }
                                    />
                                </label>
                                <label className='flex flex-col mb-4'>
                                    <span className='mb-1 text-base font-semibold text-black'>Mobile Image</span>
                                    <ImagePreview
                                        changeImage={setMobileImage}
                                        button={
                                            <div className='outline-none border px-3 py-2 rounded-lg text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400 bg-gray-100 cursor-pointer'>
                                                Choose Mobile Image
                                            </div>
                                        }
                                    />
                                </label>
                                <label htmlFor='isVisible' className='flex flex-col mb-4'>
                                    <span className='mb-1 text-base font-semibold text-black'>isVisible</span>
                                    <select
                                        id="isVisible"
                                        value={isVisible}
                                        onChange={(e) => setIsVisible(e.target.value)}
                                        className="outline-none border border-gray-200 px-3 py-2 rounded-lg text-sm font-medium hover:border-gray-300 focus:border-gray-400">
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </select>
                                </label>
                            </div>
                        </div>

                        <div
                            className={`
                                bg-white border-t border-gray-300 p-4
                                max-lg:absolute max-lg:w-screen max-lg:grid max-lg:grid-cols-2 max-lg:gap-4 max-lg:right-0 max-lg:bottom-0
                                lg:flex lg:items-center lg:flex-row-reverse
                            `}>
                            <button onClick={updateSpeciality} disabled={isLoading} type="button" className="py-2 px-6 font-semibold text-white bg-buttonBg-1 rounded-md text-md hover:bg-buttonBg-2 border border-buttonBg-1 lg:ml-4">
                                {!isLoading ? 'Update' : <BtnLoader/>}
                            </button>
                            <button onClick={closeModal} disabled={isLoading} type="button" className="py-2 px-6 font-semibold text-black bg-white border border-gray-300 rounded-md text-md hover:bg-gray-100">
                                Annuler
                            </button>
                        </div>
                    </>
                }
            />
        </>
    )
}
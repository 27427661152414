import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout/index';
import { FiChevronRight } from 'react-icons/fi';
import { AiOutlinePlus } from 'react-icons/ai';
import { IconContext } from 'react-icons';
import { isEmpty } from 'helpers/utils';
import { useFetch } from 'hooks/useSWR';
import PageLoader from 'components/shared/Loaders/PageLoader';
import Create from 'components/Pages/Translator/Create';
import ConfirmationModal from 'components/Alerts/ConfirmationModal';
import useAlert from 'components/Alerts/useAlert';
import { useAuth } from 'hooks/useAuth';
import axios from 'lib/axios';
import BtnLoader from 'components/Loaders/BtnLoader';


const Translator = () => {
    const { renderAlertMessages, showMessage } = useAlert();

    const { csrf } = useAuth()

    const fetcher = useFetch()

    const { data: languagesData, isLoading, mutate } = fetcher.getAllTranslatorLanguages();
    const refresh = () => mutate()

    const [languages, setLanguages] = useState([])


    useEffect(() => {
        setLanguages(languagesData?.data ?? [])
    }, [languagesData])


    const [showFormAddNewKey, setShowFormAddNewKey] = useState(false)

    const [selectedData, setSelectedData] = useState({})
    const [updatedData, setUpdatedData] = useState('')


    const [selectedDataToEdit, setSelectedDataToEdit] = useState('')

    const [newLineData, setNewLineData] = useState({ i: '', k: '', v: '' })


    const SaveChanges = () => {

        const newIndex = newLineData.i;
        const newKey = newLineData.k;
        const newValue = newLineData.v;

        // Get the current keys
        const keys = Object.keys(updatedData);

        if (newIndex >= 0 && newIndex < keys.length) {
            // Update the key and value at the specified index
            const currentKey = keys[newIndex];
            updatedData[newKey] = updatedData[currentKey];
            delete updatedData[currentKey]; // Remove the old key

            // Update the value
            updatedData[newKey] = newValue;

            setSelectedDataToEdit('')
        }
    }


    const [updateLangIsLoading, setUpdateLangIsLoading] = useState(false)
    const handleSaveChangesClick = async () => {
        if (!isEmpty(selectedData)) {
            const Data = {
                id: selectedData.id,
                app: selectedData.app,
                locale: selectedData.locale,
                data: updatedData
            };

            try {
                setUpdateLangIsLoading(true)
    
                await csrf();
    
                const res = await axios.put(`/api/v1/forAdmin/translator/update/${Data.id}`, Data)
                const data = await res.data;
    
                if (!data.error) {
                    await refresh();

                    showMessage(data.message, 'success');
                }
    
                setUpdateLangIsLoading(false)
            } catch (error) {
                setUpdateLangIsLoading(false)
                showMessage('something was wrong', 'error');
            }
        }
    }


    const removeKey = (index) => {
        // Get the current keys
        const keys = Object.keys(updatedData);

        if (index >= 0 && index < keys.length) {
            const currentKey = keys[index];

            delete updatedData[currentKey]; // Remove

            setSelectedDataToEdit('')
        }
    }


    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false)
    const [deleteLangInProgress, setDeleteLangInProgress] = useState(false)
    const [langIdToDelete, seLangIdToDelete] = useState('')
    const deleteLanguage = async () => {
        try {

            const id = langIdToDelete;

            setDeleteLangInProgress(true)

            await csrf()

            const { data } = await axios.delete(`/api/v1/forAdmin/translator/delete/${id}`)

            if (!data.error) {
                await refresh();

                showMessage(data.message, 'success');

                seLangIdToDelete('')
                setSelectedData({})
                setUpdatedData('')
            }

            setDeleteLangInProgress(false)
        } catch (error) {
            showMessage('something was wrong', 'error');
            setDeleteLangInProgress(false)
        }
    }


    const [addNewKey, setAddNewKey] = useState('')
    const [addNewValue, setAddNewValue] = useState('')
    const SaveNewKey = async () => {
        updatedData[addNewKey] = addNewValue;
        setShowFormAddNewKey(false)
    }


    const [showSaveBtn, setShowSaveBtn] = useState(false)
    const showOrHideBtn = () => {
        const selectedDataToJson = JSON.parse(selectedData.data ? selectedData.data : '{}')
        const key1 = JSON.stringify(selectedDataToJson);
        const key2 = JSON.stringify(updatedData ? updatedData : {});

        if (!isEmpty(key1) && !isEmpty(key2)) {
            setShowSaveBtn(key1 !== key2 ? true : false)
        }
    }


    useEffect(() => {
        showOrHideBtn()
    })

    return (
        <Layout>
        {renderAlertMessages}
            <div className="px-4 py-10">
                <div className='max-w-[1200px] mx-auto'>
                    {/* Header */}
                    <div className="flex items-center justify-between mb-6">
                        <div className="">
                            <h3 className='font-bold text-2xl flex items-center'>
                                Translator
                                <span className='bg-bgPrimary border px-2 py-1 ml-4 rounded-3xl text-sm font-semibold'>
                                    {languages?.length ?? '0'}
                                </span>
                            </h3>
                            <p className='mt-1'>View, Edit, Delete.</p>
                        </div>

                        {showSaveBtn &&
                            <div className="flex items-center">
                                <button
                                    onClick={() => {
                                        const selectedDataToJson = JSON.parse(selectedData?.data ?? '{}')
                                        setUpdatedData(selectedDataToJson)
                                    }}
                                    disabled={isLoading} className='px-4 py-2 bg-transparent hover:opacity-90 text-black border border-black text-base font-bold rounded-md mr-4'>
                                    Cancel
                                </button>
                                <button onClick={() => handleSaveChangesClick()} disabled={isLoading} className='px-4 py-2 bg-black hover:opacity-90 text-white border border-black text-base font-bold rounded-md'>
                                    {!updateLangIsLoading ? "Save Changes" : <BtnLoader />}
                                </button>
                            </div>}
                    </div>

                    {isLoading && <PageLoader /> || (
                        <section className='mt-4 lg:mt-8'>
                            <div className="grid grid-cols-[200px_1fr] gap-4 lg:gap-8">
                                {/* Aside */}
                                <div>
                                    <div className="rounded-lg border border-gray-200 overflow-hidden">
                                        <div className='p-4 px-4 cursor-context-menu border-b border-gray-200'>
                                            <div className='flex items-center justify-between'>
                                                <span className='text-black text-base font-semibold'>Languages List</span>
                                            </div>
                                        </div>
                                        {languages.length > 0 && (
                                            languages.map((data) => (
                                                <div key={data.id}
                                                    onClick={() => {
                                                        const strCode = data.data;
                                                        const toJson = JSON.parse(strCode ? strCode : '{}');
                                                        setSelectedData(data)
                                                        setUpdatedData(toJson)
                                                        setSelectedDataToEdit('')
                                                        setShowFormAddNewKey(false)
                                                    }}
                                                    className={`p-4 px-4 cursor-pointer ${selectedData?.id == data.id ? ' bg-bgSecondary' : ' hover:bg-bgSecondary' }`}>
                                                    <div className='text-gray-700 flex items-center justify-between'>
                                                        <span className='text-base'>{`${data.app}_${data.locale}`}</span>
                                                        <FiChevronRight className='w-4 h-4' />
                                                    </div>
                                                </div>
                                            ))
                                        )}

                                        <Create
                                            refresh={refresh}
                                            button={
                                                <div className={`w-full p-4 px-4 cursor-pointer border-t border-gray-200 hover:bg-bgSecondary flex items-center`}>
                                                    <AiOutlinePlus className='w-4 h-4 text-gray-700' />
                                                </div>
                                            }
                                        />
                                    </div>
                                </div>

                                {/* Content */}
                                <div>
                                    <div className="rounded-lg border border-gray-200 overflow-hidden min-h-[50px]">
                                        <div className="max-w-full overflow-auto">
                                            <div className='w-full'>
                                                {!isEmpty(selectedData) &&
                                                    <div className='p-4 px-4 cursor-pointer border-b border-gray-200'>
                                                        <div className='flex items-center justify-between'>
                                                            <span className='text-black text-base font-semibold'>
                                                                {`${selectedData.app}_${selectedData.locale}`}
                                                            </span>
                                                            <button
                                                                disabled={deleteLangInProgress}
                                                                onClick={() => {
                                                                    seLangIdToDelete(selectedData.id)
                                                                    setConfirmationModalIsOpen(true)
                                                                }}
                                                                className='text-red-500 text-base font-semibold hover:underline'>
                                                                {deleteLangInProgress && langIdToDelete == selectedData.id
                                                                    ? <BtnLoader withText={false} />
                                                                    : 'Delete'
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>}
                                                <div>
                                                    {!isEmpty(updatedData) &&
                                                        Object.entries(updatedData).map(([key, value], i) => {
                                                            if (selectedDataToEdit !== i) {
                                                                return (
                                                                    <div
                                                                        key={i}
                                                                        onClick={() => {
                                                                            setSelectedDataToEdit(i)
                                                                            setNewLineData({ i: i, k: key, v: value });
                                                                            setShowFormAddNewKey(false)
                                                                        }}
                                                                        className='grid grid-cols-2 gap-4 hover:bg-bgSecondary'>
                                                                        <div className='p-4 text-sm font-medium whitespace-nowrap'>
                                                                            {key}
                                                                        </div>
                                                                        <div className='p-4 text-sm font-medium whitespace-nowrap'>
                                                                            {value}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div key={i}>
                                                                        <div className="grid grid-cols-2 gap-4 p-2 bg-gray-50">
                                                                            <textarea
                                                                                value={newLineData.k}
                                                                                onChange={(e) => setNewLineData({
                                                                                    i: newLineData.i,
                                                                                    k: e.target.value,
                                                                                    v: newLineData.v
                                                                                })}
                                                                                className='p-2 border'
                                                                            ></textarea>
                                                                            <textarea
                                                                                value={newLineData.v}
                                                                                onChange={(e) => setNewLineData({
                                                                                    i: newLineData.i,
                                                                                    k: newLineData.k,
                                                                                    v: e.target.value
                                                                                })}
                                                                                className='p-2 border'
                                                                            ></textarea>
                                                                        </div>

                                                                        <div className="grid grid-cols-3 gap-4 p-2 bg-gray-50">
                                                                            <button
                                                                                className='p-2 rounded text-white bg-blue-400 hover:bg-blue-500'
                                                                                onClick={() => setSelectedDataToEdit('')}>
                                                                                Cancel
                                                                            </button>
                                                                            <button
                                                                                className='p-2 rounded text-white bg-green-400 hover:bg-green-500'
                                                                                onClick={SaveChanges}>
                                                                                Save
                                                                            </button>
                                                                            <button
                                                                                className='p-2 rounded text-white bg-red-400 hover:bg-red-500'
                                                                                onClick={() => removeKey(i)}>
                                                                                Remove
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    {showFormAddNewKey && (
                                                        <div className="grid grid-cols-2 gap-4 p-2 bg-gray-50">
                                                            <textarea
                                                                value={addNewKey}
                                                                onChange={(e) => setAddNewKey(e.target.value)}
                                                                className='p-2 border'
                                                            ></textarea>
                                                            <textarea
                                                                value={addNewValue}
                                                                onChange={(e) => setAddNewValue(e.target.value)}
                                                                className='p-2 border'
                                                            ></textarea>
                                                            <button onClick={() => setShowFormAddNewKey(false)} className='p-2 border'>
                                                                Cancel
                                                            </button>
                                                            <button onClick={() => SaveNewKey()} className='p-2 border'>
                                                                Save
                                                            </button>
                                                        </div>
                                                    ) || (
                                                            !isEmpty(selectedData) &&
                                                            <div onClick={() => {
                                                                    setShowFormAddNewKey(true)
                                                                    setSelectedDataToEdit('')
                                                                    setAddNewKey('')
                                                                    setAddNewValue('')
                                                                }}
                                                                className={`w-full p-4 px-4 cursor-pointer border-t border-gray-200 hover:bg-bgSecondary flex items-center`}>
                                                                <AiOutlinePlus className='w-4 h-4 text-gray-700' />
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}


                    {/* Delete Language */}
                    <ConfirmationModal
                        isOpen={confirmationModalIsOpen}
                        title="Delete Language"
                        message="Are you sure you want to delete language?"
                        buttonName="Supprimer"
                        onConfirm={() => {
                            setConfirmationModalIsOpen(false);
                            deleteLanguage()
                        }}
                        onCancel={() => {
                            setConfirmationModalIsOpen(false);
                        }}
                    />
                </div>
            </div>
        </Layout>
    )
}
export default Translator;
import React, { useState } from 'react';
import Layout from 'components/Layout/index';
import { useNavigate, useParams } from 'react-router-dom';
import PageLoader from 'components/shared/Loaders/PageLoader';
import { useFetch } from 'hooks/useSWR';
import { isEmpty } from 'helpers/utils';
import AsideBar from 'components/Pages/Professional/AsideBar';
import Content from 'components/Pages/Professional/Content';


const Professional = () => {

    const { id } = useParams();

    const navigate = useNavigate()

    const fetcher = useFetch()

    const { data: professionalData, isLoading } = fetcher.getProfessionalById(id);

    return (
        <Layout>
            <div className="sm:p-4">
                {isLoading ? <PageLoader /> : (
                    !isEmpty(professionalData) && (
                        <div className='max-w-[1400px] mx-auto'>
                            <main
                                className='grid grid-cols-[1fr] lg:grid-cols-[300px_1fr] gap-4 max-sm:gap-0'>
                                {/* Personal Informations */}
                                <AsideBar
                                    user={professionalData?.data?.user ?? {}}
                                    professionalData={professionalData?.data}
                                />

                                {/* More Informations */}
                                <Content
                                    professionalData={professionalData?.data ?? {}}
                                />
                            </main>
                        </div>
                    ) || (
                        <div className="p-4 w-full h-52 flex flex-col items-center justify-center">
                            <p><strong>Error:</strong> Professional Not Found!</p>
                            <button
                                onClick={() => navigate(-1)}
                                className='mt-8 underline hover:no-underline'>
                                Return Back
                            </button>
                        </div>
                    )
                )}
            </div>
        </Layout>
    )
}

export default Professional;
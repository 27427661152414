import { useIsMobile } from "hooks/useIsMobile";
import React, { useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom/dist";


export default function PageLayout({ aside, content }) {

    // get params
    const [searchParams] = useSearchParams();
    const ticketsType = searchParams.get('type') ?? 'unread';

    // Get path name
    const location = useLocation();
    const { pathname } = location;

    const isMobile = useIsMobile();

    // State to store asideBar status
    const [showLinks, setShowLinks] = useState(!isMobile)

    // check if the link is active or not.
    const isLinkActive = (link) => {
        return link.includes(ticketsType);
    }

    return (
        <div className="relative w-full h-full">

            {/* Toggle Button */}
            <button
                onClick={() => setShowLinks(!showLinks)}
                className={`absolute ${showLinks ? 'left-[204px] max-lg:left-[unset] max-lg:right-2 rotate-0' : 'left-2 right-[unset] rotate-180'} top-3 bg-white hover:bg-gray-100 border border-gray-200 hover:border-gray-300 text-gray-700 p-1 rounded-full z-10 transition-colors duration-300`}>
                <BiChevronLeft className="w-5 h-5" />
            </button>

            {/* Aside & Content */}
            <div className={`grid h-full ${showLinks ? 'grid-cols-[220px_1fr] max-lg:grid-cols-1' : 'grid-cols-1'}`}>
                {/* AsideBar */}
                <div className={`lg:border-r lg:border-gray-200 overflow-x-hidden overflow-y-auto h-[calc(100vh_-_64px)] lg:h-[calc(100vh_-_70px)] asideScrollBar bg-white ${showLinks ? '' : 'hidden'}
                    max-lg:absolute max-lg:w-full max-lg:top-0 max-lg:left-0 max-lg:z-[9]`}>
                    {/* Title */}
                    <div className="border-b border-gray-200">
                        <div className="py-4 px-4">
                            <p className="font-bold text-base text-black">{aside.title}</p>
                        </div>
                    </div>

                    {/* Links */}
                    <div className="px-2 pt-1 pb-4 max-lg:pt-2 max-lg:grid max-lg:grid-cols-2 max-sm:grid-cols-1 gap-2">
                        {aside?.links?.map((link, i) => (
                            <div
                                key={i}
                                className={`cursor-pointer rounded-lg transition-colors duration-300
                                ${isLinkActive(link.path) ? 'bg-slate-200 max-lg:border max-lg:border-slate-200' : 'hover:bg-slate-100 max-lg:border max-lg:border-slate-100'}`}>
                                <Link to={link.path}>
                                    <div className="py-2.5 px-4 text-gray-900">
                                        <p className="text-sm max-lg:font-bold line-clamp-1">{link.name}</p>
                                        <span className="text-sm lg:hidden">{link.desc}</span>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Contnet */}
                <div className="">
                    <div className="p-4 max-sm:px-2">
                        {content}
                    </div>
                </div>
            </div>
        </div>
    );
}

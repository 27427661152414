import React from "react";

const PageLoader = () => {
    return (
        <div className="w-full h-[200px] flex items-center justify-center px-4">
            <div className="w-12 h-12 bg-transparent border-[6px] border-l-primary border-b-primary border-[rgb(6 65 105 / 15%)] animate-spin rounded-full"></div>
        </div>
    );
};

export default PageLoader;
import BoxModal from "components/Layout/BoxModal";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { LuUploadCloud } from "react-icons/lu";


export default function ImagePreview({ button, changeImage }) {

    const [isOpen, setIsOpen] = useState(false)
    const closeModal = () => setIsOpen(false)
    const openModal = () => setIsOpen(true)

    
    const [image, setImage] = useState('')


    const handleChangeImage = (event) => {
        const newIMG = event.target.files[0];
        if (newIMG) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const base64 = event.target.result;
                setImage(base64);
                changeImage(base64)
            };

            reader.readAsDataURL(newIMG);
        }
    }


    const handleRemoveImage = () => {
        setImage("");
        changeImage("");
    }

    return (
        <>
            <div onClick={openModal}>
                {button}
            </div>
            <BoxModal
                isOpen={isOpen}
                onClose={closeModal}
                content={
                    <>
                        <div className="flex items-center justify-between p-4 border-b border-gray-300 bg-white">
                            <h2 className="text-lg font-extrabold text-black">Select Image</h2>
                            <button
                                type="button"
                                className="justify-center outline-none p-1 bg-gray-50 hover:bg-gray-100 rounded-md"
                                onClick={closeModal}>
                                <IoMdClose className="w-6 h-6" />
                            </button>
                        </div>

                        <div className="px-4 py-4 bg-white h-[60vh] max-lg:h-[80vh] overflow-y-auto">
                            <input onChange={handleChangeImage} type="file" id="image" hidden />

                            {!image && (
                                <label
                                    htmlFor='image'
                                    className="bg-gray-50 hover:bg-gray-100 text-gray-400 hover:text-gray-500 p-4 rounded-lg w-full min-h-40 flex flex-col items-center justify-center cursor-pointer">
                                    <LuUploadCloud size={40} />
                                </label>
                            ) || (
                                <div className="w-full">
                                    <img src={image} alt="Preview Image Before Upload" className="w-full h-auto rounded-lg" />
                                </div>
                            )}
                        </div>

                        <div
                            className={`
                                bg-white border-t border-gray-300 p-4
                                max-lg:absolute max-lg:w-screen max-lg:grid max-lg:grid-cols-2 max-lg:gap-4 max-lg:right-0 max-lg:bottom-0
                                lg:flex lg:items-center lg:flex-row-reverse
                            `}>
                            <button
                                onClick={closeModal}
                                disabled={!image}
                                type="button"
                                className={`py-2 px-6 font-semibold text-white bg-buttonBg-1 rounded-md text-md border border-buttonBg-1 lg:ml-4 ${image ? 'hover:bg-buttonBg-2' : ''}`}>
                                Save
                            </button>
                            <button
                                onClick={handleRemoveImage}
                                disabled={!image}
                                type="button"
                                className={`py-2 px-6 font-semibold text-black bg-white border border-gray-300 rounded-md text-md ${image ? 'hover:bg-gray-100' : ''}`}>
                                Remove
                            </button>
                        </div>
                    </>
                }
            />
        </>
    )
}
import { Popover, Transition } from "@headlessui/react";
import { FiMoreHorizontal } from "react-icons/fi";
import { Fragment, useState } from "react";

export default function Select({ period, changePeriod, research, cancel }) {
    return (
        <Popover className="relative">
            {({ open, close }) => (
                <>
                    <Popover.Button
                        className={`${open ? "" : "text-opacity-90"} text-dark group  inline-flex items-center bg-white px-3 py-2 text-base font-medium hover:text-opacity-100 `}>
                        <FiMoreHorizontal />
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1">
                        <Popover.Panel className="  absolute z-10 w-screen h-full max-w-sm px-4 transform -translate-y-1/2 rounded-full xl:right-1/2 md:-right-[200%] -right-full  sm:px-0 lg:max-w-lg">
                            <div className="overflow-hidden border border-gray-300 rounded-md ring-1 ring-black ring-opacity-5">
                                <div className="relative grid gap-4 bg-white p-7 lg:grid-cols-1">
                                    <div>
                                        <h2 className="text-lg font-bold">Période</h2>
                                        <div>
                                            <select value={period} onChange={changePeriod} className="w-full h-10 mt-2 border px-3 border-gray-300 rounded-md hover:border-bleu-300">
                                                <option value={7}>7 dernières jours</option>
                                                <option value={30}>30 dernières jours</option>
                                            </select>
                                        </div>
                                        <div className="grid grid-cols-2 gap-4 mt-2">
                                            <button
                                                onClick={() => {
                                                    cancel()
                                                    close()
                                                }}
                                                className="w-full p-3 font-bold text-black bg-white border border-gray-300 rounded-md">
                                                Fermer
                                            </button>
                                            <button
                                                onClick={() => {
                                                    research()
                                                    close()
                                                }}
                                                className="w-full p-3 font-bold text-white  border border-gray-300 rounded-md bg-primary">
                                                Enregistrer
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
}

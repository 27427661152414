import BoxModal from "components/Layout/BoxModal";
import Editor from "components/shared/Editor";
import { useAuth } from "hooks/useAuth";
import axios from "lib/axios";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import BtnLoader from "../../Loaders/BtnLoader";
import { useFetch } from "hooks/useSWR";
import { isEmpty } from "helpers/utils";
import useAlert from "components/Alerts/useAlert";
import ImagePreview from "components/Preview/ImagePreview";

export default function CreateCare({ button, refresh }) {
    const { renderAlertMessages, showMessage } = useAlert();

    const [isOpen, setIsOpen] = useState(false)
    const closeModal = () => setIsOpen(false)
    const openModal = () => setIsOpen(true)

    const { csrf } = useAuth()


    // Get Cares Data Using SWR package
    const fetcher = useFetch()
    const { data: specialitiesData, isLoading: specialitiesIsLoading } = fetcher.getSpecialitiesToCreateCare();


    const [title, setTitle] = useState('')
    const [speciality, setSpeciality] = useState('')
    const [type, setType] = useState('')
    const [description, setDescription] = useState('')
    const [details, setDetails] = useState('')
    const [webImage, setWebImage] = useState('')
    const [mobileImage, setMobileImage] = useState('')
    const [isVisible, setIsVisible] = useState(true)

    const [isLoading, setIsLoading] = useState(false)


    const saveNewCare = async () => {
        try {
            const formData = {
                title,
                speciality,
                type,
                description,
                details,
                web_img: webImage,
                mobile_img: mobileImage,
                is_visible: isVisible,
            };
            
            setIsLoading(true)

            await csrf()

            const res = await axios.post('/api/v1/forAdmin/createCare', formData)
            const data = await res.data;

            if (!data.error) {
                await refresh();

                showMessage(data.message, 'success');

                setTitle('')
                setSpeciality('')
                setType('')
                setDescription('')
                setDetails('')
                setWebImage('')
                setMobileImage('')
                setIsVisible(true)

                closeModal()
            }

            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            showMessage('something was wrong', 'error');
        }
    }


    return (
        <>
            {renderAlertMessages}
            <div onClick={openModal} className="w-fit">
                {button}
            </div>
            <BoxModal
                isOpen={isOpen}
                onClose={closeModal}
                content={
                    <>
                        <div
                            className="flex items-center justify-between p-4 border-b border-gray-300 bg-white">
                            <h2 className="text-lg font-extrabold text-gray-900">Create Care</h2>
                            <button
                                type="button"
                                className="justify-center outline-none p-1 bg-gray-50 hover:bg-gray-100 rounded-md"
                                onClick={closeModal}>
                                <IoMdClose className="w-6 h-6" />
                            </button>
                        </div>

                        <div className="p-4 bg-white max-h-[60vh] max-lg:max-h-[80vh] overflow-y-auto">
                            <label className='flex flex-col mb-4'>
                                <span className='mb-1 text-sm font-medium text-gray-900'>Title</span>
                                <input
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                    className='outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                                    type="text"
                                />
                            </label>
                            
                            <label className='flex flex-col mb-4'>
                                <span className='mb-1 text-sm font-medium text-gray-900'>Speciality</span>
                                <select
                                    value={speciality}
                                    onChange={(e) => setSpeciality(e.target.value)}
                                    className='outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'>
                                    {specialitiesIsLoading && <option value="">Loading...</option> || (<>
                                        <option value=""></option>
                                        {!isEmpty(specialitiesData?.data) && (
                                            specialitiesData?.data.map((spe) => (
                                                <option key={spe.id} value={spe.id}>{spe.fr_name}</option>
                                            ))
                                        )}
                                    </>)}
                                </select>
                            </label>

                            <label className='flex flex-col mb-4'>
                                <span className='mb-1 text-sm font-medium text-gray-900'>Type</span>
                                <select
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                    className='outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'>
                                    <option value=""></option>
                                    <option value="practice">Practice</option>
                                    <option value="online">Online</option>
                                    <option value="home">Home</option>
                                </select>
                            </label>

                            <label className='flex flex-col mb-4'>
                                <span className='mb-1 text-sm font-medium text-gray-900'>Description</span>
                                <Editor
                                    name="content"
                                    value={description}
                                    onChange={(e, { value }) => setDescription(value)}
                                />
                            </label>

                            <label className='flex flex-col mb-4'>
                                <span className='mb-1 text-sm font-medium text-gray-900'>Details</span>
                                <Editor
                                    name="content"
                                    value={details}
                                    onChange={(e, { value }) => setDetails(value)}
                                />
                            </label>

                            <div className='grid gap-4 grid-cols-3 max-sm:grid-cols-1 max-sm:gap-0'>
                                <label className='flex flex-col mb-4'>
                                    <span className='mb-1 text-sm font-medium text-gray-900'>Web Image</span>
                                    <ImagePreview
                                        changeImage={setWebImage}
                                        button={
                                            <div className='outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400 bg-gray-100 cursor-pointer'>
                                                Choose Web Image
                                            </div>
                                        }
                                    />
                                </label>
                                <label className='flex flex-col mb-4'>
                                    <span className='mb-1 text-sm font-medium text-gray-900'>Mobile Image</span>
                                    <ImagePreview
                                        changeImage={setMobileImage}
                                        button={
                                            <div className='outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400 bg-gray-100 cursor-pointer'>
                                                Choose Mobile Image
                                            </div>
                                        }
                                    />
                                </label>
                                <label htmlFor='isVisible' className='flex flex-col mb-4'>
                                    <span className='mb-1 text-sm font-medium text-gray-900'>isVisible</span>
                                    <select
                                        id="isVisible"
                                        value={isVisible}
                                        onChange={(e) => setIsVisible(e.target.value)}
                                        className="outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400">
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </select>
                                </label>
                            </div>
                        </div>

                        <div
                            className={`
                                bg-white border-t border-gray-300 p-4
                                max-lg:absolute max-lg:w-screen max-lg:grid max-lg:grid-cols-2 max-lg:gap-4 max-lg:right-0 max-lg:bottom-0
                                lg:flex lg:items-center lg:flex-row-reverse
                            `}>
                            <button onClick={saveNewCare} disabled={isLoading} type="button" className="py-2 px-6 font-semibold text-white bg-buttonBg-1 rounded-md text-md hover:bg-buttonBg-2 border border-buttonBg-1 lg:ml-4">
                                {!isLoading ? 'Save' : <BtnLoader/>}
                            </button>
                            <button onClick={closeModal} disabled={isLoading} type="button" className="py-2 px-6 font-semibold text-black bg-white border border-gray-300 rounded-md text-md hover:bg-gray-100">
                                Annuler
                            </button>
                        </div>
                    </>
                }
            />
        </>
    )
}
import Djs from "dayjs";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/fr";
const tz = "Africa/Casablanca";

Djs.extend(utc);
Djs.extend(timezone);
Djs.extend(weekday);
Djs.extend(relativeTime);

Djs.locale("fr");
Djs.tz.setDefault(tz);

const dayjs = (...args) => {
    return Djs(...args).tz(tz);
};

const timezonedUnix = value => {
    return Djs.unix(value).tz(tz);
};

dayjs.unix = timezonedUnix;
dayjs.duration = Djs.duration;

export function isBeforeTwoHoursAgo(eventDate, eventTime) {
    // Combine event date and time
    const eventDateTime = dayjs(`${eventDate} ${eventTime}`);

    // Get the current date and time
    const currentDate = dayjs();

    // Subtract 2 hours from the current date and time
    const twoHoursAgo = currentDate.subtract(2, "hour");

    // Check if eventDateTime is before twoHoursAgo
    return eventDateTime.isBefore(twoHoursAgo);
}

export default dayjs;
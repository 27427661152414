/* eslint-disable react-hooks/rules-of-hooks */
import useSWR from "swr";
import * as api from "services/api";

export const useFetch = () => {
    return {
        getAllPractices: (page, searchQuery, sortBy) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/practices?page=${page}&query=${searchQuery}&sortby=${sortBy}`, async () => {
                const response = await api.getAllPractices(page, searchQuery, sortBy);
                return response;
            }),

        getAllAppointments: (page, sortBy, appointmentStatus) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAllAppointments?page=${page}&sortby=${sortBy}&status=${appointmentStatus}`, async () => {
                const response = await api.getAllAppointments(page, sortBy, appointmentStatus);
                return response;
            }),

        getPracticeById: (id) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getPracticeById/${id}`, async () => {
                const response = await api.getPracticeById(id);
                return response;
            }),

        getAppointmentsByPracticeId: (id, pageNumber, orderBy) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAppointmentsByPracticeId/${id}?page=${pageNumber}&sortby=${orderBy}`, async () => {
                const response = await api.getAppointmentsByPracticeId(id, pageNumber, orderBy);
                return response;
            }),

        getServicesByPracticeId: (id) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getServicesByPracticeId/${id}`, async () => {
                const response = await api.getServicesByPracticeId(id);
                return response;
            }),

        getTeamMembersByPracticeId: (id, pageNumber) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getTeamMembersByPracticeId/${id}?page=${pageNumber}`, async () => {
                const response = await api.getTeamMembersByPracticeId(id, pageNumber);
                return response;
            }),

        getReviewsByPracticeId: (id, pageNumber) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getReviewsByPracticeId/${id}?page=${pageNumber}`, async () => {
                const response = await api.getReviewsByPracticeId(id, pageNumber);
                return response;
            }),

        getAppointmentsByProfessionalId: (id, pageNumber, orderBy) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAppointmentsByProfessionalId/${id}?page=${pageNumber}&sortby=${orderBy}`, async () => {
                const response = await api.getAppointmentsByProfessionalId(id, pageNumber, orderBy);
                return response;
            }),

        getReviewsByProfessionalId: (id, pageNumber) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getReviewsByProfessionalId/${id}?page=${pageNumber}`, async () => {
                const response = await api.getReviewsByProfessionalId(id, pageNumber);
                return response;
            }),

        getServicesByProfessionalId: (id) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getServicesByProfessionalId/${id}`, async () => {
                const response = await api.getServicesByProfessionalId(id);
                return response;
            }),

        getAllPatients: (page, searchQuery, sortBy) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAllPatients?page=${page}&query=${searchQuery}&sortby=${sortBy}`, async () => {
                const response = await api.getAllPatients(page, searchQuery, sortBy);
                return response;
            }),

        getAllAdmins: (page, searchQuery, sortBy) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAllAdmins?page=${page}&query=${searchQuery}&sortby=${sortBy}`, async () => {
                const response = await api.getAllAdmins(page, searchQuery, sortBy);
                return response;
            }),

        getAllSpecialties: (page, searchQuery, sortBy, status) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAllSpecialties?page=${page}&query=${searchQuery}&sortby=${sortBy}&status=${status}`, async () => {
                const response = await api.getAllSpecialties(page, searchQuery, sortBy, status);
                return response;
            }),

        getAllPages: (page, searchQuery, sortBy) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAllPages?page=${page}&query=${searchQuery}&sortby=${sortBy}`, async () => {
                const response = await api.getAllPages(page, searchQuery, sortBy);
                return response;
            }),

        getAllLanguages: (page, searchQuery, sortBy) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAllLanguages?page=${page}&query=${searchQuery}&sortby=${sortBy}`, async () => {
                const response = await api.getAllLanguages(page, searchQuery, sortBy);
                return response;
            }),

        getAllCountries: (page, searchQuery, sortBy) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAllCountries?page=${page}&query=${searchQuery}&sortby=${sortBy}`, async () => {
                const response = await api.getAllCountries(page, searchQuery, sortBy);
                return response;
            }),

        getAllCities: (page, searchQuery, sortBy) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAllCities?page=${page}&query=${searchQuery}&sortby=${sortBy}`, async () => {
                const response = await api.getAllCities(page, searchQuery, sortBy);
                return response;
            }),

        getAllRegions: (page, searchQuery, sortBy) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAllRegions?page=${page}&query=${searchQuery}&sortby=${sortBy}`, async () => {
                const response = await api.getAllRegions(page, searchQuery, sortBy);
                return response;
            }),

        getAllTroubles: (page, searchQuery, sortBy) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAllTroubles?page=${page}&query=${searchQuery}&sortby=${sortBy}`, async () => {
                const response = await api.getAllTroubles(page, searchQuery, sortBy);
                return response;
            }),

        getDashboardInformations: () =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getDashboardInformations`, async () => {
                const response = await api.getDashboardInformations();
                return response;
            }),

        getAppointmentsStats: (days) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAppointmentsStats/${days}`, async () => {
                const response = await api.getAppointmentsStats(days);
                return response;
            }),

        getUsersStats: (days) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getUsersStats/${days}`, async () => {
                const response = await api.getUsersStats(days);
                return response;
            }),

        getAllProfessionals: (page, searchQuery, sortBy) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAllProfessionals?page=${page}&query=${searchQuery}&sortby=${sortBy}`, async () => {
                const response = await api.getAllProfessionals(page, searchQuery, sortBy);
                return response;
            }),

        getAllCares: (page, searchQuery, orderBy, careType, careStatus) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAllCares?page=${page}&query=${searchQuery}&sortby=${orderBy}&type=${careType}&status=${careStatus}`, async () => {
                const response = await api.getAllCares(page, searchQuery, orderBy, careType, careStatus);
                return response;
            }),

        getSpecialitiesToCreateCare: () =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getSpecialitiesToCreateCare`, async () => {
                const response = await api.getSpecialitiesToCreateCare();
                return response;
            }),

        getAllReviews: (page, starsNumber) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAllReviews?page=${page}&stars=${starsNumber}`, async () => {
                const response = await api.getAllReviews(page, starsNumber);
                return response;
            }),

        getAllOfflinePatients: (page, searchQuery, orderBy) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAllOfflinePatients?page=${page}&query=${searchQuery}&sortby=${orderBy}`, async () => {
                const response = await api.getAllOfflinePatients(page, searchQuery, orderBy);
                return response;
            }),

        getUserInformations: () =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/getUserInformations`, async () => {
                const response = await api.getUserInformations();
                return response;
            }),

        getProfessionalById: (id) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getProfessionalById/${id}`, async () => {
                const response = await api.getProfessionalById(id);
                return response;
            }),

        getPatientById: (id) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getPatientById/${id}`, async () => {
                const response = await api.getPatientById(id);
                return response;
            }),

        getOfflinePatientById: (id) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getOfflinePatientById/${id}`, async () => {
                const response = await api.getOfflinePatientById(id);
                return response;
            }),

        getAppointmentsByOfflinePatientId: (id, pageNumber, orderBy) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAppointmentsByOfflinePatientId/${id}?page=${pageNumber}&sortby=${orderBy}`, async () => {
                const response = await api.getAppointmentsByOfflinePatientId(id, pageNumber, orderBy);
                return response;
            }),

        getAppointmentsByPatientId: (id, pageNumber, orderBy) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAppointmentsByPatientId/${id}?page=${pageNumber}&sortby=${orderBy}`, async () => {
                const response = await api.getAppointmentsByPatientId(id, pageNumber, orderBy);
                return response;
            }),

        getReviewsByPatientId: (id, pageNumber) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getReviewsByPatientId/${id}?page=${pageNumber}`, async () => {
                const response = await api.getReviewsByPatientId(id, pageNumber);
                return response;
            }),

        getAllTranslatorLanguages: () =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/translator/get`, async () => {
                const response = await api.getAllTranslatorLanguages();
                return response;
            }),

        getAllAdminPermissions: () =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/adminPermissions/getAll`, async () => {
                const response = await api.getAllAdminPermissions();
                return response;
            }),

        getTickets: (page, type) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getTickets?type=${type}&page=${page}`, async () => {
                const response = await api.getTickets(page, type);
                return response;
            }),

        getAllContactRequests: (page, searchQuery, sortBy, status) =>
            useSWR(`${process.env.NEXT_PUBLIC_API_VERSION}/forAdmin/getAllContactRequests?page=${page}&query=${searchQuery}&sortby=${sortBy}&status=${status}`, async () => {
                const response = await api.getAllContactRequests(page, searchQuery, sortBy, status);
                return response;
            }),
    };
};

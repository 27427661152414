import React from "react";
import { Link } from "react-router-dom";
import { GoScreenFull } from 'react-icons/go';
import { IconContext } from 'react-icons';

function LastReviews({ reviews }) {
    return (
        <div className='bg-white rounded-lg p-4 border border-gray-300 h-full w-full'>
            <div className='flex justify-between pb-4 border-b border-gray-100'>
                <div>
                    <h2 className='text-xl mb-2 font-semibold'>Reviews</h2>
                    <p className='text-gray-500 font-sans'>Last 10 reviews</p>
                </div>
                <Link to="/reviews">
                    <IconContext.Provider value={{ className: 'w-6 h-6 text-gray-400 hover:text-gray-600' }}>
                        <GoScreenFull />
                    </IconContext.Provider>
                </Link>
            </div>

            {reviews.length > 0 ? (
                <div className='mt-4 overflow-auto'>
                    <table className="min-w-full">
                        <tbody>
                            {reviews.map((review) => {

                                const id = review.id;
                                const note = review.note;
                                const comment = review.comment;

                                return (
                                    <tr key={id} className="border-b border-gray-100">
                                        <td className='py-2 pr-2'>
                                            <p className="text-sm line-clamp-1 overflow-hidden text-ellipsis">
                                                {comment}
                                            </p>
                                        </td>
                                        <td className='py-2 pl-2 text-end'>
                                            <p className="font-bold text-lg">
                                                {note}
                                            </p>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            ) : (<>No Data</>)}
        </div>

    );
}

export default LastReviews;

import { useAuth } from "hooks/useAuth";
import axios from "lib/axios";
import { useState } from "react";
import ConfirmationModal from "components/Alerts/ConfirmationModal";
import BtnLoader from "components/Loaders/BtnLoader";
import { TbEdit } from "react-icons/tb";
import { isEmpty } from "helpers/utils";
import useAlert from "components/Alerts/useAlert";
import { CgClose } from "react-icons/cg";
import UpdateCountry from "./UpdateCountry";


export default function CountriesList({ countries, refresh }) {
    const { renderAlertMessages, showMessage } = useAlert();

    const { csrf } = useAuth()

    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false)
    const [countryIdToDelete, setCountryIdToDelete] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const deleteCountry = async () => {
        try {

            const id = countryIdToDelete;

            setIsLoading(true)

            await csrf()

            const { data } = await axios.delete(`/api/v1/forAdmin/deleteCountry/${id}`)

            if (!data.error) {
                await refresh();
                showMessage(data.message, 'success');
                setCountryIdToDelete('')
            }

            setIsLoading(false)
        } catch (error) {
            showMessage('something was wrong', 'error');
            setIsLoading(false)
        }
    }

    // set country data to update it
    const [selectedCountry, setSelectedCountry] = useState({})

    return (
        <>
            {renderAlertMessages}
            <section>
                <div className="overflow-hidden bg-white">
                    <div className="max-w-full overflow-auto min-h-[100px]">
                        <table className='w-full'>
                            <thead>
                                <tr className='[&>*]:px-4 [&>*]:py-2 [&>*]:text-black [&>*]:whitespace-nowrap [&>*]:text-start'>
                                    <th className=''>Id</th>
                                    <th className=''>English name</th>
                                    <th className=''>French name</th>
                                    <th className=''>Arabic name</th>
                                    <th className=''>Country code</th>
                                    <th className=''>Phone code</th>
                                    <th className=''></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isEmpty(countries) && (
                                    countries.map((country) => {

                                        const inProgressToDelete = isLoading && countryIdToDelete == country.id;

                                        return (
                                            <tr key={country.id} className='hover:bg-bgPrimary border-t border-gray-200 [&>*]:py-6 [&>*]:px-4 [&>*]:text-gray-700 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-pointer transition-colors duration-200'>
                                                <td className='font-bold'>{country.id}</td>
                                                <td className=''>{country.en_name}</td>
                                                <td className=''>{country.fr_name}</td>
                                                <td className=''>{country.ar_name}</td>
                                                <td className=''>{country.country_code}</td>
                                                <td className=''>{country.phone_code}</td>
                                                <td className="">
                                                    <div className="flex items-center justify-center w-fit float-right">
                                                        <button
                                                            disabled={inProgressToDelete}
                                                            onClick={() => setSelectedCountry(country)}
                                                            className="text-green-500 hover:text-white transition-colors duration-200 bg-transparent hover:bg-green-500 focus:outline-none p-2 rounded-md">
                                                            <TbEdit className="w-5 h-5" />
                                                        </button>
                                                        <button
                                                            disabled={inProgressToDelete}
                                                            onClick={() => {
                                                                setCountryIdToDelete(country.id)
                                                                setConfirmationModalIsOpen(true)
                                                            }}
                                                            className="text-red-500 hover:text-white transition-colors duration-200 bg-transparent hover:bg-red-500 focus:outline-none p-2 rounded-md">
                                                            {!inProgressToDelete
                                                                ? <CgClose className="w-5 h-5" />
                                                                : <BtnLoader withText={false} />}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>


            {/* Update */}
            <UpdateCountry
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                isOpen={!isEmpty(selectedCountry) ? true : false}
                refresh={refresh}
            />

            {/* Delete Care */}
            <ConfirmationModal
                isOpen={confirmationModalIsOpen}
                title="Delete Country"
                message="Are you sure you want to delete country?"
                buttonName="Supprimer"
                onConfirm={() => {
                    setConfirmationModalIsOpen(false);
                    deleteCountry()
                }}
                onCancel={() => {
                    setConfirmationModalIsOpen(false);
                }}
            />
        </>
    )
}
import React from 'react'
import dayjs from '../../../lib/dayjs'
import { isEmpty, getProName } from 'helpers/utils'
import { Link } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'

const ProfessionalsList = ({ professionalsData, refresh }) => {
    const { user: admin, userIsLoading: adminIsLoading } = useAuth()
    const { ViewProfessionalPage } = !adminIsLoading && admin && admin.roles
    console.log('ProfessionalsList', professionalsData)
    return (
        <div className="overflow-hidden bg-white">
            <div className="max-w-full overflow-auto min-h-[100px]">
                <table className="w-full">
                    <thead>
                        <tr className="[&>*]:px-4 [&>*]:py-2 [&>*]:text-black [&>*]:whitespace-nowrap">
                            <th className="text-start">ID</th>
                            <th className="text-start">Fullname</th>
                            <th className="text-start">Appointments</th>
                            <th className="text-start">Sales</th>
                            <th className="text-start">Website</th>
                            <th className="text-end">Created_at</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isEmpty(professionalsData) &&
                            professionalsData.map(professional => {
                                return (
                                    <tr
                                        key={professional?.id}
                                        className="hover:bg-bgPrimary border-t border-gray-200 [&>*]:py-6 [&>*]:px-4 [&>*]:text-gray-700 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-pointer transition-colors duration-200">
                                        <td className="font-bold">
                                            {professional?.id}
                                        </td>
                                        <td className="">
                                            <div className="w-fit">
                                                <Link
                                                    to={
                                                        ViewProfessionalPage
                                                            ? `/professional/${professional?.id}`
                                                            : '#'
                                                    }>
                                                    <div className="group flex items-center gap-2">
                                                        <div className="w-10 h-10 rounded-full bg-slate-200 overflow-hidden">
                                                            <img
                                                                className="w-full h-full"
                                                                src={
                                                                    professional?.logo
                                                                }
                                                                alt={getProName(
                                                                    professional,
                                                                    professional,
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <span
                                                                className={`font-bold ${
                                                                    ViewProfessionalPage
                                                                        ? 'group-hover:underline'
                                                                        : ''
                                                                }`}>
                                                                {getProName(
                                                                    professional,
                                                                    professional,
                                                                )}
                                                            </span>
                                                            <span className="">
                                                                {
                                                                    professional?.slug
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </td>
                                        <td className="">
                                            {professional?.total_appointments}
                                        </td>
                                        <td className="">
                                            {professional?.total_sales}
                                        </td>
                                        <td className="">
                                            {professional?.siteweb}
                                        </td>
                                        <td className="text-end">
                                            {dayjs(
                                                professional?.created_at,
                                            ).format('D MMMM, YYYY')}
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default ProfessionalsList

import { createSlice } from '@reduxjs/toolkit'

const LayoutReducer = createSlice({
    name: "LayoutReducer",
    initialState: {
        hideNavbar: false,
    },
    reducers: {
        hideNavbar: (state, { payload }) => {
            state.hideNavbar = payload
        },

    }
})

export default LayoutReducer

export const LayoutReducerAction = LayoutReducer.actions

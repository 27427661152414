import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { IoMailOutline, IoNotificationsOutline } from "react-icons/io5";
import ProfilMenu from "components/shared/MenuButton/ProfilMenu";
import logo from "assets/icons/Wenaya-successful.png";
import { useAuth } from "hooks/useAuth";
import { Link } from "react-router-dom";
import BtnLoader from "components/Loaders/BtnLoader";

const Navbar = ({ userData, setOpenSidebar, openSidebar }) => {

    const { logout } = useAuth()
    const [logOutInProgress, setLogOutInProgress] = useState(false)
    const handleLogoutClick = async () => {
        setLogOutInProgress(true)
        await logout();
        setLogOutInProgress(false)
    }

    return (
        <nav className="sticky top-0 z-50 w-full bg-white border-b border-gray-200">
            <div className="px-3 lg:px-5 lg:pl-3 h-[70px] flex items-center">
                <div className="w-full flex items-center justify-between">
                    <div className="flex items-center justify-start">
                        <button
                            data-drawer-target="logo-sidebar"
                            data-drawer-toggle="logo-sidebar"
                            aria-controls="logo-sidebar"
                            type="button"
                            onClick={() => setOpenSidebar((prev) => !prev)}
                            className="inline-flex items-center p-2 mr-1 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                        >
                            {
                                openSidebar ? <>
                                    <span className="sr-only">Open sidebar</span>
                                    <svg
                                        className="w-6 h-6"
                                        aria-hidden="true"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            clipRule="evenodd"
                                            fillRule="evenodd"
                                            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                                        />
                                    </svg>
                                </> : (
                                    <>
                                        <span className="sr-only">Close sidebar</span>
                                        <svg id="toggleSidebarMobileClose" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </>
                                )
                            }
                        </button>

                        <a href="/" className="flex md:mr-24 max-h-[39px]" >
                            <img
                                src={logo}
                                width={150}
                                height={150}
                                className="mr-3"
                                alt="Wenaya Logo"
                            />
                        </a>
                    </div>
                    <div className="flex items-center">
                        <div className="flex items-center  ml-3">
                            <Link
                                to="/messages"
                                className="flex items-center text-black hover:text-gray">
                                <IoMailOutline className="mr-2 w-8 h-8" />
                            </Link>
                            <Link
                                to="/notifications"
                                className="flex items-center text-black hover:text-gray">
                                <IoNotificationsOutline className="mr-2 w-8 h-8" />
                            </Link>
                            <div>
                                <ProfilMenu userAvatar={userData?.avatar}>
                                    <div className="flex flex-col">
                                        {userData &&
                                        <Link
                                            to="/account"
                                            className="w-full whitespace-nowrap font-bold text-left hover:bg-slate-100 px-4 py-2 transition-all rounded-md">
                                            {`${userData?.first_name} ${userData?.last_name}`}
                                        </Link>}
                                        <Link
                                            to="/account"
                                            className="w-full whitespace-nowrap text-left hover:bg-slate-100 px-4 py-2 transition-all rounded-md">
                                            Account
                                        </Link>
                                        <Link
                                            to="/settings"
                                            className="w-full whitespace-nowrap text-left hover:bg-slate-100 px-4 py-2 transition-all rounded-md">
                                            Settings
                                        </Link>
                                        <hr className="w-11/12 h-1 self-center my-1" />
                                        <a
                                            onClick={handleLogoutClick}
                                            style={{ cursor: 'pointer' }}
                                            className="w-full whitespace-nowrap font-medium text-left hover:bg-slate-100 px-4 py-2 transition-all rounded-md">
                                            <div className="flex items-center justify-between">
                                                <span>Déconnexion</span>
                                                {logOutInProgress && <BtnLoader withText={false} />}
                                            </div>
                                        </a>
                                    </div>
                                </ProfilMenu>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;

import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout';
import { TbCameraPlus } from 'react-icons/tb';
import BtnLoader from 'components/Loaders/BtnLoader';
import axios from 'lib/axios';
import { useAuth } from 'hooks/useAuth';
import useAlert from 'components/Alerts/useAlert';
import MobileInput from 'components/shared/MobileInput';

const CreatePractice = () => {
    const { renderAlertMessages, showMessage } = useAlert();


    const { csrf } = useAuth()

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [gender, setGender] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [countryPhoneCode, setCountryPhoneCode] = useState('')
    const [countryName, setCountryName] = useState('')
    const [countryCode, setCountryCode] = useState('')
    const [avatar, setAvatar] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [businessWebsite, setBusinessWebsite] = useState('')
    const [businessTeamSize, setBusinessTeamSize] = useState('2')
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({})

    // Generate a Random Password For Online Patients.
    function randomPassword() {
        const length = 10;
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let randomStr = '';
        for (let i = 0; i < length; i++) {
            randomStr += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        setPassword(randomStr);
    }


    useEffect(() => {
        randomPassword()
    }, [])


    const ChangePhoneNumber = (phone, country) => {
        setPhoneNumber(phone);
        setCountryPhoneCode(country.dialCode)
        setCountryName(country.name)
        setCountryCode(country.countryCode)
    }


    const handleChooseProAvatar = (event) => {
        const newIMG = event.target.files[0];
        if (newIMG) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const base64 = event.target.result;
                setAvatar(base64);
            };

            reader.readAsDataURL(newIMG);
        }
    }


    const resetData = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        randomPassword()
        setGender('')
        setPhoneNumber('')
        setCountryPhoneCode('')
        setCountryName('')
        setCountryCode('')
        setAvatar('')
        setBusinessName('')
        setBusinessWebsite('')
        setBusinessTeamSize('2')
    }


    const createPractice = async () => {
        const formData = {
            first_name: firstName,
            last_name: lastName,
            avatar: avatar,
            email,
            password,
            gender,
            phone: phoneNumber,
            country_code: countryPhoneCode,
            name: businessName,
            website: businessWebsite,
            teamsize: businessTeamSize,
        };
        
        try {
            setIsLoading(true)

            setErrors([])

            await csrf()

            const { data } = await axios.post(`/api/v1/forAdmin/createPractice`, formData);

            if (!data.error) {
                showMessage(data.message, 'success');
                resetData()
            }


            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)

            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
                return
            }

            if (error.response && error.response.data.error === true) {
                setErrors(error.response.data.message);
                return
            }

            showMessage('something was wrong', 'error');
        }
    }

    return (
        <Layout>
            {renderAlertMessages}
            <div className="sm:p-4">
                <div className='max-w-[1200px] mx-auto'>
                    <div className="min-h-screen">
                        {/* Page Header */}
                        <div className='mt-5 mb-4 flex items-center justify-between max-sm:px-4'>
                            <h2 className='text-xl font-extrabold'>Add New Practice</h2>
                            <button
                                onClick={createPractice}
                                disabled={isLoading}
                                className="py-3 px-12 font-bold text-white rounded-md max-lg:text-sm bg-primary border border-primary max-sm:hidden">
                                {!isLoading ? 'Create' : <BtnLoader/>}
                            </button>
                        </div>

                        {/* Form */}
                        <div className="grid grid-cols-[1fr_350px] gap-4 max-lg:grid-cols-1">
                            <div className="">
                                {/* Account Informations */}
                                <div className="bg-white border-gray-300 sm:rounded-xl sm:border">
                                    <div className="border-b border-gray-200 p-4">
                                        <span className='font-bold text-lg'>Account Informations</span>
                                    </div>
                                    <div className="p-4">
                                        <div className="grid grid-cols-2 gap-4 mb-4">
                                            <div className="flex flex-col">
                                                <label htmlFor="firstname" className="font-bold max-lg:text-sm mb-1">
                                                    First name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="firstname"
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                    placeholder="Enter first name"
                                                    className="px-2 py-2 border border-gray-300 rounded-md max-lg:text-sm"
                                                />
                                                {errors.first_name && (
                                                <p className="mt-1 text-sm text-red-500">
                                                    {errors.first_name}
                                                </p>)}
                                            </div>
                                            <div className="flex flex-col">
                                                <label htmlFor="lastName" className="font-bold max-lg:text-sm mb-1">
                                                    Last name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="lastName"
                                                    value={lastName}
                                                    onChange={(e) => setLastName(e.target.value)}
                                                    placeholder="Enter last name"
                                                    className="px-2 py-2 border border-gray-300 rounded-md max-lg:text-sm"
                                                />
                                                {errors.last_name && (
                                                <p className="mt-1 text-sm text-red-500">
                                                    {errors.last_name}
                                                </p>)}
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2 gap-4 mb-4">
                                            <div className="flex flex-col">
                                                <label htmlFor="email" className="font-bold max-lg:text-sm mb-1">
                                                    Email address
                                                </label>
                                                <input
                                                    type="text"
                                                    id="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="Enter email address"
                                                    className="px-2 py-2 border border-gray-300 rounded-md max-lg:text-sm"
                                                />
                                                {errors.email && (
                                                <p className="mt-1 text-sm text-red-500">
                                                    {errors.email}
                                                </p>)}
                                            </div>
                                            <div className="">
                                                <label className="flex flex-col" htmlFor="password">
                                                    <span className="font-bold max-lg:text-sm mb-1">Password</span>
                                                    <div className="relative flex flex-col">
                                                        <input
                                                            id="password"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            type="text"
                                                            className="px-2 py-2 border border-gray-300 rounded-md outline-none text-md"
                                                        />
                                                        <button
                                                            onClick={() => randomPassword()}
                                                            className="absolute right-0 flex px-3 py-2 rounded-md group top-2/4 -translate-y-2/4"
                                                            type="button">
                                                            <svg
                                                                width="20px"
                                                                height="20px"
                                                                className="fill-gray-500 group-hover:fill-gray-700"
                                                                viewBox="0 0 24 24">
                                                                <path d="M4,12a1,1,0,0,1-2,0A9.983,9.983,0,0,1,18.242,4.206V2.758a1,1,0,1,1,2,0v4a1,1,0,0,1-1,1h-4a1,1,0,0,1,0-2h1.743A7.986,7.986,0,0,0,4,12Zm17-1a1,1,0,0,0-1,1A7.986,7.986,0,0,1,7.015,18.242H8.757a1,1,0,1,0,0-2h-4a1,1,0,0,0-1,1v4a1,1,0,0,0,2,0V19.794A9.984,9.984,0,0,0,22,12,1,1,0,0,0,21,11Z" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    {errors.password && (
                                                    <p className="mt-1 text-sm text-red-500">
                                                        {errors.password}
                                                    </p>)}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2 gap-4 mb-4">
                                            <div className="flex flex-col">
                                                <label htmlFor="phoneNumber" className="font-bold max-lg:text-sm mb-1">
                                                    Mobile number
                                                </label>
                                                <MobileInput
                                                    name="phoneNumber"
                                                    id="phoneNumber"
                                                    required
                                                    value={phoneNumber}
                                                    onChange={ChangePhoneNumber}
                                                    inputStyle={{
                                                        width: "100%",
                                                        height: "100%",
                                                        color: "rgb(85, 85, 85)",
                                                        paddingTop: "1rem",
                                                        paddingBottom: "1rem",
                                                        border: errors.phone
                                                            ? "1px solid #F87171"
                                                            : "1px solid rgb(204, 204, 204)",
                                                    }}
                                                />
                                                {errors.phone && errors.country_code && (
                                                <p className="mt-1 text-sm text-red-500">
                                                    {errors.phone ?? null}
                                                    {errors.country_code ?? null}
                                                </p>)}
                                            </div>
                                            <div className="flex flex-col">
                                                <label htmlFor="gender" className="font-bold max-lg:text-sm mb-1">
                                                    Gender
                                                </label>
                                                <select
                                                    id="gender"
                                                    value={gender}
                                                    onChange={(e) => setGender(e.target.value)}
                                                    className="p-3 py-2 border border-gray-300 rounded-md outline-none max-lg:text-sm">
                                                    <option value="">Select Gender</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                </select>
                                                {errors.gender && (
                                                <p className="mt-1 text-sm text-red-500">
                                                    {errors.gender}
                                                </p>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Practice Informations */}
                                <div className="bg-white border-gray-300 sm:rounded-xl sm:border mt-4">
                                    <div className="border-b border-gray-200 p-4">
                                        <span className='font-bold text-lg'>Practice Informations</span>
                                    </div>
                                    <div className="p-4">
                                        <div className="grid grid-cols-2 gap-4 mb-4">
                                            <div className="flex flex-col">
                                                <label htmlFor="businessname" className="font-bold max-lg:text-sm mb-1">
                                                    Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="businessname"
                                                    value={businessName}
                                                    onChange={(e) => setBusinessName(e.target.value)}
                                                    placeholder="Enter Practice name"
                                                    className="px-2 py-2 border border-gray-300 rounded-md max-lg:text-sm"
                                                />
                                                {errors.name && (
                                                <p className="mt-1 text-sm text-red-500">
                                                    {errors.name}
                                                </p>)}
                                            </div>
                                            <div className="flex flex-col">
                                                <label htmlFor="businesswebsite" className="font-bold max-lg:text-sm mb-1">
                                                    Website
                                                </label>
                                                <input
                                                    type="text"
                                                    id="businesswebsite"
                                                    value={businessWebsite}
                                                    onChange={(e) => setBusinessWebsite(e.target.value)}
                                                    placeholder="Enter Website Url"
                                                    className="px-2 py-2 border border-gray-300 rounded-md max-lg:text-sm"
                                                />
                                                {errors.website && (
                                                <p className="mt-1 text-sm text-red-500">
                                                    {errors.website}
                                                </p>)}
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2 gap-4 mb-4">
                                            <div className="flex flex-col">
                                                <label htmlFor="teamsize" className="font-bold max-lg:text-sm mb-1">
                                                    Team Size
                                                </label>
                                                <select
                                                    id="teamsize"
                                                    value={businessTeamSize}
                                                    onChange={(e) => setBusinessTeamSize(e.target.value)}
                                                    className="p-3 py-2 border border-gray-300 rounded-md outline-none max-lg:text-sm">
                                                    <option value="2">2-5</option>
                                                    <option value="5">5-10</option>
                                                    <option value="10">10-15</option>
                                                </select>
                                                {errors.teamsize && (
                                                <p className="mt-1 text-sm text-red-500">
                                                    {errors.teamsize}
                                                </p>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <div className="bg-white border-gray-300 sm:rounded-xl sm:border">
                                    <div className="border-b border-gray-200 p-4">
                                        <span className='font-bold text-lg'>Account Avatar</span>
                                    </div>
                                    <div className="p-4">
                                        <div className='w-fit mx-auto'>
                                            <label htmlFor="fileInput" className="flex items-center justify-center overflow-hidden bg-opacity-25 rounded-full cursor-pointer lg:h-40 lg:w-40 h-32 w-32 bg-secondary hover:bg-opacity-20">
                                                {!avatar
                                                    ? <TbCameraPlus color="#0891b2" size={30} className="" />
                                                    : <img src={avatar} className="w-full h-full" />}
                                            </label>
                                            <input
                                                type="file"
                                                id="fileInput"
                                                className="hidden"
                                                onChange={handleChooseProAvatar}
                                                accept=".jpg, .jpeg, .png" // Spécifiez les types de fichiers acceptés
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Buttons */}
                    <div className="sticky bottom-0 flex items-center justify-center gap-4 p-4 bg-white -mb-4 border-t border-gray-300 sm:hidden">
                        <button
                            onClick={resetData}
                            disabled={isLoading}
                            className="w-1/2 py-3 px-4 font-bold text-primary rounded-md max-lg:text-sm bg-white border border-primary">
                            Reset
                        </button>
                        <button
                            onClick={createPractice}
                            disabled={isLoading}
                            className="w-1/2 py-3 px-4 font-bold text-white rounded-md max-lg:text-sm bg-primary border border-primary">
                            {!isLoading ? 'Create' : <BtnLoader/>}
                        </button>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default CreatePractice;
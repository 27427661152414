import { useState, Fragment, forwardRef, useEffect, useRef } from "react"
import { Transition } from '@headlessui/react'


function ProfilMenu({ children, userAvatar }) {
    const [showMainMenu, setShowMainMenu] = useState(false);
    const ref = useRef();


    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);


    const handleClickOutside = (event) => {
        var tagName = event.target.tagName;
        var attribute;
        if (tagName == "SPAN" || tagName == "IMG") {
            attribute = event.target.parentElement.dataset.action;
        } else {
            attribute = event.target.dataset.action;
        }

        if (ref.current && !ref.current.contains(event.target) && attribute != "menu-action") {
            setShowMainMenu(false);
        }
    };


    const Menu = forwardRef((props, forwardedRef) => {
        return (
            <div
                ref={forwardedRef}
                data-action="menu-list"
                className="absolute font-normal right-0 flex flex-col gap-2 w-72 bg-white p-3 rounded-md border border-gray-200">
                {children}
            </div>
        )
    })


    return (
        <div className="relative">
            <Fragment>
                <button data-action="menu-action" onClick={() => setShowMainMenu(prev => !prev)} className="h-[48px] text-lg font-semibold flex items-center">
                    <div className="w-8 h-8 rounded-full overflow-hidden bg-slate-200">
                        {userAvatar && <img src={userAvatar} alt="avatar" className="w-full h-full" />}
                    </div>
                </button>
                <Transition
                    show={showMainMenu}
                    ref={ref}
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1">
                    <Menu />
                </Transition>
            </Fragment>
        </div>
    )
}

ProfilMenu.displayName = 'ProfilMenu';

export default ProfilMenu;
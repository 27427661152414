import PaginationSecondary from "components/Pagination/Secondary";
import PageLoader from "components/shared/Loaders/PageLoader";
import { getFullName, isEmpty } from "helpers/utils";
import { useFetch } from "hooks/useSWR";
import dayjs from "../../../lib/dayjs";
import { Link, useParams } from "react-router-dom";
import { PiFolderOpenLight } from "react-icons/pi";
import { VscSettings } from "react-icons/vsc";
import { useEffect, useState } from "react";

const Reviews = () => {

    const [pageNumber, setPageNumber] = useState(1)

    const { id } = useParams();

    const fetcher = useFetch()
    const { data: reviewsData, isLoading } = fetcher.getReviewsByPatientId(id, pageNumber);

    const [reviews, setReviews] = useState([])

    useEffect(() => {
        setReviews(reviewsData?.data?.data)
    }, [reviewsData])

    // Change Page
    const navigateToAnotherPage = (n) => {
        setPageNumber(n)
    }

    return (
        <>
            <div className='bg-white sm:rounded-xl sm:border max-sm:border-t border-gray-200 mt-4 overflow-hidden'>
                <div className={`overflow-y-auto`}>
                    <div className="flex items-center justify-between border-b border-gray-200 p-4">
                        <h3 className='font-bold text-xl flex items-center'>
                            Reviews
                        </h3>
                        <span className='px-2 py-1 ml-2 rounded-3xl text-sm font-semibold leading-[normal] select-none cursor-context-menu'>
                            {`Total (${reviewsData?.data?.total ?? 0})`}
                        </span>
                    </div>
                    <div className="max-w-full overflow-x-auto">
                        <table
                            className='w-full'>
                            <thead>
                                <tr className='[&>*]:px-4 [&>*]:py-4 [&>*]:text-gray-700 [&>*]:whitespace-nowrap'>
                                    <th className='text-start'>Patient</th>
                                    <th className='text-start'>Professional</th>
                                    <th className='text-start'>Note</th>
                                    <th className='text-start'>Comment</th>
                                    <th className='text-right'>Created_at</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading && (
                                    !isEmpty(reviews) && (
                                        reviews.map((review) => {

                                            // get pro information, if no pro is found get practice info
                                            const isPro = !isEmpty(review.professional);
                                            const proPageUrl = isPro ? `/professional/${review?.professional?.id}` : `/practice/${review?.practice?.id}`;
                                            const proAvatar = isPro ? review?.professional?.avatar : review?.practice?.avatar;
                                            const proFullName = isPro ? getFullName(review.professional) : getFullName(review.practice);
                                            const proFirstName = isPro ? review?.professional?.first_name : review?.practice?.first_name;
                                            const proLastName = isPro ? review?.professional?.last_name : review?.practice?.last_name;
                                            const proEmail = isPro ? review?.professional?.email : review?.practice?.email;

                                            return (
                                                <tr key={review.id} className='hover:bg-bgPrimary border-t border-gray-200 [&>*]:py-2 [&>*]:px-4 [&>*]:text-gray-700 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-pointer transition-colors duration-200'>
                                                    <td className=''>
                                                        <div className="w-fit">
                                                            <Link to={`/patient/${review?.patient?.id}`}>
                                                                <div className="flex items-center gap-2">
                                                                    <div>
                                                                        <img src={review?.patient?.avatar} alt={`${review?.patient?.first_name} ${review?.patient?.last_name}`} className="w-8 h-8 rounded-full bg-slate-100" />
                                                                    </div>
                                                                    <div className="flex flex-col text-sm font-semibold">
                                                                        <span>{review?.patient?.first_name}</span>
                                                                        <span>{review?.patient?.last_name}</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td className=''>
                                                        <div className="w-fit">
                                                            <Link to={proPageUrl}>
                                                                <div className="flex items-center gap-2">
                                                                    <div>
                                                                        <img src={proAvatar} alt={proFullName} className={`w-8 h-8 bg-slate-100 ${isPro ? 'rounded-full' : 'rounded-lg'}`} />
                                                                    </div>
                                                                    <div className="flex flex-col text-sm font-semibold">
                                                                        <span>{proFirstName}</span>
                                                                        <span>{proLastName}</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td className=''>{review.note}</td>
                                                    <td className=''>{review.comment}</td>
                                                    <td className='text-right'>{dayjs(review.created_at).format("D MMMM, YYYY")}</td>
                                                </tr>
                                            )
                                        })
                                    ) || (
                                        // Show NotFound Message
                                        <tr>
                                            <td colSpan={5}>
                                                <div className='bg-gray-50 p-4 min-h-[200px] flex flex-col gap-2 items-center justify-center text-sm text-center text-gray-600'>
                                                    <PiFolderOpenLight size={35} />
                                                    <span>No Results Found!</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                ) || (
                                        // Show Loader
                                        <tr>
                                            <td colSpan={5}>
                                                <PageLoader />
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Pagination */}
            <PaginationSecondary
                currentPageNumber={reviewsData?.data?.current_page}
                lastPageNumber={reviewsData?.data?.last_page}
                changePageNumber={navigateToAnotherPage}
            />
        </>
    )
}
export default Reviews;
import React, { useState } from 'react';
import { isEmpty } from 'helpers/utils';
import { TbEdit } from 'react-icons/tb';
import BtnLoader from 'components/Loaders/BtnLoader';
import ConfirmationModal from 'components/Alerts/ConfirmationModal';
import useAlert from 'components/Alerts/useAlert';
import axios from 'lib/axios';
import { useAuth } from 'hooks/useAuth';
import UpdateAdmin from './UpdateAdmin';
import { CgClose } from 'react-icons/cg';


const AdminsList = ({ adminsData, refresh }) => {
    const { renderAlertMessages, showMessage } = useAlert();

    const { csrf, user, userIsLoading } = useAuth()

    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false)
    const [inProgressToDelete, setInProgressToDelete] = useState(false)
    const [adminIdToDelete, setAdminIdToDelete] = useState('')


    const deleteAdmin = async () => {
        try {

            const id = adminIdToDelete;

            setInProgressToDelete(true)

            await csrf()

            const { data } = await axios.delete(`/api/v1/forAdmin/deleteAdmin/${id}`)

            if (!data.error) {
                await refresh();
                showMessage(data.message, 'success');
                setAdminIdToDelete('')
            }

            setInProgressToDelete(false)
        } catch (error) {
            showMessage('something was wrong', 'error');
            setInProgressToDelete(false)
        }
    }


    const [selectedAdmin, setSelectedAdmin] = useState({})


    return (
        <>
            {renderAlertMessages}
            <div
                className="overflow-hidden bg-white">
                <div
                    className="max-w-full overflow-auto min-h-[100px]">
                    <table
                        className='w-full'>
                        <thead>
                            <tr className='[&>*]:px-4 [&>*]:py-2 [&>*]:text-black [&>*]:whitespace-nowrap [&>*]:text-start'>
                                <th className=''>ID</th>
                                <th className=''>FullName</th>
                                <th className=''>Email</th>
                                <th className=''>Phone</th>
                                <th className=''>Role</th>
                                <th className=''></th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isEmpty(adminsData) && (
                                adminsData.map((admin) => {
                                    return (
                                        <tr key={admin.id} className='hover:bg-bgPrimary border-t border-gray-200 [&>*]:py-6 [&>*]:px-4 [&>*]:text-gray-700 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-pointer transition-colors duration-200'>
                                            <td className='font-bold'>{admin.id}</td>
                                            <td className=''>
                                                <div className="w-fit">
                                                    <div className="flex items-center gap-2">
                                                        <div className='w-10 h-10 rounded-full bg-gray-100 overflow-hidden'>
                                                            <img
                                                                className='w-full h-full rounded-full'
                                                                src={admin.avatar}
                                                                alt={`${admin.first_name}  ${admin.last_name}`}
                                                            />
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <span className='font-bold'>{`${admin.first_name}  ${admin.last_name}`}</span>
                                                            <span className=''>{admin.username}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className=''>{admin.email}</td>
                                            <td className=''>{`${admin.country_code ?? ''} ${admin.phone ?? ''}`}</td>
                                            <td className=''>{admin.role}</td>
                                            <td className='whitespace-nowrap'>
                                                {!userIsLoading && (
                                                    <div className="flex items-center justify-center w-fit float-right">
                                                        <button
                                                            disabled={inProgressToDelete}
                                                            onClick={() => setSelectedAdmin(admin)}
                                                            className="text-green-500 hover:text-white transition-colors duration-200 bg-transparent hover:bg-green-500 focus:outline-none p-2 rounded-md">
                                                            <TbEdit className="w-5 h-5" />
                                                        </button>
                                                        <button
                                                            disabled={inProgressToDelete}
                                                            onClick={() => {
                                                                setAdminIdToDelete(admin.id)
                                                                setConfirmationModalIsOpen(true)
                                                            }}
                                                            className="text-red-500 hover:text-white transition-colors duration-200 bg-transparent hover:bg-red-500 focus:outline-none p-2 rounded-md">
                                                            {inProgressToDelete && adminIdToDelete == admin.id
                                                                ? <BtnLoader withText={false} />
                                                                : <CgClose className="w-5 h-5" />}
                                                        </button>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })
                            )}
                        </tbody>
                    </table>
                </div>
            </div>


            {/* Update */}
            <UpdateAdmin
                selectedAdmin={selectedAdmin}
                setSelectedAdmin={setSelectedAdmin}
                isOpen={!isEmpty(selectedAdmin) ? true : false}
                refresh={refresh}
            />


            {/* Delete Admin */}
            <ConfirmationModal
                isOpen={confirmationModalIsOpen}
                title="Delete Admin"
                message="Are you sure you want to delete this admin?"
                buttonName="Supprimer"
                onConfirm={() => {
                    setConfirmationModalIsOpen(false);
                    deleteAdmin()
                }}
                onCancel={() => {
                    setConfirmationModalIsOpen(false);
                }}
            />
        </>
    )
}
export default AdminsList;
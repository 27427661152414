import { isEmpty } from 'helpers/utils';
import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from "../../../lib/dayjs";
import { useAuth } from 'hooks/useAuth';


const PracticesList = ({ practicesData }) => {

    const { user: admin, userIsLoading: adminIsLoading } = useAuth()
    const { ViewPracticePage } = !adminIsLoading && admin && admin.roles;

    return (
        <section className="flex flex-col mb-10">
            <div className="overflow-x-auto bg-white">
                <div className="inline-block min-w-full align-middle">
                    <div className="overflow-hidden">
                        <table className="min-w-full">
                            <thead className="bg-white">
                                <tr className='[&>*]:px-4 [&>*]:py-2 [&>*]:text-black [&>*]:whitespace-nowrap'>
                                    <th className="text-start">ID</th>
                                    <th className="text-start">practice</th>
                                    <th className="text-start">members</th>
                                    <th className="text-start">appointments</th>
                                    <th className="text-start">sales</th>
                                    <th className="text-start">website</th>
                                    <th className="text-end">created_at</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {!isEmpty(practicesData) && (
                                    practicesData.map((practice) => {
                                        return (
                                            <tr key={practice.user} className='hover:bg-bgPrimary border-t border-gray-200 [&>*]:py-6 [&>*]:px-4 [&>*]:text-gray-700 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-pointer transition-colors duration-200'>
                                                <td className="font-bold">{practice.user}</td>
                                                <td className="">
                                                    <div className="w-fit">
                                                        <Link to={ViewPracticePage ? `/practice/${practice.user}` : '#'}>
                                                            <div className="group flex items-center gap-2">
                                                                <div className='w-10 h-10 rounded-md bg-slate-200 overflow-hidden'>
                                                                    <img
                                                                        className='w-full h-full'
                                                                        src={practice.logo}
                                                                        alt={practice.business}
                                                                    />
                                                                </div>
                                                                <div className="flex flex-col">
                                                                    <span className={`font-bold ${ViewPracticePage ? 'group-hover:underline' : ''}`}>{practice.business}</span>
                                                                    <span className=''>{practice.slug}</span>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td className="">{practice.total_members}</td>
                                                <td className="">{practice.total_appointments}</td>
                                                <td className="">{practice.total_sales}</td>
                                                <td className="">{practice.siteweb}</td>
                                                <td className="text-end">{dayjs(practice.created_at).format("D MMMM, YYYY")}</td>
                                            </tr>
                                        )
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default PracticesList;
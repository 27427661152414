import { GetRelativeTime } from "lib/GetRelativeTime";
import dayjs from "../../../lib/dayjs";
import BtnLoader from "components/Loaders/BtnLoader";
import ConfirmationModal from "components/Alerts/ConfirmationModal";
import { changeTicketType, deleteTicket } from "services/api";
import { useState } from "react";
import useAlert from "components/Alerts/useAlert";


export const TicketCard = ({ ticket, refresh }) => {
    const { renderAlertMessages, showMessage } = useAlert();

    // get ticket info
    const { id, name, email, message, created_at, type } = ticket ?? {};


    // remove ticket
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false)
    const [inProgressToDelete, setInProgressToDelete] = useState(false)
    const removeTicket = async () => {
        setInProgressToDelete(true)

        const { error, message } = await deleteTicket(id)

        if (!error) {
            showMessage(message, 'success');
            await refresh();
        } else {
            showMessage('something was wrong', 'error');
        }

        setInProgressToDelete(false)
    }

    // change ticket type (unread, solved, archived)
    const [inProgressChangeType, setInProgressChangeType] = useState('')
    const changeTicketType_ = async (t) => {
        setInProgressChangeType(t)

        const { error, message } = await changeTicketType(id, t)

        if (!error) {
            showMessage(message, 'success');
            setInProgressChangeType('')
            await refresh();
        } else {
            showMessage('something was wrong', 'error');
        }

        setInProgressChangeType('')
    }

    return (
        <>
        {renderAlertMessages}
        <div className="p-4 flex flex-col gap-6">
            <div className="flex items-start justify-between">
                <p className='font-bold text-base text-[#0D1619]'>{`#${id}`}</p>
                <p className='text-sm font-semibold'>{dayjs(created_at).format("D MMMM, YYYY")} <span className='text-xs text-[#757676]'>({GetRelativeTime(created_at)})</span></p>
            </div>

            <div className="flex flex-col">
                <span className='font-semibold text-base text-[#0D1619]'>Full name</span>
                <p className='font-semibold text-base text-[#757676]'>{name}</p>
            </div>

            <div className="flex flex-col">
                <span className='font-semibold text-base text-[#0D1619]'>Email Address</span>
                <p className='font-semibold text-base text-[#757676]'>{email}</p>
            </div>

            <div className="flex flex-col">
                <span className='font-semibold text-base text-[#0D1619]'>Message</span>
                <p className='font-semibold text-base text-[#757676]'>{message}</p>
            </div>

            <div className="flex items-center gap-4 border-t border-gray-100 pt-4">
                {type !== 'solved' &&
                <button
                    disabled={inProgressChangeType == 'solved'}
                    onClick={() => changeTicketType_('solved')}
                    className='rounded px-4 py-2 font-semibold text-sm border text-[#155724] bg-[#d4edda] hover:bg-[#c3e6cb] border-[#c3e6cb] transition-all duration-200 active:scale-[.97] outline-none'>
                    {inProgressChangeType !== 'solved' ? 'Solved' : <BtnLoader/>}
                </button>}

                {type !== 'archived' &&
                <button
                    disabled={inProgressChangeType == 'archived'}
                    onClick={() => changeTicketType_('archived')}
                    className='rounded px-4 py-2 font-semibold text-sm border text-[#004085] bg-[#cce5ff] hover:bg-[#b8daff] border-[#b8daff] transition-all duration-200 active:scale-[.97] outline-none'>
                    {inProgressChangeType !== 'archived' ? 'Archive' : <BtnLoader/>}
                </button>}

                <button
                    disabled={inProgressToDelete}
                    onClick={() => {
                        setConfirmationModalIsOpen(true)
                    }}
                    className='rounded px-4 py-2 font-semibold text-sm border text-[#721c24] bg-[#f8d7da] hover:bg-[#f5c6cb] border-[#f5c6cb] transition-all duration-200 active:scale-[.97] outline-none'>
                    {!inProgressToDelete ? 'Remove' : <BtnLoader/>}
                </button>
            </div>
        </div>
        
        {/* Delete Care */}
        <ConfirmationModal
                isOpen={confirmationModalIsOpen}
                title="Delete Tickit"
                message="Are you sure you want to delete this ticket?"
                buttonName="Supprimer"
                onConfirm={() => {
                    setConfirmationModalIsOpen(false);
                    removeTicket()
                }}
                onCancel={() => {
                    setConfirmationModalIsOpen(false);
                }}
            />
            </>
    )
}
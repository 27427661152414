import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout/index';
import { TicketsAsideBar } from 'components/SideBar/TicketsAsideBar';
import PageLayout from 'components/Layout/PageLayout';
import { FaPlus } from 'react-icons/fa6';
import { TicketCard } from 'components/Pages/Tickets/TicketCard';
import { TicketsList } from 'components/Pages/Tickets/TicketsList';
import { isEmpty } from 'helpers/utils';
import { useSearchParams } from 'react-router-dom';
import { useFetch } from 'hooks/useSWR';
import Pagination from 'components/Pagination';
import { useNavigate } from 'react-router-dom';
import BtnLoader from 'components/Loaders/BtnLoader';



const PageContent = () => {

    const navigate = useNavigate()

    const [searchParams] = useSearchParams();
    const ticketsType = searchParams.get('type') ?? 'unread';
    const pageNumber = searchParams.get('page') ?? 1;

    const [selectedTicketId, setSelectedTicketId] = useState('')


    // Get tickets list by type and pagination
    const fetcher = useFetch()

    const { data, isLoading, mutate } = fetcher.getTickets(pageNumber, ticketsType);
    const refresh = () => mutate();


    const [tickets, setTickets] = useState([]);


    useEffect(() => {
        const t = data?.data?.data ?? [];
        // if (!isEmpty(t)) {
        setTickets(t)
        // }
    }, [data])


    // navigate to the next page
    const navigateToAnotherPage = (n) => {
        setSelectedTicketId('')
        navigate(`/tickets?type=${ticketsType}&page=${n}`)
    }


    // get selected ticket
    const selectedTicket = !isEmpty(selectedTicketId) ? tickets.find(t => t.id == Number(selectedTicketId)) ?? {} : {};

    return (
        <div className="mx-auto w-full max-w-6xl py-8 lg:px-4 lg:py-4">
            <div className="flex items-center justify-between mb-6">
                <div className="max-sm:px-4">
                    <h3 className='font-bold text-2xl flex items-center'>
                        Tickets
                        <span className='bg-bgPrimary border px-2 py-1 ml-4 rounded-3xl text-sm font-semibold'>
                            {data?.data?.total ?? 0}
                        </span>
                    </h3>
                    <p className='mt-1'>View, Edit, Delete All Tickets.</p>
                </div>
                {/* <button
                    className='px-4 py-2 bg-black hover:opacity-90 text-white text-lg font-bold rounded-md whitespace-nowrap flex items-center gap-2'>
                    <span className=''>Add</span>
                    <FaPlus className='' size={20} />
                </button> */}
            </div>

            <div className="border-t border-gray-200 flex w-full h-full max-w-full">
                <div className="w-[300px] flex shrink-0 flex-col items-center h-full bg-transparent overflow-y-auto overflow-x-hidden border-r border-gray-200 min-h-[600px] select-none relative">
                    {isLoading ? <div className='absolute top-0 left-0 w-full h-full bg-black/5 flex items-center justify-center'><BtnLoader withText={false} /></div> : null}

                    {isLoading && isEmpty(tickets) ? null :
                        <TicketsList
                            tickets={tickets}
                            selectedTicketId={selectedTicketId}
                            setSelectedTicketId={setSelectedTicketId}
                        />}
                </div>
                <div className="min-h-full flex flex-col grow justify-between">
                    {!isEmpty(selectedTicket) && (
                        <TicketCard ticket={selectedTicket} refresh={refresh} />
                    ) || (
                            <div className="min-h-[200px] flex flex-col items-center justify-center">
                                <p>No ticket selected</p>
                            </div>
                        )}
                </div>
            </div>

            {/* Pagination */}
            <Pagination
                currentPageNumber={data?.data?.current_page}
                lastPageNumber={data?.data?.last_page}
                changePageNumber={navigateToAnotherPage}
            />
        </div>
    )
}


const Tickets = () => {
    return (
        <Layout>
            <PageLayout aside={TicketsAsideBar()} content={PageContent()} />
        </Layout>
    )
}
export default Tickets;
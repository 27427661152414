import { useAuth } from "hooks/useAuth";
import axios from "lib/axios";
import { useState } from "react";
import ConfirmationModal from "components/Alerts/ConfirmationModal";
import BtnLoader from "components/Loaders/BtnLoader";
import { TbEdit } from "react-icons/tb";
import { isEmpty } from "helpers/utils";
import useAlert from "components/Alerts/useAlert";
import { CgClose } from "react-icons/cg";
import { Link } from "react-router-dom";
import UpdateTrouble from "./UpdateTrouble";


export default function TroublesList({ troubles, refresh }) {
    const { renderAlertMessages, showMessage } = useAlert();

    const { csrf } = useAuth()

    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false)
    const [troubleIdToDelete, setTroubleIdToDelete] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const deleteTrouble = async () => {
        try {

            const id = troubleIdToDelete;

            setIsLoading(true)

            await csrf()

            const { data } = await axios.delete(`/api/v1/forAdmin/deleteTrouble/${id}`)

            if (!data.error) {
                await refresh();
                showMessage(data.message, 'success');
                setTroubleIdToDelete('')
            }

            setIsLoading(false)
        } catch (error) {
            showMessage('something was wrong', 'error');
            setIsLoading(false)
        }
    }

    // set trouble data to update it
    const [selectedTrouble, setSelectedTrouble] = useState({})

    return (
        <>
            {renderAlertMessages}
            <section>
                <div className="overflow-hidden bg-white">
                    <div className="max-w-full overflow-auto min-h-[100px]">
                        <table className='w-full'>
                            <thead>
                                <tr className='[&>*]:px-4 [&>*]:py-2 [&>*]:text-black [&>*]:whitespace-nowrap [&>*]:text-start'>
                                    <th className=''>Id</th>
                                    <th className=''>Created_by</th>
                                    <th className=''>Name</th>
                                    <th className=''>Web Image</th>
                                    <th className=''>Mobile Image</th>
                                    <th className=''></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isEmpty(troubles) && (
                                    troubles.map((trouble) => {

                                        const inProgressToDelete = isLoading && troubleIdToDelete == trouble.id;

                                        return (
                                            <tr key={trouble.id} className='hover:bg-bgPrimary border-t border-gray-200 [&>*]:py-6 [&>*]:px-4 [&>*]:text-gray-700 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-pointer transition-colors duration-200'>
                                                <td className='font-bold'>{trouble.id}</td>
                                                <td className=''>
                                                    <div className="w-fit">
                                                        <div className="group flex items-center gap-2">
                                                            <div className='w-10 h-10 rounded-full bg-slate-200 overflow-hidden'>
                                                                <img
                                                                    className='w-full h-full'
                                                                    src={trouble.created_by.avatar}
                                                                    alt={`${trouble.created_by.first_name} ${trouble.created_by.last_name}`}
                                                                />
                                                            </div>
                                                            <div className="flex flex-col">
                                                                <span className='font-bold group-hover:underline'>{`${trouble.created_by.first_name} ${trouble.created_by.last_name}`}</span>
                                                                <span className=''>{trouble.created_by.email}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className=''>{trouble.name}</td>
                                                <td className=''>
                                                    <div className='w-12 h-12 rounded-xl overflow-hidden bg-slate-100 border'>
                                                        {trouble.image_web &&
                                                            <img src={trouble.image_web} alt={trouble.name} className='w-full h-full rounded-xl' />}
                                                    </div>
                                                </td>
                                                <td className=''>
                                                    <div className='w-12 h-12 rounded-xl overflow-hidden bg-slate-100 border'>
                                                        {trouble.image_mobile &&
                                                            <img src={trouble.image_mobile} alt={trouble.name} className='w-full h-full rounded-xl' />}
                                                    </div>
                                                </td>
                                                <td className="">
                                                    <div className="flex items-center justify-center w-fit float-right">
                                                        <button
                                                            disabled={inProgressToDelete}
                                                            onClick={() => setSelectedTrouble(trouble)}
                                                            className="text-green-500 hover:text-white transition-colors duration-200 bg-transparent hover:bg-green-500 focus:outline-none p-2 rounded-md">
                                                            <TbEdit className="w-5 h-5" />
                                                        </button>
                                                        <button
                                                            disabled={inProgressToDelete}
                                                            onClick={() => {
                                                                setTroubleIdToDelete(trouble.id)
                                                                setConfirmationModalIsOpen(true)
                                                            }}
                                                            className="text-red-500 hover:text-white transition-colors duration-200 bg-transparent hover:bg-red-500 focus:outline-none p-2 rounded-md">
                                                            {!inProgressToDelete
                                                                ? <CgClose className="w-5 h-5" />
                                                                : <BtnLoader withText={false} />}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>


            {/* Update */}
            <UpdateTrouble
                selectedTrouble={selectedTrouble}
                setSelectedTrouble={setSelectedTrouble}
                isOpen={!isEmpty(selectedTrouble) ? true : false}
                refresh={refresh}
            />

            {/* Delete Care */}
            <ConfirmationModal
                isOpen={confirmationModalIsOpen}
                title="Delete Trouble"
                message="Are you sure you want to delete trouble?"
                buttonName="Supprimer"
                onConfirm={() => {
                    setConfirmationModalIsOpen(false);
                    deleteTrouble()
                }}
                onCancel={() => {
                    setConfirmationModalIsOpen(false);
                }}
            />
        </>
    )
}
import React, { useState } from 'react'
import SideBar from 'components/SideBar/index';
import NavBar from 'components/NavBar/index'
import classNames from 'helpers/classNames';
import { useAuth } from 'hooks/useAuth';
import { useSelector } from 'react-redux';

function Layout({ children }) {

    const { user, userIsLoading, logout } = useAuth()

    let isNavbarHidden = useSelector(state => state.LayoutReducer.hideNavbar);
    const [openSidebar, setOpenSidebar] = useState(true);
    if (!userIsLoading && user) {
        const authorize = user.isAdmin == 1;
        if (!authorize) {
            logout();
            return;
        }
    }

    return (
        <div className='bg-white min-h-screen'>
            {!isNavbarHidden &&
                <NavBar
                    userData={user}
                    setOpenSidebar={setOpenSidebar}
                    openSidebar={openSidebar}
                />
            }

            <SideBar
                openSidebar={openSidebar}
                roles={!userIsLoading && user && user.roles || {}}
            />

            <div
                onClick={() => setOpenSidebar(true)}
                className={classNames(openSidebar ? "hidden" : "", "fixed inset-0 z-10 bg-gray-900/50 dark:bg-gray-900/50")}
                id="sidebarBackdrop">
            </div>

            <div className="sm:ml-[70px]">
                <div className="rounded-lg">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Layout

import BoxModal from "components/Layout/BoxModal";
import { useAuth } from "hooks/useAuth";
import axios from "lib/axios";
import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import BtnLoader from "../../Loaders/BtnLoader";
import useAlert from "components/Alerts/useAlert";
import { TbCameraPlus } from "react-icons/tb";
import MobileInput from "components/shared/MobileInput";

export default function UpdateAdmin({ selectedAdmin, setSelectedAdmin, isOpen, refresh }) {
    const { renderAlertMessages, showMessage } = useAlert();

    const closeModal = () => setSelectedAdmin({})

    const { csrf } = useAuth()


    const adminRoles = ['basic', 'low', 'medium', 'high', 'owner'];


    const [adminId, setAdminId] = useState('')
    const [avatar, setAvatar] = useState('')
    const [avatarPreview, setAvatarPreview] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [countryPhoneCode, setCountryPhoneCode] = useState('')
    const [countryName, setCountryName] = useState('')
    const [countryCode, setCountryCode] = useState('')
    const [gender, setGender] = useState('')
    const [role, setRole] = useState('')


    // Generate a Random Password For Online Patients.
    function randomPassword() {
        const length = 10;
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let randomStr = '';
        for (let i = 0; i < length; i++) {
            randomStr += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        setPassword(randomStr);
    }


    const ChangePhoneNumber = (phone, country) => {
        setPhoneNumber(phone);
        setCountryPhoneCode(country.dialCode)
        setCountryName(country.name)
        setCountryCode(country.countryCode)
    }


    const handleChooseProAvatar = (event) => {
        const newIMG = event.target.files[0];
        if (newIMG) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const base64 = event.target.result;
                setAvatar(base64);
                setAvatarPreview(base64);
            };

            reader.readAsDataURL(newIMG);
        } else {
            setAvatar('');
            setAvatarPreview('');
        }
    }


    const resetData = () => {
        const a = selectedAdmin;
        setAdminId(a.id)
        setAvatarPreview(a.avatar)
        setFirstname(a.first_name)
        setLastname(a.last_name)
        setEmail(a.email)
        setPassword('')
        setPhoneNumber(a.phone ?? '')
        setCountryPhoneCode(a.country_code)
        setGender(a.gender)
        setRole(a.role)
    }


    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState(false)


    const updateAdminInformations = async () => {
        try {
            const formData = {
                admin_id: adminId,
                avatar,
                first_name: firstname,
                last_name: lastname,
                email,
                password,
                phone: phoneNumber,
                country_code: countryPhoneCode,
                gender,
                role
            };

            setIsLoading(true)
            setErrors([])

            await csrf()

            const res = await axios.put('/api/v1/forAdmin/updateAdmin', formData)
            const data = await res.data;

            if (!data.error) {
                await refresh();

                showMessage(data.message, 'success');

                closeModal()
            }

            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)

            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
                return
            }

            if (error.response && error.response.data.error === true) {
                setErrors(error.response.data.message);
                return
            }

            showMessage('something was wrong', 'error');
        }
    }


    useEffect(() => {
        resetData()
    }, [selectedAdmin])


    return (
        <>
            {renderAlertMessages}
            <BoxModal
                isOpen={isOpen}
                onClose={closeModal}
                content={
                    <>
                        <div
                            className="flex items-center justify-between p-4 border-b border-gray-300 bg-white">
                            <h2 className="text-lg font-extrabold text-gray-900">Update Admin</h2>
                            <button
                                type="button"
                                className="justify-center outline-none p-1 bg-gray-50 hover:bg-gray-100 rounded-md"
                                onClick={closeModal}>
                                <IoMdClose className="w-6 h-6" />
                            </button>
                        </div>

                        <div className="p-4 bg-white max-h-[60vh] max-lg:max-h-[80vh] overflow-y-auto">
                            <div className="p-4">
                                <div className='w-fit mx-auto'>
                                    <label htmlFor="fileInput" className="flex items-center justify-center overflow-hidden bg-opacity-25 rounded-full cursor-pointer h-28 w-28 bg-secondary hover:bg-opacity-20">
                                        {!avatarPreview
                                            ? <TbCameraPlus color="#0891b2" size={30} className="" />
                                            : <img src={avatarPreview} className="w-full h-full" />}
                                    </label>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="hidden"
                                        onChange={handleChooseProAvatar}
                                        accept=".jpg, .jpeg, .png" // Spécifiez les types de fichiers acceptés
                                    />
                                </div>
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <label className='flex flex-col mb-4'>
                                    <span className='mb-1 text-sm font-medium text-gray-900'>Firstname</span>
                                    <input
                                        onChange={(e) => setFirstname(e.target.value)}
                                        value={firstname}
                                        className='outline-none border px-2 py-2 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                                        type="text"
                                    />
                                    {errors.first_name && (
                                        <p className="mt-1 text-sm text-red-500">
                                            {errors.first_name}
                                        </p>)}
                                </label>
                                <label className='flex flex-col mb-4'>
                                    <span className='mb-1 text-sm font-medium text-gray-900'>Lastname</span>
                                    <input
                                        onChange={(e) => setLastname(e.target.value)}
                                        value={lastname}
                                        className='outline-none border px-2 py-2 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                                        type="text"
                                    />
                                    {errors.last_name && (
                                        <p className="mt-1 text-sm text-red-500">
                                            {errors.last_name}
                                        </p>)}
                                </label>
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <label className='flex flex-col mb-4'>
                                    <span className='mb-1 text-sm font-medium text-gray-900'>Email</span>
                                    <input
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                        className='outline-none border px-2 py-2 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                                        type="text"
                                    />
                                    {errors.email && (
                                        <p className="mt-1 text-sm text-red-500">
                                            {errors.email}
                                        </p>)}
                                </label>
                                <label className='flex flex-col mb-4'>
                                    <span className='mb-1 text-sm font-medium text-gray-900'>Password</span>
                                    <div className="relative flex flex-col">
                                        <input
                                            id="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            type="text"
                                            className="outline-none border px-2 py-2 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400"
                                        />
                                        <button
                                            onClick={() => randomPassword()}
                                            className="absolute right-0 flex px-3 py-2 rounded-md group top-2/4 -translate-y-2/4"
                                            type="button">
                                            <svg
                                                width="20px"
                                                height="20px"
                                                className="fill-gray-500 group-hover:fill-gray-700"
                                                viewBox="0 0 24 24">
                                                <path d="M4,12a1,1,0,0,1-2,0A9.983,9.983,0,0,1,18.242,4.206V2.758a1,1,0,1,1,2,0v4a1,1,0,0,1-1,1h-4a1,1,0,0,1,0-2h1.743A7.986,7.986,0,0,0,4,12Zm17-1a1,1,0,0,0-1,1A7.986,7.986,0,0,1,7.015,18.242H8.757a1,1,0,1,0,0-2h-4a1,1,0,0,0-1,1v4a1,1,0,0,0,2,0V19.794A9.984,9.984,0,0,0,22,12,1,1,0,0,0,21,11Z" />
                                            </svg>
                                        </button>
                                    </div>
                                    {errors.password && (
                                        <p className="mt-1 text-sm text-red-500">
                                            {errors.password}
                                        </p>)}
                                </label>
                            </div>

                            <div className="grid grid-cols-2 gap-4 mb-4">
                                <label htmlFor="phoneNumber" className="flex flex-col">
                                    <span className='mb-1 text-sm font-medium text-gray-900'>Mobile number</span>
                                    <MobileInput
                                        name="phoneNumber"
                                        id="phoneNumber"
                                        required
                                        value={phoneNumber}
                                        onChange={ChangePhoneNumber}
                                        inputStyle={{
                                            width: "100%",
                                            height: "100%",
                                            color: "rgb(85, 85, 85)",
                                            paddingTop: "0.65rem",
                                            paddingBottom: "0.65rem",
                                            border: errors.phone
                                                ? "1px solid #F87171"
                                                : "1px solid rgb(204, 204, 204)",
                                        }}
                                    />
                                    {errors.phone && errors.country_code && (
                                        <p className="mt-1 text-sm text-red-500">
                                            {errors.phone ?? null}
                                            {errors.country_code ?? null}
                                        </p>)}
                                </label>
                                <label htmlFor="gender" className="flex flex-col">
                                    <span className='mb-1 text-sm font-medium text-gray-900'>Gender</span>
                                    <select
                                        id="gender"
                                        value={gender}
                                        onChange={(e) => setGender(e.target.value)}
                                        className="outline-none border px-2 py-2 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400">
                                        <option value=""></option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                    {errors.gender && (
                                        <p className="mt-1 text-sm text-red-500">
                                            {errors.gender}
                                        </p>)}
                                </label>
                            </div>

                            <div className="grid grid-cols-2 gap-4 mb-4">
                                <label htmlFor="role" className="flex flex-col">
                                    <span className='mb-1 text-sm font-medium text-gray-900'>Role</span>
                                    <select
                                        id="role"
                                        value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                        className="outline-none border px-2 py-2 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400">
                                        {adminRoles?.map((role) => (
                                            <option value={role}>
                                                {(role).slice(0,1).toUpperCase() + (role).slice(1, (role).length)}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.role && (
                                        <p className="mt-1 text-sm text-red-500">
                                            {errors.role}
                                        </p>)}
                                </label>
                            </div>
                        </div>

                        <div
                            className={`
                                bg-white border-t border-gray-300 p-4
                                max-lg:absolute max-lg:w-screen max-lg:grid max-lg:grid-cols-2 max-lg:gap-4 max-lg:right-0 max-lg:bottom-0
                                lg:flex lg:items-center lg:flex-row-reverse
                            `}>
                            <button onClick={updateAdminInformations} disabled={isLoading} type="button" className="py-2 px-6 font-semibold text-white bg-buttonBg-1 rounded-md text-md hover:bg-buttonBg-2 border border-buttonBg-1 lg:ml-4">
                                {!isLoading ? 'Update' : <BtnLoader />}
                            </button>
                            <button
                                onClick={() => {
                                    resetData()
                                    closeModal()
                                }}
                                disabled={isLoading} type="button" className="py-2 px-6 font-semibold text-black bg-white border border-gray-300 rounded-md text-md hover:bg-gray-100">
                                Annuler
                            </button>
                        </div>
                    </>
                }
            />
        </>
    )
}
import PaginationSecondary from "components/Pagination/Secondary";
import PageLoader from "components/shared/Loaders/PageLoader";
import { isEmpty } from "helpers/utils";
import { useFetch } from "hooks/useSWR";
import { useEffect, useState } from "react";
import { PiFolderOpenLight } from "react-icons/pi";
import { Link, useParams } from "react-router-dom";

const Members = () => {

    const [pageNumber, setPageNumber] = useState(1)

    const { id } = useParams()

    const fetcher = useFetch()
    const { data: membersData, isLoading } = fetcher.getTeamMembersByPracticeId(id, pageNumber);

    const [members, setMembers] = useState([])

    useEffect(() => {
        setMembers(membersData?.data?.data)
    }, [membersData])

    // Change Page
    const navigateToAnotherPage = (n) => {
        setPageNumber(n)
    }

    return (
        <>
            <div className='bg-white sm:rounded-xl sm:border max-sm:border-t border-gray-200 mt-4 overflow-hidden'>
                <div className={`overflow-y-auto`}>
                    <div className="flex items-center justify-between border-b border-gray-200 p-4">
                        <h3 className='font-bold text-xl flex items-center'>
                            Members
                        </h3>
                        <span className='px-2 py-1 ml-2 rounded-3xl text-sm font-semibold leading-[normal] select-none cursor-context-menu'>
                            {`Total (${membersData?.data?.total ?? 0})`}
                        </span>
                    </div>
                    <div className="max-w-full overflow-x-auto">
                        <table
                            className='w-full'>
                            <thead>
                                <tr className="[&>*]:px-4 [&>*]:py-4 [&>*]:text-gray-700 [&>*]:whitespace-nowrap">
                                    <th className='text-start'>FullName</th>
                                    <th className='text-start'>Email</th>
                                    <th className='text-start'>Phone</th>
                                    <th className='text-start'>Appointments</th>
                                    <th className='text-start'>Sales</th>
                                    <th className='text-right'>Reviews</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading && (
                                    !isEmpty(members) && (
                                        members.map((member) => (
                                            <tr key={member?.user?.id} className='hover:bg-bgPrimary border-t border-gray-200 [&>*]:py-2 [&>*]:px-4 [&>*]:text-gray-700 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-pointer transition-colors duration-200'>
                                                <td className=''>
                                                    <div className="w-fit">
                                                        <Link to={`/professional/${member?.user?.id}`}>
                                                            <div className="flex items-center gap-2">
                                                                <div>
                                                                    <img src={member?.user?.avatar} alt={`${member?.user?.first_name} ${member?.user?.last_name}`} className="w-8 h-8 rounded-full bg-slate-100" />
                                                                </div>
                                                                <div className="flex flex-col text-sm font-semibold">
                                                                    <span>{member?.user?.first_name}</span>
                                                                    <span>{member?.user?.last_name}</span>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td className=''>{member?.user?.email}</td>
                                                <td className=''>{`${member?.user?.country_code ?? ''} ${member?.user?.phone ?? ''}`}</td>
                                                <td className=''>{member?.total_appointments}</td>
                                                <td className=''>{member?.total_sales}</td>
                                                <td className='text-right'>
                                                    <div className="flex gap-1 flex-row-reverse">
                                                        <span>({member?.total_reviews})</span>
                                                        <b>{member?.reviews_avg ?? ''}</b>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) || (
                                        // Show NotFound Message
                                        <tr>
                                            <td colSpan={6}>
                                                <div className='bg-gray-50 p-4 min-h-[200px] flex flex-col gap-2 items-center justify-center text-sm text-center text-gray-600'>
                                                    <PiFolderOpenLight size={35} />
                                                    <span>No Results Found!</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                ) || (
                                        // Show Loader
                                        <tr>
                                            <td colSpan={6}>
                                                <PageLoader />
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Pagination */}
            <PaginationSecondary
                currentPageNumber={membersData?.data?.current_page}
                lastPageNumber={membersData?.data?.last_page}
                changePageNumber={navigateToAnotherPage}
            />
        </>
    )
}
export default Members;
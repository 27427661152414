import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { isEmpty, isMoroccanNumber } from "helpers/utils";

export default function MobileInput({
    onChange,
    value,
    country = "ma",
    onlyCountries = ["ma"],
    inputStyle,
    buttonStyle,
    required = false,
    ...rest
}) {

    const handlePhoneChange = (value, country, event, formattedValue) => {
        // Perform your validation here
        const phoneNumber = `+${country?.dialCode}${value}`;
        const isValidMoroccanNumber = isMoroccanNumber(phoneNumber);
        console.log("isValidMoroccanNumber", country.dialCode, isValidMoroccanNumber, phoneNumber);
        // Set the phone number to state
        if (isValidMoroccanNumber) {
            onChange(value, country);
        } else {
            //Alert.error("le format du téléphone n'est pas valide");
        }
    };
    return (
        <PhoneInput
            buttonStyle={buttonStyle}
            {...rest}
            autoFormat={false}
            inputClass={"tel-input"}
            inputStyle={!isEmpty(inputStyle) ? inputStyle : {}}
            disableCountryCode={true}
            inputProps={{
                maxLength: 10, // Set the maximum length for the input
            }}
            placeholder="numéro de téléphone"
            country={country}
            onlyCountries={onlyCountries}
            value={`${value}`}
            onChange={handlePhoneChange}
            isValid={required ? isMoroccanNumber(`+212${value}`) : true}
        />
    );
}

import React from "react";


const Secondary = ({ currentPageNumber, lastPageNumber, changePageNumber }) => {

    // Get Preview Page Url
    const prevPageUrl = () => {
        const currentPage = currentPageNumber;
        const prevPage = currentPage - 1;
        if (prevPage > 0) {
            changePageNumber(prevPage)
        }
    }

    // Get Next Page Url
    const nextPageUrl = () => {
        const currentPage = currentPageNumber;
        const lastPage = lastPageNumber;
        const nextPage = currentPage + 1;
        if (nextPage <= lastPage) {
            changePageNumber(nextPage)
        }
    }


    const canNavigateToNextPage = currentPageNumber + 1 <= lastPageNumber ? false : true;
    const canNavigateToPrevPage = currentPageNumber - 1 > 0 ? false : true;


    // Show Pagination Only When Last Page Not Equal 1.
    const showPagination = lastPageNumber > 1;


    return !showPagination ? null : (
        <div className="py-6 sm:flex sm:justify-between max-sm:px-4">
            <div className="text-sm text-gray-500 max-sm:text-center ">
                <div className="flex items-center gap-1">
                    <span className="font-medium">Page</span>
                    <span className="font-medium text-gray-700">{currentPageNumber}</span>
                    <span className="font-medium">From</span>
                    <span className="font-medium text-gray-700">{lastPageNumber}</span>
                </div>
            </div>
            <div className="flex items-center mt-4 gap-x-4 sm:mt-0">
                <a
                    onClick={prevPageUrl}
                    className={`flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition duration-300 bg-white border border-gray-200 rounded-md sm:w-auto gap-x-2 select-none
                        ${canNavigateToPrevPage ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-bgPrimary'}
                    `}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-5 h-5 rtl:-scale-x-100">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                    </svg>
                    <span>Previous</span>
                </a>
                <a
                    onClick={nextPageUrl}
                    className={`flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition duration-300 bg-white border border-gray-200 rounded-md sm:w-auto gap-x-2 select-none
                        ${canNavigateToNextPage ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-bgPrimary'}
                    `}>
                    <span>Next</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-5 h-5 rtl:-scale-x-100">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                    </svg>
                </a>
            </div>
        </div>
    );
};

export default Secondary;

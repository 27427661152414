import PaginationSecondary from "components/Pagination/Secondary";
import PageLoader from "components/shared/Loaders/PageLoader";
import { isEmpty } from "helpers/utils";
import { useFetch } from "hooks/useSWR";
import { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { PiFolderOpenLight } from "react-icons/pi";
import { Link, useParams } from "react-router-dom";

const Appointments = () => {

    const [pageNumber, setPageNumber] = useState(1)

    const [orderBy, setOrderBy] = useState('new')

    const { id } = useParams()

    const fetcher = useFetch()
    const { data: appointmentsData, isLoading } = fetcher.getAppointmentsByPracticeId(id, pageNumber, orderBy);

    const [appointments, setAppointments] = useState([])

    useEffect(() => {
        setAppointments(appointmentsData?.data?.data)
    }, [appointmentsData])

    // Change Page
    const navigateToAnotherPage = (n) => {
        setPageNumber(n)
    }

    return (
        <>
            <div className='bg-white sm:rounded-xl sm:border max-sm:border-t border-gray-200 mt-4 overflow-hidden'>
                <div className={`overflow-y-auto`}>
                    <div className="flex items-center justify-between border-b border-gray-200 p-4">
                        <h3 className='font-bold text-xl flex items-center'>
                            Appointments
                            <span className='bg-bgPrimary border px-2 py-1 ml-2 rounded-3xl text-sm font-semibold leading-[normal] select-none cursor-context-menu'>
                                {appointmentsData?.data?.total ?? 0}
                            </span>
                        </h3>
                        <button onClick={() => setOrderBy(orderBy == 'new' ? 'old' : 'new')} className='relative border border-gray-200 hover:border-gray-300 bg-white hover:bg-gray-100 text-black font-bold transition-colors duration-200 outline-none py-1 px-3 rounded-3xl flex items-center gap-1'>
                            {orderBy == 'new'
                                ? <>Newest first <IoIosArrowDown /></>
                                : <>Oldest first <IoIosArrowUp /></>}
                        </button>
                    </div>
                    <div className="max-w-full overflow-x-auto">
                        <table
                            className='w-full'>
                            <thead>
                                <tr className="[&>*]:px-4 [&>*]:py-4 [&>*]:text-gray-700 [&>*]:whitespace-nowrap">
                                    <th className='text-start'>Professional</th>
                                    <th className='text-start'>Price</th>
                                    <th className='text-start'>Status</th>
                                    <th className='text-start'>Type</th>
                                    <th className='text-right'>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading && (
                                    !isEmpty(appointments) && (
                                        appointments.map((appointment) => (
                                            <tr key={appointment.id} className='hover:bg-bgPrimary border-t border-gray-200 [&>*]:py-2 [&>*]:px-4 [&>*]:text-gray-700 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-pointer transition-colors duration-200'>
                                                <td className=''>
                                                    <div className="w-fit">
                                                        <Link to={`/professional/${appointment.professional?.id}`}>
                                                            <div className="flex items-center gap-2">
                                                                <div>
                                                                    <img src={appointment.professional?.avatar} alt={`${appointment.professional?.first_name} ${appointment.professional?.last_name}`} className="w-8 h-8 rounded-full bg-slate-100" />
                                                                </div>
                                                                <div className="flex flex-col text-sm text-black font-semibold">
                                                                    <span>{appointment.professional?.first_name}</span>
                                                                    <span>{appointment.professional?.last_name}</span>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td className=''>{appointment.price}</td>
                                                <td className=''>
                                                    <div className={` rounded py-1 px-2 ${appointment.booking_status == "canceled" ? "text-red-600" : appointment.booking_status == "active" ? "text-green-600" : appointment.booking_status == "completed" ? "text-secondary" : "text-secondary"}`}>
                                                        {appointment.booking_status}
                                                    </div>
                                                </td>
                                                <td className=''>{appointment.booking_type}</td>
                                                <td className=' text-right'>{`${appointment.booking_date} ${appointment.booking_time}`}</td>
                                            </tr>
                                        ))
                                    ) || (
                                        // Show NotFound Message
                                        <tr>
                                            <td colSpan={5}>
                                                <div className='bg-gray-50 p-4 min-h-[200px] flex flex-col gap-2 items-center justify-center text-sm text-center text-gray-600'>
                                                    <PiFolderOpenLight size={35} />
                                                    <span>No Results Found!</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                ) || (
                                        // Show Loader
                                        <tr>
                                            <td colSpan={5}>
                                                <PageLoader />
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Pagination */}
            <PaginationSecondary
                currentPageNumber={appointmentsData?.data?.current_page}
                lastPageNumber={appointmentsData?.data?.last_page}
                changePageNumber={navigateToAnotherPage}
            />
        </>
    )
}
export default Appointments;
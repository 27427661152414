import React from "react";
import { ImSpinner } from "react-icons/im";

const BtnLoader = ({ withText=true, }) => {
    return (
        <div className="flex items-center justify-center">
            <ImSpinner className="animate-spin text-xl" />
            {withText && (
                <span className="ml-2">Loading...</span>
            )}
        </div>
    );
};

export default BtnLoader;
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import dayjs from '../../../lib/dayjs'
import axios from 'lib/axios'
import BtnLoader from 'components/Loaders/BtnLoader'
import { useAuth } from 'hooks/useAuth'
import ConfirmationModal from 'components/Alerts/ConfirmationModal'
import { getFullName, isEmpty } from 'helpers/utils'
import { CgClose } from 'react-icons/cg'
import { FaStarHalfAlt } from 'react-icons/fa'
import { FaStar } from 'react-icons/fa6'

const ReviewsList = ({ reviewsData, refresh }) => {
    const { csrf } = useAuth()

    const [changeReviewStatusInProgress, setChangeReviewStatusInProgress] =
        useState(false)
    const [reviewIdToChangeStatus, setreviewIdToChangeStatus] = useState('')
    const ChangeReviwStatus = async id => {
        try {
            setChangeReviewStatusInProgress(true)
            setreviewIdToChangeStatus(id)

            await csrf()

            const { data } = await axios.put(
                `/api/v1/forAdmin/changeReviewStatus/${id}`,
            )

            if (!data.error) {
                await refresh()
            }

            setChangeReviewStatusInProgress(false)
            setreviewIdToChangeStatus('')
        } catch (error) {
            setChangeReviewStatusInProgress(false)
            setreviewIdToChangeStatus('')
        }
    }

    const [confirmationModalIsOpen, setConfirmationModalIsOpen] =
        useState(false)
    const [deleteReviewInProgress, setDeleteReviewInProgress] = useState(false)
    const [reviewIdToDelete, setReviewIdToDelete] = useState('')
    const deleteReview = async () => {
        try {
            setDeleteReviewInProgress(true)

            await csrf()

            const { data } = await axios.delete(
                `/api/v1/forAdmin/deleteReview/${reviewIdToDelete}`,
            )

            if (!data.error) {
                await refresh()
            }

            setReviewIdToDelete('')
            setDeleteReviewInProgress(false)
        } catch (error) {
            setDeleteReviewInProgress(false)
        }
    }

    const disabled = deleteReviewInProgress || reviewIdToChangeStatus

    return (
        <>
            <div className="overflow-hidden bg-white">
                <div className="max-w-full overflow-auto min-h-[100px]">
                    <table className="w-full">
                        <thead>
                            <tr className="[&>*]:px-4 [&>*]:py-2 [&>*]:text-black [&>*]:whitespace-nowrap [&>*]:text-start">
                                <th className="">Id</th>
                                <th className="">Professional</th>
                                <th className="">Patient</th>
                                <th className="">Note</th>
                                <th className="">Comment</th>
                                <th className="">Care</th>
                                <th className="">Created_at</th>
                                <th className="">Status</th>
                                <th className="">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isEmpty(reviewsData) &&
                                reviewsData.map(review => {
                                    const isVerified = review.is_verified

                                    // get pro information, if no pro is found get practice info
                                    const isPro = !isEmpty(review.professional)
                                    const proPageUrl = isPro
                                        ? `/professional/${review?.professional?.id}`
                                        : `/practice/${review?.practice?.id}`
                                    const proAvatar = isPro
                                        ? review?.professional?.avatar
                                        : review?.practice?.avatar
                                    const proFullName = isPro
                                        ? getFullName(review.professional)
                                        : getFullName(review.practice)
                                    const proEmail = isPro
                                        ? review?.professional?.email
                                        : review?.practice?.email

                                    return (
                                        <tr
                                            key={review?.id}
                                            className="hover:bg-bgPrimary border-t border-gray-200 [&>*]:py-6 [&>*]:px-4 [&>*]:text-gray-700 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-pointer transition-colors duration-200">
                                            <td className="font-bold">
                                                {review?.id}
                                            </td>
                                            <td className="">
                                                <div className="w-fit">
                                                    <Link to={proPageUrl}>
                                                        <div className="group flex items-center gap-2">
                                                            <div
                                                                className={`w-10 h-10 bg-slate-200 overflow-hidden ${
                                                                    isPro
                                                                        ? 'rounded-full'
                                                                        : 'rounded-lg'
                                                                }`}>
                                                                <img
                                                                    className="w-full h-full"
                                                                    src={
                                                                        proAvatar
                                                                    }
                                                                    alt={
                                                                        proFullName
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="flex flex-col">
                                                                <span className="font-bold group-hover:underline">
                                                                    {
                                                                        proFullName
                                                                    }
                                                                </span>
                                                                <span className="">
                                                                    {proEmail}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </td>
                                            <td className="">
                                                <div className="w-fit">
                                                    <Link
                                                        to={`/patient/${review?.patient?.id}`}>
                                                        <div className="group flex items-center gap-2">
                                                            <div className="w-10 h-10 rounded-full bg-slate-200 overflow-hidden">
                                                                <img
                                                                    className="w-full h-full"
                                                                    src={
                                                                        review
                                                                            .patient
                                                                            ?.avatar
                                                                    }
                                                                    alt={`${review.patient?.first_name} ${review.patient?.last_name}`}
                                                                />
                                                            </div>
                                                            <div className="flex flex-col">
                                                                <span className="font-bold group-hover:underline">{`${review.patient?.first_name} ${review.patient?.last_name}`}</span>
                                                                <span className="">
                                                                    {
                                                                        review
                                                                            .patient
                                                                            ?.email
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </td>
                                            <td className="">
                                                <div className="flex">
                                                    {renderStars(review.note)}
                                                </div>
                                            </td>
                                            <td className="">
                                                {review?.comment}
                                            </td>
                                            <td className="">{review?.care}</td>
                                            <td className="">
                                                {dayjs(
                                                    review?.created_at,
                                                ).format('D MMMM, YYYY')}
                                            </td>
                                            <td className="">
                                                <button
                                                    disabled={disabled}
                                                    onClick={() =>
                                                        ChangeReviwStatus(
                                                            review?.id,
                                                        )
                                                    }
                                                    className={`px-2 py-1 rounded text-white outline-none
                                                        ${
                                                            isVerified
                                                                ? 'bg-green-500 hover:bg-green-600'
                                                                : 'bg-orange-500 hover:bg-orange-600'
                                                        }
                                                    `}>
                                                    {changeReviewStatusInProgress &&
                                                    reviewIdToChangeStatus ==
                                                        review?.id ? (
                                                        <BtnLoader
                                                            withText={false}
                                                        />
                                                    ) : isVerified ? (
                                                        'Active'
                                                    ) : (
                                                        'Unactive'
                                                    )}
                                                </button>
                                            </td>
                                            <td className="text-end">
                                                <button
                                                    disabled={disabled}
                                                    onClick={() => {
                                                        setReviewIdToDelete(
                                                            review?.id,
                                                        )
                                                        setConfirmationModalIsOpen(
                                                            true,
                                                        )
                                                    }}
                                                    className="text-red-500 hover:text-white transition-colors duration-200 bg-transparent hover:bg-red-500 focus:outline-none p-2 rounded-md">
                                                    {deleteReviewInProgress &&
                                                    reviewIdToDelete ==
                                                        review?.id ? (
                                                        <BtnLoader
                                                            withText={false}
                                                        />
                                                    ) : (
                                                        <CgClose className="w-5 h-5" />
                                                    )}
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Delete Care */}
            <ConfirmationModal
                isOpen={confirmationModalIsOpen}
                title="Delete Review"
                message="Are you sure you want to delete review?"
                buttonName="Supprimer"
                onConfirm={() => {
                    setConfirmationModalIsOpen(false)
                    deleteReview()
                }}
                onCancel={() => {
                    setConfirmationModalIsOpen(false)
                }}
            />
        </>
    )
}

export default ReviewsList

const renderStars = rate => {
    const maxStars = 5
    const starElements = []

    for (let i = 1; i <= maxStars; i++) {
        if (i <= rate) {
            starElements.push(
                <FaStar key={i} size={17} className="text-yellow-400" />,
            )
        } else if (i - 0.5 === rate) {
            starElements.push(
                <FaStarHalfAlt key={i} size={17} className="text-yellow-400" />,
            )
        } else {
            starElements.push(
                <FaStar key={i} size={17} className="text-gray-400" />,
            )
        }
    }

    return starElements
}

import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout/index';
import { Link, useNavigate } from 'react-router-dom';
import Pagination from 'components/Pagination';
import ProfessionalsList from 'components/Pages/Professionals/ProfessionalsList';
import { useFetch } from 'hooks/useSWR';
import PageLoader from 'components/shared/Loaders/PageLoader';
import Search from 'components/Search/Search';
import { LuSettings2 } from 'react-icons/lu';
import FilterProfessionals from 'components/Pages/Professionals/FilterProfessionals';
import { FaPlus } from 'react-icons/fa6';


const Professionals = () => {

    const navigate = useNavigate()

    // Get Url Parameters
    const searchParams = new URLSearchParams(document.location.search)
    const getPageNumber = searchParams.get('page') ?? 1;
    const [pageNumber, setPageNumber] = useState(getPageNumber)
    const [searchQuery, setSearchQuery] = useState('')

    const [sortBy, setSortBy] = useState('new')
    const isFiltered = sortBy != 'new'; // show filter notif..


    // Get Cares Data Using SWR package
    const fetcher = useFetch()

    const { data: professionalsData, isLoading, mutate } = fetcher.getAllProfessionals(pageNumber, searchQuery, sortBy);
    const refresh = () => mutate()

    const [professionals, setProfessionals] = useState([])

    useEffect(() => {
        setProfessionals(professionalsData?.data?.data)
    }, [professionalsData])

    // Change Page
    const navigateToAnotherPage = (n) => {
        setPageNumber(n)
        navigate("/professionals?page=" + n)
    }


    // Change Keywords And Get Data
    const handleChangeSearchQuery = (q) => {
        setSearchQuery(q)
        setPageNumber(1)
        navigate("/professionals?page=1")
    }

    const refreshProfessionalsData = (_by) => {
        setSortBy(_by)
        mutate()
    }

    return (
        <Layout>
            <div className="px-4 py-10">
                <div className='max-w-[1200px] mx-auto'>
                    {/* Header */}
                    <div className="flex items-center justify-between mb-6">
                        <div className="">
                            <h3 className='font-bold text-2xl flex items-center'>
                                Professionals List
                                <span className='bg-bgPrimary border px-2 py-1 ml-4 rounded-3xl text-sm font-semibold'>
                                    {professionalsData?.data?.total ?? 0}
                                </span>
                            </h3>
                            <p className='mt-1'>View, Edit, Delete All Professionals.</p>
                        </div>
                        <Link to={'/professional/create'}>
                            <button
                                className='px-4 py-2 bg-black hover:opacity-90 text-white text-lg font-bold rounded-md whitespace-nowrap flex items-center gap-2
                                max-sm:fixed max-sm:bottom-4 max-sm:right-4'>
                                <span className='hidden sm:block'>Add Professional</span>
                                <span className='block sm:hidden'>Add</span>
                                <FaPlus className='block sm:hidden' size={20} />
                            </button>
                        </Link>
                    </div>

                    <div className="flex items-center justify-between mb-6 bg-bgSecondary p-4 rounded-md">
                        <Search
                            navigate={handleChangeSearchQuery}
                        />

                        <FilterProfessionals
                            refresh={refreshProfessionalsData}
                            data={{ orderBy: sortBy }}
                            button={
                                <button className='relative flex items-center gap-2 border border-gray-200 hover:border-gray-300 bg-white hover:bg-gray-100 text-black font-bold transition-colors duration-200 outline-none py-1.5 px-4 rounded-3xl'>
                                    Filtere
                                    <LuSettings2 size={20} className="max-lg:w-3" />
                                    {isFiltered && <span className='absolute top-0 left-0 w-2.5 h-2.5 bg-green-500 rounded-full border border-gray-200'></span>}
                                </button>
                            }
                        />
                    </div>


                    {isLoading ? <PageLoader /> : (
                        <div className=''>
                            {/* Professionals List */}
                            <ProfessionalsList
                                professionalsData={professionals}
                                refresh={refresh}
                            />

                            {/* Pagination */}
                            <Pagination
                                currentPageNumber={professionalsData?.data?.current_page}
                                lastPageNumber={professionalsData?.data?.last_page}
                                changePageNumber={navigateToAnotherPage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    )
}
export default Professionals;
import BoxModal from "components/Layout/BoxModal";
import { useAuth } from "hooks/useAuth";
import axios from "lib/axios";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import BtnLoader from "../../Loaders/BtnLoader";
import useAlert from "components/Alerts/useAlert";

export default function Create({ button, refresh }) {
    const { renderAlertMessages, showMessage } = useAlert();

    const [isOpen, setIsOpen] = useState(false)
    const closeModal = () => setIsOpen(false)
    const openModal = () => setIsOpen(true)

    const { csrf } = useAuth()

    const [app, setApp] = useState('')
    const [locale, setLocale] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({})


    const saveNewLanguage = async () => {
        try {
            const formData = {
                app,
                locale
            };
            
            setIsLoading(true)

            await csrf()

            const res = await axios.post('/api/v1/forAdmin/translator/create', formData)
            const data = await res.data;

            if (!data.error) {
                await refresh();

                showMessage(data.message, 'success');

                setApp('')
                setLocale('')

                closeModal()
            }

            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)

            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
                return
            }

            if (error.response && error.response.data.error === true) {
                setErrors(error.response.data.message);
                return
            }

            showMessage('something was wrong', 'error');
        }
    }


    return (
        <>
            {renderAlertMessages}
            <div onClick={openModal}>
                {button}
            </div>
            <BoxModal
                isOpen={isOpen}
                onClose={closeModal}
                content={
                    <>
                        <div
                            className="flex items-center justify-between p-4 border-b border-gray-300 bg-white">
                            <h2 className="text-lg font-extrabold text-gray-900">Add Language</h2>
                            <button
                                type="button"
                                className="justify-center outline-none p-1 bg-gray-50 hover:bg-gray-100 rounded-md"
                                onClick={closeModal}>
                                <IoMdClose className="w-6 h-6" />
                            </button>
                        </div>

                        <div className="p-4 bg-white max-h-[60vh] max-lg:max-h-[80vh] overflow-y-auto">
                            <label className='flex flex-col mb-4'>
                                <span className='mb-1 text-sm font-medium text-gray-900'>App Name</span>
                                <input
                                    onChange={(e) => setApp(e.target.value)}
                                    value={app}
                                    className='outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                                    type="text"
                                />
                                {errors.app && (
                                <p className="mt-1 text-sm text-red-500">
                                    {errors.app}
                                </p>)}
                            </label>

                            <label className='flex flex-col mb-4'>
                                <span className='mb-1 text-sm font-medium text-gray-900'>Language Slug</span>
                                <input
                                    onChange={(e) => setLocale(e.target.value)}
                                    value={locale}
                                    className='outline-none border px-2 py-3 rounded text-sm font-medium text-gray-700 hover:border-gray-300 focus:border-gray-400'
                                    type="text"
                                />
                                {errors.locale && (
                                <p className="mt-1 text-sm text-red-500">
                                    {errors.locale}
                                </p>)}
                            </label>
                        </div>

                        <div
                            className={`
                                bg-white border-t border-gray-300 p-4
                                max-lg:absolute max-lg:w-screen max-lg:grid max-lg:grid-cols-2 max-lg:gap-4 max-lg:right-0 max-lg:bottom-0
                                lg:flex lg:items-center lg:flex-row-reverse
                            `}>
                            <button onClick={saveNewLanguage} disabled={isLoading} type="button" className="py-2 px-6 font-semibold text-white bg-buttonBg-1 rounded-md text-md hover:bg-buttonBg-2 border border-buttonBg-1 lg:ml-4">
                                {!isLoading ? 'Save' : <BtnLoader/>}
                            </button>
                            <button onClick={closeModal} disabled={isLoading} type="button" className="py-2 px-6 font-semibold text-black bg-white border border-gray-300 rounded-md text-md hover:bg-gray-100">
                                Annuler
                            </button>
                        </div>
                    </>
                }
            />
        </>
    )
}
import Layout from "components/Layout";
import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {

    const navigate = useNavigate()

    return (
        <Layout>
            <div className="p-4 my-20 w-full h-52 flex flex-col items-center justify-center">
                <div className='max-w-[1200px] mx-auto'>
                    <p className="text-sm font-medium text-blue-500">Error 404</p>
                    <h1 className="mt-3 text-2xl font-semibold text-gray-800 md:text-3xl">
                        Page not found
                    </h1>
                    <p className="mt-4 text-gray-500">
                        Sorry, the page you are looking for doesn't exist.Here are some helpful links:
                    </p>

                    <div className="mt-6 flex">
                        <button
                            onClick={() => navigate(-1)}
                            className="rounded-lg bg-primary px-6 py-2 text-base font-bold text-white transition-colors duration-200 hover:bg-primary/90 whitespace-nowrap">
                            Go back
                        </button>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default NotFound;
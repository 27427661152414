import React from "react";
import { Link } from "react-router-dom";
import { GoScreenFull } from 'react-icons/go';
import { IconContext } from 'react-icons';


function LastUsers({ users }) {
    return (
        <div className='bg-white rounded-lg p-4 border border-gray-300 h-full w-full'>
            <div className='flex justify-between pb-4 border-b border-gray-100'>
                <div>
                    <h2 className='text-xl mb-2 font-semibold'>Users</h2>
                    <p className='text-gray-500 font-sans'>Last 10 users</p>
                </div>
                <Link to="/users">
                    <IconContext.Provider value={{ className: 'w-6 h-6 text-gray-400 hover:text-gray-600' }}>
                        <GoScreenFull />
                    </IconContext.Provider>
                </Link>
            </div>

            {users.length > 0 ? (
                <div className='mt-4 overflow-auto'>
                    <table className="min-w-full">
                        <tbody>
                            {users.map((user) => {

                                const id = user.id;
                                const avatar = user.avatar;
                                const name = `${user.first_name} ${user.last_name}`;
                                const email = user.email;
                                const role = user.role;

                                return (
                                    <tr key={id} className="border-b border-gray-100">
                                        <td className='py-2 pr-2'>
                                            <div className="w-6 h-w-6 rounded-full overflow-hidden bg-slate-100">
                                                <img
                                                    src={avatar}
                                                    alt={name}
                                                    className='w-full h-full'
                                                />
                                            </div>
                                        </td>
                                        <td className='py-2 px-2'>
                                            <p className="text-sm">
                                                {name}
                                            </p>
                                        </td>
                                        <td className='py-2 px-2'>
                                            <p className="text-sm">
                                                {email}
                                            </p>
                                        </td>
                                        <td className='py-2 pl-2 text-end'>
                                            <p className="font-bold text-sm">
                                                {role}
                                            </p>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            ) : (<>No Data</>)}
        </div>

    );
}

export default LastUsers;

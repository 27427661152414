import PageLoader from "components/shared/Loaders/PageLoader";
import { getFullName, isEmpty, minutesToHours } from "helpers/utils";
import { useFetch } from "hooks/useSWR";
import { PiFolderOpenLight } from "react-icons/pi";
import { useParams } from "react-router-dom";

const Services = () => {

    const { id } = useParams()

    const fetcher = useFetch()
    const { data: servicesData, isLoading } = fetcher.getServicesByProfessionalId(id);
    

    return (
        <div className='bg-white sm:rounded-xl sm:border max-sm:border-t border-gray-200 mt-4 overflow-hidden'>
            <div className="">
                <div className="flex items-center justify-between p-4">
                    <h3 className='font-bold text-xl flex items-center'>
                        Services
                    </h3>
                </div>
                <div className="max-w-full overflow-auto">
                    {!isLoading && (
                        !isEmpty(servicesData?.data) && (
                            servicesData?.data.map((speciality) => {
                                return (
                                    <div key={speciality.id}>
                                        {/* Speciality */}

                                        {/* Cares */}
                                        {!isEmpty(speciality?.cares ?? []) &&
                                            speciality?.cares?.map((care) => {
                                                return (
                                                    <div key={care.id} className="hover:bg-bgSecondary border-t border-gray-200 p-4">
                                                        <div className="flex items-center justify-between border-b border-gray-100 pb-2">
                                                            <strong className="text-xl">{care.name}</strong>
                                                        </div>
                                                        {/* Prices */}
                                                        <div className="">
                                                            {!isEmpty(care?.prices ?? []) &&
                                                                care.prices?.map((price) => {
                                                                    return (
                                                                        <div key={price.id}>
                                                                            <div className="grid grid-cols-4 gap-2 border-b border-gray-100 py-2">
                                                                                <div className="flex items-center justify-between">
                                                                                    {price.professional ? (
                                                                                        <div className="flex items-center">
                                                                                            <img
                                                                                                src={price.professional.avatar}
                                                                                                alt={getFullName(price.professional)}
                                                                                                className="mr-1 h-6 w-6 rounded-full bg-gray-100"
                                                                                            />
                                                                                            <span className="text-sm font-bold text-black max-lg:text-xs">
                                                                                                {getFullName(price.professional)}
                                                                                            </span>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <span className="text-sm font-bold text-black max-lg:text-xs">
                                                                                            {price.name || "Unknown name"}
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                                <div className="text-right">
                                                                                    <strong className="text-sm">{care.type ?? 'qqqqqq'}</strong>
                                                                                </div>
                                                                                <div className="text-right">
                                                                                    <strong className="text-sm">{`${minutesToHours(price.duration,)}`}</strong>
                                                                                </div>
                                                                                <div className="text-right">
                                                                                    <strong className="text-sm">{`${price.price} MAD`}</strong>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                )
                            })
                        ) || (
                            // Show NotFound Message
                            <div className='bg-gray-50 p-4 min-h-[200px] flex flex-col gap-2 items-center justify-center text-sm text-center text-gray-600'>
                                <PiFolderOpenLight size={35} />
                                <span>No Results Found!</span>
                            </div>
                        )
                    ) || (
                            // Show Loader
                            <PageLoader />
                        )}
                </div>
            </div>
        </div>
    )
}
export default Services;
import { IoClose } from "react-icons/io5";

export default function AlertMessages({ message, type, onClose }) {

    const alertTitle = type == 'success' && 'Success :' || type == 'error'  && 'Danger :' || 'Unknow :';
    const alertBgColor = type == 'success' && 'bg-green-500' || 'bg-red-500';

    return (
        <div className={`swipeUpAnimation fixed -translate-x-2/4 left-2/4 bottom-4 text-white p-4 z-50 w-80 rounded-lg shadow-lg ${alertBgColor}`}>
            <div className="flex items-start justify-between gap-4 w-full">
                <div className="flex flex-col gap-1">
                    <strong className="text-lg">{alertTitle}</strong>
                    <p className="text-md">{message}</p>
                </div>
                <button onClick={onClose} className="hover:scale-110">
                    <IoClose
                        size={24}
                        className="m-[-4px]"
                    />
                </button>
            </div>
        </div>
    );
}
import React from 'react';
import Layout from 'components/Layout/index';
import { FiChevronRight } from 'react-icons/fi';
import { IconContext } from 'react-icons';
import { Link } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

const Settings = () => {

    const { user: admin, userIsLoading: adminIsLoading } = useAuth()
    const {
        ViewOverviewPage,
        ViewPracticesList,
        ViewProfessionalsList,
        ViewAppointmentsList,
        ViewCaresList,
        ViewReviewsList,
        ViewPatientsList,
        ViewAdminsList,
        ViewAccountPage,
        ViewSpecialitiesList,
        ViewLanguagesList,
        ViewCountriesList,
        ViewCitiesList,
        ViewRegionsList,
        ViewOfflinePatientsList,
        ViewPagesList,
        ViewCategoriesList,
        ViewTroublesList,
        ViewTranslatorList,
        ViewPermissionsList,
        ViewTicketsList,
        ViewContactRequestsList,
    } = !adminIsLoading && admin && admin.roles;
    

    return (
        <Layout>
            <div className="p-4">
                <div className='max-w-[1100px] mx-auto'>

                    {/* Page Header */}
                    <div className='flex flex-col mt-5 mb-5'>
                        <h2 className='text-xl font-extrabold'>Settings</h2>
                        <p>Manage all your Wenaya settings in one place.</p>
                    </div>

                    {/* Content */}
                    <div className='grid grid-cols-[repeat(2,1fr)] gap-4'>
                        <div>
                            {/* Links */}
                            <div className='border border-solid border-gray-200 overflow-hidden rounded-[5px] bg-white'>
                                {ViewOverviewPage &&
                                <Link to="/">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Overview</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}

                                {ViewAppointmentsList &&
                                <Link to="/appointments">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Appointments</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}

                                {ViewCaresList &&
                                <Link to="/cares">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Cares</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}

                                {ViewPracticesList &&
                                <Link to="/practices">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Practices</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}

                                {ViewProfessionalsList &&
                                <Link to="/professionals">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Professionals</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}

                                {ViewReviewsList &&
                                <Link to="/reviews">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Reviews</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}

                                {ViewPatientsList &&
                                <Link to="/patients">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Patients</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}

                                {ViewAdminsList &&
                                <Link to="/admins">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Admins</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}

                                <Link to="/settings">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Settings</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>

                                {ViewAccountPage &&
                                <Link to="/account">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Account</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}
                            </div>
                        </div>
                        <div>
                            {/* Links */}
                            <div className='border border-solid border-gray-200 overflow-hidden rounded-[5px] bg-white'>
                                {ViewSpecialitiesList &&
                                <Link to="/specializations">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Specializations</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}

                                {ViewLanguagesList &&
                                <Link to="/languages">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Languages</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}

                                {ViewCountriesList &&
                                <Link to="/countries">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Countries</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}

                                {ViewCitiesList &&
                                <Link to="/cities">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Cities</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}

                                {ViewRegionsList &&
                                <Link to="/regions">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Regions</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}

                                {ViewOfflinePatientsList &&
                                <Link to="/offline-patients">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Offline Patients</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}

                                {ViewPagesList &&
                                <Link to="/pages">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Pages</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}

                                {ViewCategoriesList &&
                                <Link to="/categories">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Categories</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}

                                {ViewTroublesList &&
                                <Link to="/troubles">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Troubles</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}

                                {ViewTranslatorList &&
                                <Link to="/translator">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Translator</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}

                                {ViewPermissionsList &&
                                <Link to="/permissions">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Permissions</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}

                                {ViewContactRequestsList &&
                                <Link to="/contact-requests">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Contact requests</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}
                                
                                {ViewTicketsList &&
                                <Link to="/tickets">
                                    <div className='p-4 hover:bg-bgPrimary transition-colors duration-200 flex items-center justify-between'>
                                        <span>Tickets</span>
                                        <IconContext.Provider value={{ className: 'w-4 h-4' }}>
                                            <FiChevronRight />
                                        </IconContext.Provider>
                                    </div>
                                </Link>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default Settings;
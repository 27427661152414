import ConfirmationModal from "components/Alerts/ConfirmationModal";
import useAlert from "components/Alerts/useAlert";
import BtnLoader from "components/Loaders/BtnLoader";
import { useAuth } from "hooks/useAuth";
import axios from "lib/axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";


const DangerZone = ({ userData }) => {
    const { renderAlertMessages, showMessage } = useAlert();

    const { csrf, user: admin, userIsLoading: adminIsLoading } = useAuth()
    const { DeletePatientAccount, UpdatePatientInformations } = !adminIsLoading && admin && admin.roles;

    const navigate = useNavigate()

    const returnBack = () => {
        navigate(-1)
    }

    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const deletePatient = async () => {
        const id = userData?.id;
        if (id) {
            try {
                setIsLoading(true)

                await csrf()

                const { data } = await axios.delete(`/api/v1/forAdmin/deletePatient/${id}`)

                if (!data.error) {
                    showMessage(data.message, 'success');
                    returnBack()
                }

                setIsLoading(false)
            } catch (error) {
                showMessage('something was wrong', 'error');
                setIsLoading(false)
            }
        }
    }

    return (
        <div className='bg-white sm:rounded-xl sm:border max-sm:border-t border-gray-200 mt-4 overflow-hidden'>
            {renderAlertMessages}
            <div className="flex items-center justify-between p-4">
                <h3 className='font-bold text-xl flex items-center'>
                    Settings
                </h3>
            </div>
            <div className="max-w-full overflow-auto">
                <table className='w-full'>
                    <tbody className="[&>*]:border-t [&>*]:border-gray-200">
                        <tr className='[&>*]:py-4 [&>*]:px-4 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-context-menu'>
                            <td className="text-black font-bold">Delete Patient</td>
                            <td className="">
                                {DeletePatientAccount &&
                                <button
                                    onClick={() => setConfirmationModalIsOpen(true)}
                                    disabled={isLoading}
                                    className='bg-red-500 hover:bg-red-600 text-white font-bold transition-colors duration-200 outline-none py-1 px-4 rounded-3xl'>
                                    {!isLoading ? "Delete" : <BtnLoader />}
                                </button>}
                            </td>
                        </tr>
                        <tr className='[&>*]:py-4 [&>*]:px-4 [&>*]:text-sm [&>*]:whitespace-nowrap cursor-context-menu'>
                            <td className="text-black font-bold">Edit Patient</td>
                            <td className="">
                                {UpdatePatientInformations &&
                                <Link
                                    to={`update`}
                                    className="bg-green-500 hover:bg-green-600 text-white font-bold transition-colors duration-200 outline-none py-1 px-4 rounded-3xl">
                                    Edit
                                </Link>}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Delete Care */}
            <ConfirmationModal
                isOpen={confirmationModalIsOpen}
                title="Delete Patient"
                message="Are you sure you want to delete this patient?"
                buttonName="Supprimer"
                onConfirm={() => {
                    setConfirmationModalIsOpen(false);
                    deletePatient()
                }}
                onCancel={() => {
                    setConfirmationModalIsOpen(false);
                }}
            />
        </div>
    )
}
export default DangerZone;
export const _get = (object, keys, defaultVal) => {
    keys = Array.isArray(keys) ? keys : keys.split('.')
    object = object[keys[0]]
    if (object && keys.length > 1) {
        return _get(object, keys.slice(1), defaultVal)
    }
    return object === undefined ? defaultVal : object
}

const isDev = process.env.NODE_ENV != 'production'
export const baseBackUrl = isDev
    ? 'http://localhost:8000'
    : 'https://api.wenaya.com'
export const baseApiURL = baseBackUrl + '/api'
export const baseURL = isDev
    ? 'http://localhost:3001'
    : 'https://pro.wenaya.com'
export const eventbaseURL = isDev
    ? 'http://localhost:3003'
    : 'https://event.wenaya.com'

export const USE_GOOGLE_ANALYTICS = process.env.NODE_ENV == 'production'
export const GOOGLE_ANALYTICS_DEBUG = process.env.NODE_ENV == 'development'
export const GA_MEASUREMENT_ID = 'G-NSQTK9104B'

export const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//export const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@()$%^&*=_{}[\]:;\"'|\\<>,.\/~`±§+-]).{8,}$/;
export const passwordRegex = /^((?=.*[a-zA-Z]).{8,})$/
export const usernameRegex = /^[\u0621-\u064A0-9A-Za-z_\-]{4,}$/
export const urlregex =
    /^((?:https?\:\/\/|www\.)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)$/

/**
 *  Check if object empty
 * @param {Object} obj
 * @return {Boolean}
 */
export const isObjEmpty = obj => {
    if (obj == undefined) {
        return true
    }
    return Object.entries(obj).length === 0 && obj.constructor === Object
}

/**
 *  Check if object is a Date Instance
 * @param {Object} obj
 * @return {Boolean}
 */
export let isDate = function (input) {
    if (Object.prototype.toString.call(input) === '[object Date]') return true
    return false
}
/**
 * Checks if `value` is empty. Arrays, strings, or `arguments` objects with a
 * length of `0` and objects with no own enumerable properties are considered
 * "empty".
 *
 * @static
 * @memberOf _
 * @category Objects
 * @param {Array|Object|string} value The value to inspect.
 * @returns {boolean} Returns `true` if the `value` is empty, else `false`.
 * @example
 *
 * _.isEmpty([1, 2, 3]);
 * // => false
 *
 * _.isEmpty([]);
 * // => true
 *
 * _.isEmpty({});
 * // => true
 *
 * _.isEmpty('');
 * // => true
 */

export const isEmpty = value => {
    if (!value) {
        return true
    }
    if (value == 'null') {
        return true
    } else if (Array.isArray(value) || typeof value == 'string') {
        return !value.length
    } else if (typeof value == 'object') {
        return isObjEmpty(value)
    } else if (value !== null) {
        return false
    }
    return true
}

/**
 * convert minutes to hours
 * @param {Number} m `minutes`
 * @returns {Number}  `hours`
 */
export function minutesToHours(m) {
    // convert minutes to Hours:Minutes
    const hours = Math.floor((m % 3600) / 60)
    const remainingMinutes = m % 60

    const formattedTime = `${hours == 0 ? '' : hours.toString() + 'h'} ${
        remainingMinutes == 0
            ? ''
            : remainingMinutes.toString().padStart(2, '') + 'min'
    }`
    return formattedTime
}

/**
 * check if Password valid
 *
 * @param {String} value
 * @returns {Boolean}
 */
export const ValidPassword = value => {
    if (value.match(passwordRegex)) {
        return true
    } else {
        return false
    }
}

/**
 * check if Email valid
 *
 * @param {String} value
 * @returns {Boolean}
 */
export const isValidEmail = value => {
    return emailRegex.test(value)
}

/**
 * chunk an array into smaller arrays of a specified size
 * @param {Array} arr
 * @param {Int} size
 * @return {Array}
 */
export const chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size),
    )

// /**
//  * Debounce event handlers
//  */
// export function debounce(a, b, c) {
//   var d, e;
//   return function () {
//     function h() {
//       (d = null), c || (e = a.apply(f, g));
//     }
//     var f = this,
//       g = arguments;
//     return (
//       clearTimeout(d), (d = setTimeout(h, b)), c && !d && (e = a.apply(f, g)), e
//     );
//   };
// }

export const DEFAULT_SEO = {
    title: 'site_title',
    description: 'Wenaya, l’univers Santé & Bien-être',
    keywords:
        'wenaya, wenaya, طب, clinic, professional, docteur, hospital, hopitale, reservation, حجز, دكتور, دكتورة,مواعيد,مرض,امراض,أمراض',
    openGraph: {
        type: 'website',
        locale: 'ar',
        url: baseURL,
        title: 'Wenaya',
        description: 'Wenaya, l’univers Santé & Bien-être',
        image: `${baseURL}/static/icons/icon-512x512.png`,
        site_name: 'wenaya.com',
        imageWidth: 512,
        imageHeight: 512,
    },
    twitter: {
        handle: '@wenaya',
        cardType: 'summary_large_image',
    },
}

/**
 *  ucwords in JavaScript - Equivalent to PHP's ucwords() Returns a string with the first letter in upper case of each word.
 * @param {String} str
 * @returns {String}
 */
export const ucwords = str => {
    return (str + '').replace(/^(.)|\s+(.)/g, function ($1) {
        return $1.toUpperCase()
    })
}

/**
 *  get Full Name
 *  @param `Object` user
 *  @returns `String` Full Name
 *
 */
export const getFullName = user => {
    let name = user.first_name + ' ' + (user.last_name ? user.last_name : '')
    return name == 'null ' ? user.username : ucwords(name)
}
/**
 *  get Full Name
 *  @param `Object` professional or practice
 *  @param `Object` user
 *  @returns `String` Full Name
 *
 */
export const getProName = (pro, user) => {
    let name = pro?.business ?? getFullName(user)
    return ucwords(name)
}

/**
 *  get Full Name
 *  @param `Object` user
 *  @returns `String` Full Name
 *
 */
export const getProfessionalFullName = (user, prefix, locale) => {
    let pre = prefix
        ? locale == 'ar'
            ? prefix == 'Dr'
                ? user.gender === 'male'
                    ? 'دكتور'
                    : 'دكتورة'
                : user.gender === 'male'
                ? 'بروفيسور'
                : 'بروفيسورة'
            : prefix
        : ''
    let name =
        (locale == 'ar'
            ? user.professional.ar_first_name
                ? user.professional.ar_first_name
                : user.first_name
            : user.first_name) +
        ' ' +
        (locale == 'ar'
            ? user.professional.ar_last_name
                ? user.professional.ar_last_name
                : user.last_name
                ? user.last_name
                : ''
            : user.last_name
            ? user.last_name
            : '')
    return name == 'null '
        ? pre + ' ' + user.username
        : pre + ' ' + ucwords(name)
}

/**
 * Get Url Paramas
 */
export const getUrlVars = () => {
    var vars = {}
    var parts = window.location.href.replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        function (m, key, value) {
            vars[key] = value
        },
    )
    return vars
}

/**
 * Calculate age
 * @param `String` date
 */
export const calculateAge = date => {
    if (isEmpty(date)) {
        return 0
    }
    var ageDifMs = Date.now() - new Date(date).getTime()
    var ageDate = new Date(ageDifMs)
    return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export const colors = [
    [
        '#D9E3F0',
        '#F47373',
        '#697689',
        '#37D67A',
        '#2CCCE4',
        '#555555',
        '#dce775',
        '#ff8a65',
        '#ba68c8',
        '#800000',
    ],
    [
        '#FF0000',
        '#800080',
        '#F0FFFF',
        '#A1CAF1',
        '#FF91AF',
        '#CD5C5C',
        '#F08080',
        '#FA8072',
        '#E9967A',
        '#FFA07A',
    ],
    [
        '#DC143C',
        '#B22222',
        '#FFC0CB',
        '#FFB6C1',
        '#FF69B4',
        '#FF1493',
        '#C71585',
        '#DB7093',
        '#FF7F50',
        '#FF6347',
    ],
    [
        '#FF4500',
        '#FF8C00',
        '#FFA500',
        '#FFD700',
        '#FFFF00',
        '#FFFFE0',
        '#FFFACD',
        '#FAFAD2',
        '#FFEFD5',
        '#FFE4B5',
    ],

    [
        '#EEE8AA',
        '#F0E68C',
        '#FFDAB9',
        '#BDB76B',
        '#E6E6FA',
        '#D8BFD8',
        '#DDA0DD',
        '#EE82EE',
        '#DA70D6',
        '#FF00FF',
    ],
    [
        '#BA55D3',
        '#9370DB',
        '#663399',
        '#8A2BE2',
        '#9400D3',
        '#9932CC',
        '#8B008B',
        '#800080',
        '#4B0082',
        '#6A5ACD',
    ],
    [
        '#483D8B',
        '#7B68EE',
        '#ADFF2F',
        '#7FFF00',
        '#7CFC00',
        '#00FF00',
        '#32CD32',
        '#98FB98',
        '#90EE90',
        '#00FA9A',
    ],
    [
        '#00FF7F',
        '#3CB371',
        '#2E8B57',
        '#228B22',
        '#008000',
        '#006400',
        '#9ACD32',
        '#6B8E23',
        '#808000',
        '#556B2F',
    ],
    [
        '#66CDAA',
        '#8FBC8B',
        '#20B2AA',
        '#008B8B',
        '#008080',
        '#00FFFF',
        '#E0FFFF',
        '#AFEEEE',
        '#7FFFD4',
        '#40E0D0',
    ],
    [
        '#48D1CC',
        '#00CED1',
        '#5F9EA0',
        '#4682B4',
        '#B0C4DE',
        '#B0E0E6',
        '#ADD8E6',
        '#87CEEB',
        '#87CEFA',
        '#00BFFF',
    ],
    [
        '#1E90FF',
        '#6495ED',
        '#7B68EE',
        '#4169E1',
        '#000080',
        '#DAA520',
        '#BC8F8F',
        '#CD853F',
        '#8B4513',
        '#D2691E',
    ],
    [
        '#A52A2A',
        '#808080',
        '#696969',
        '#778899',
        '#708090',
        '#2F4F4F',
        '#f44336',
        '#e91e63',
        '#9c27b0',
        '#673ab7',
    ],
    [
        '#3f51b5',
        '#2196f3',
        '#03a9f4',
        '#00bcd4',
        '#009688',
        '#4caf50',
        '#8bc34a',
        '#cddc39',
        '#ffeb3b',
        '#ffc107',
    ],
    [
        '#ff9800',
        '#ff5722',
        '#795548',
        '#607d8b',
        '#4D4D4D',
        '#999999',
        '#F44E3B',
        '#FE9200',
        '#FCDC00',
        '#DBDF00',
    ],
    [
        '#A4DD00',
        '#68CCCA',
        '#73D8FF',
        '#AEA1FF',
        '#FDA1FF',
        '#333333',
        '#808080',
        '#cccccc',
        '#D33115',
        '#E27300',
    ],
    [
        '#FCC400',
        '#B0BC00',
        '#68BC00',
        '#16A5A5',
        '#009CE0',
        '#7B64FF',
        '#FA28FF',
        '#666666',
        '#B3B3B3',
        '#9F0500',
    ],
    [
        '#C45100',
        '#FB9E00',
        '#808900',
        '#194D33',
        '#0C797D',
        '#0062B1',
        '#653294',
        '#AB149E',
        '#B80000',
        '#DB3E00',
    ],
    [
        '#FCCB00',
        '#008B02',
        '#006B76',
        '#1273DE',
        '#004DCF',
        '#5300EB',
        '#EB9694',
        '#FAD0C3',
        '#FEF3BD',
        '#C1E1C5',
    ],
    [
        '#BEDADC',
        '#C4DEF6',
        '#BED3F3',
        '#D4C4FB',
        '#D0021B',
        '#F5A623',
        '#F8E71C',
        '#8B572A',
        '#7ED321',
        '#417505',
    ],
    [
        '#BD10E0',
        '#9013FE',
        '#4A90E2',
        '#50E3C2',
        '#B8E986',
        '#000000',
        '#4A4A4A',
        '#9B9B9B',
        '#FF6900',
        '#FCB900',
    ],
    [
        '#7BDCB5',
        '#00D084',
        '#8ED1FC',
        '#0693E3',
        '#ABB8C3',
        '#EB144C',
        '#F78DA7',
        '#9900EF',
    ],
]

export const paymentMethods = [
    { text: 'Wallet', value: 'wallet' },
    { text: 'en espèces', value: 'cash' },
    { text: 'Chèque', value: 'check' },
    { text: 'Carte bancaire', value: 'creditCard' },
    { text: 'Virement bancaire', value: 'bankTransfer' },
]

export const eventTypes = [
    { text: 'payé le rendez-vous', value: 'paid_for_appointment' },
    {
        text: 'payé le rendez-vous de groupe',
        value: 'paid_for_group_appointment',
    },
    { text: 'modifier le prix du rendez-vous', value: 'update_appointment' },
    { text: 'annulé un rendez-vous', value: 'cancel_appointment' },
    { text: 'chargé son wallet', value: 'charge_wallet' },
]

export const paymentMethodsData = [
    { text: 'Wallet', value: 'wallet' },
    { text: 'en espèces', value: 'cash' },
    { text: 'Chèque', value: 'check' },
    { text: 'Carte bancaire', value: 'creditCard' },
    { text: 'Carte bancaire', value: 'CARD' },
    { text: 'Virement bancaire', value: 'bankTransfer' },
]

export const bookingStatus = [
    { text: 'active', value: 'active' },
    { text: 'canceled', value: 'canceled' },
    { text: 'completed', value: 'completed' },
]
export const AppointmentDurations = [
    { text: '10 min', value: '10' },
    { text: '20 min', value: '20' },
    { text: '30 min', value: '30' },
    { text: '40 min', value: '40' },
    { text: '50 min', value: '50' },
    { text: '60 min', value: '60' },
    { text: '90 min', value: '90' },
    { text: '120 min', value: '120' },
    { text: '180 min', value: '180' },
]

// detect window scrolling

if (typeof window !== 'undefined') {
    window.onscroll = function (params) {
        var distanceScrolled = document.documentElement.scrollTop
        var fixedTabs = document.getElementsByClassName('fixedTab')[0]
        if (distanceScrolled == 0) {
            if (fixedTabs) {
                fixedTabs.style.position = 'relative'
                fixedTabs.style.width = '-webkit-fill-available'
            }
        } else {
            if (fixedTabs) {
                fixedTabs.style.position = 'fixed'
                fixedTabs.style.width = '-webkit-fill-available'
            }
        }
    }
}

export const saveAs = (blob, name, opts, popup) => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    // the filename you want
    a.download = name
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
}

export const toBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () =>
            resolve({ file: reader.result, name: file.name, size: file.size })
        reader.onerror = error => reject(error)
    })

export const getImage = doc => {
    const linkSource = `data:image/${fileType(doc)};base64,${doc}`
    return linkSource
}

export const isImage = data => {
    const regex = /(data:image\/[^;]+;base64[^"]+)/g
    return regex.test(data)
}

/**
 * @description generate a unique id
 * @return unique id
 **/
export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8
            return v.toString(16)
        },
    )
}

export const fileType = file => {
    let type = ''
    if (file.startsWith('image/')) {
        type = file.replace('image/', '')
    } else {
        type = file.substring(file.indexOf('/') + 1, file.indexOf(';base64'))
    }
    switch (type) {
        case 'vnd.adobe.photoshop':
            return 'psd'
        case 'postscript':
            return 'eps'
        case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
            return 'docx'
        default:
            return type
    }
}

export const base64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
}

export const getHeightAndWidthFromDataUrl = dataURL =>
    new Promise(resolve => {
        const img = new Image()
        img.onload = () => {
            resolve({
                height: img.height,
                width: img.width,
            })
        }
        img.src = dataURL
    })

export const getTimeBlocks = () => {
    var quarterHours = ['00', '15', '30', '45']
    var times = []
    for (var i = 0; i < 24; i++) {
        for (var j = 0; j < quarterHours.length; j++) {
            var time = i + ':' + quarterHours[j]
            if (i < 10) {
                time = '0' + time
            }
            times.push({ text: time, value: time })
        }
    }
    return times
}

/**
 * validate Moroccan phone numbers
 * @param {*} value
 * @returns `boolean`
 */
export const isMoroccanNumber = value => {
    const moroccanRegex =
        /^(?:(?:\+|00)212|\+2120|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/
    return moroccanRegex.test(value)
}

import { useState } from "react";
import { GoScreenFull } from "react-icons/go";

const Overview = ({ professionalData, GoTo }) => {

    const [child, setChild] = useState('Overview')

    return (
        <div className=''>
            <div className={`overflow-y-auto`}>
                <div className="flex items-center border-b border-gray-100 my-6">
                    <button
                        onClick={() => setChild('Overview')}
                        className={`font-bold text-lg flex items-center outline-none px-2 py-2 transition-colors duration-200 border-b-2 rounded-t-lg ${child == 'Overview' ? 'border-black cursor-context-menu' : 'hover:bg-gray-50 border-transparent'}`}>
                        Overview
                    </button>
                    <button
                        onClick={() => setChild('Images')}
                        className={`font-bold text-lg flex items-center outline-none px-2 py-2 transition-colors duration-200 border-b-2 rounded-t-lg ${child == 'Images' ? 'border-black cursor-context-menu' : 'hover:bg-gray-50 border-transparent'}`}>
                        Images ({professionalData?.images?.length ?? 0})
                    </button>
                </div>
                <div className="max-w-full overflow-x-auto">
                    <div className="">
                        {child == 'Images' && (
                            <div>
                                {/* Logo */}
                                <div className="mb-4">
                                    <div className="w-[140px] h-[140px] rounded-xl overflow-hidden bg-slate-100 border border-gray-200">
                                        <img
                                            alt={professionalData.business}
                                            src={professionalData.logo}
                                            className='w-full h-full'
                                        />
                                    </div>
                                </div>
                                {/* Images */}
                                <div className="">
                                    <div className='grid grid-cols-3 gap-4 max-lg:grid-cols-2 max-sm:grid-cols-1 max-sm:gap-2'>
                                        {professionalData.images.length > 0 && (
                                            professionalData.images.map((image, i) => (
                                                <div key={i + 1} className="w-full h-auto min-h-5 rounded-xl overflow-hidden bg-slate-100 border border-slate-200 max-sm:border-none max-sm:rounded-none">
                                                    <img src={image} alt={`practice image ${i + 1}`} className='w-full h-full' />
                                                </div>
                                            ))
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) || (
                                // Overview
                                <div className="rounded-xl">
                                    <div className="grid grid-cols-3 gap-4 max-lg:grid-cols-2 max-sm:grid-cols-1 max-sm:gap-2 [&>*]:flex [&>*]:flex-col [&>*]:gap-2 [&>*]:p-4 [&>*]:rounded-lg [&>*]:cursor-context-menu [&>*]:bg-gradient-to-t [&>*]:from-slate-50 [&>*]:to-slate-100">
                                        <div className="">
                                            <div className="group flex justify-between items-start">
                                                <div className="flex flex-col">
                                                    <span>Total Price</span>
                                                    <strong className="text-green-500 font-bold text-xl mt-2">{`${professionalData?.total_price ?? '0.00'} MAD`}</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="group flex justify-between items-start">
                                                <div className="flex flex-col">
                                                    <span>All Appointments</span>
                                                    <strong className="text-black font-bold text-xl mt-2">{professionalData?.total_appointments ?? '0'}</strong>
                                                </div>
                                                <button onClick={() => GoTo('Appointments')} className="outline-none text-slate-300 hover:text-slate-400 opacity-0 group-hover:opacity-100 max-lg:opacity-100">
                                                    <GoScreenFull className="size-5" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="group flex justify-between items-start">
                                                <div className="flex flex-col">
                                                    <span>Upcomming Appointments</span>
                                                    <strong className="text-black font-bold text-xl mt-2">{professionalData?.upcomming_appointments ?? '0'}</strong>
                                                </div>
                                                <button onClick={() => GoTo('Appointments')} className="outline-none text-slate-300 hover:text-slate-400 opacity-0 group-hover:opacity-100 max-lg:opacity-100">
                                                    <GoScreenFull className="size-5" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="group flex justify-between items-start">
                                                <div className="flex flex-col">
                                                    <span>Reviews</span>
                                                    <strong className="text-black font-bold text-xl mt-2">{professionalData?.total_reviews ?? '0'}</strong>
                                                </div>
                                                <button onClick={() => GoTo('Reviews')} className="outline-none text-slate-300 hover:text-slate-400 opacity-0 group-hover:opacity-100 max-lg:opacity-100">
                                                    <GoScreenFull className="size-5" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="group flex justify-between items-start">
                                                <div className="flex flex-col">
                                                    <span>Services</span>
                                                    <strong className="text-black font-bold text-xl mt-2">#</strong>
                                                </div>
                                                <button onClick={() => GoTo('Services')} className="outline-none text-slate-300 hover:text-slate-400 opacity-0 group-hover:opacity-100 max-lg:opacity-100">
                                                    <GoScreenFull className="size-5" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Overview;
import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout/index';
import { useNavigate } from 'react-router-dom';
import { useFetch } from 'hooks/useSWR';
import PageLoader from 'components/shared/Loaders/PageLoader';
import AppointmentsList from 'components/Pages/Appointments/AppointmentsList';
import Pagination from 'components/Pagination';
import { LuSettings2 } from 'react-icons/lu';
import FilterAppointments from 'components/Pages/Appointments/FilterAppointments';


const Appointments = () => {

    const navigate = useNavigate()

    // Get Url Parameters
    const searchParams = new URLSearchParams(document.location.search)
    const getPageNumber = searchParams.get('page') ?? 1;
    const [pageNumber, setPageNumber] = useState(getPageNumber)

    const [sortBy, setSortBy] = useState('new')
    const [appointmentStatus, setAppointmentStatus] = useState('all')
    const isFiltered = sortBy != 'new' || appointmentStatus != 'all'; // show filter notif..


    // Get Cares Data Using SWR package
    const fetcher = useFetch()

    const { data: appointmentsData, isLoading, mutate } = fetcher.getAllAppointments(pageNumber, sortBy, appointmentStatus);
    const refresh = () => mutate()

    const [appointments, setAppointments] = useState([])

    useEffect(() => {
        setAppointments(appointmentsData?.data?.data)
    }, [appointmentsData])

    // Change Page
    const navigateToAnotherPage = (n) => {
        setPageNumber(n)
        navigate("/appointments?page=" + n)
    }

    const refreshAppointmentsData = (_by, _status) => {
        setSortBy(_by)
        setAppointmentStatus(_status)
        mutate()
    }

    return (
        <Layout>
            <div className="px-4 py-10">
                <div className='max-w-[1200px] mx-auto'>
                    <div className="flex items-center justify-between mb-6">
                        <div className="">
                            <h3 className='font-bold text-2xl flex items-center'>
                                Appointments List
                                <span className='bg-bgPrimary border px-2 py-1 ml-4 rounded-3xl text-sm font-semibold'>
                                    {appointmentsData?.data?.total ?? 0}
                                </span>
                            </h3>
                            <p className='mt-1'>View, Delete All Appointments.</p>
                        </div>
                    </div>

                    <div className="flex items-center justify-between mb-6 bg-bgSecondary p-4 rounded-md flex-row-reverse">
                        <FilterAppointments
                            refresh={refreshAppointmentsData}
                            data={{ orderBy: sortBy, appoStatus: appointmentStatus }}
                            button={
                                <button className='relative flex items-center gap-2 border border-gray-200 hover:border-gray-300 bg-white hover:bg-gray-100 text-black font-bold transition-colors duration-200 outline-none py-1.5 px-4 rounded-3xl'>
                                    Filters
                                    <LuSettings2 size={20} className="max-lg:w-3" />
                                    {isFiltered && <span className='absolute top-0 left-0 w-2.5 h-2.5 bg-green-500 rounded-full border border-gray-200'></span>}
                                </button>
                            }
                        />
                    </div>

                    {isLoading && <PageLoader /> || (
                        <div className=''>
                            {/* Appointments List */}
                            <AppointmentsList
                                appointments={appointments}
                                refresh={refresh}
                            />

                            {/* Pagination */}
                            <Pagination
                                currentPageNumber={appointmentsData?.data?.current_page}
                                lastPageNumber={appointmentsData?.data?.last_page}
                                changePageNumber={navigateToAnotherPage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    )
}
export default Appointments;